import React, { useEffect, useState } from "react";
import {
  FaFolder,
  FaHome,
  FaMoneyCheck,
  FaMusic,
  FaVideo,
} from "react-icons/fa";
import { IoLogOut } from "react-icons/io5";
import { MdAudiotrack } from "react-icons/md";
import { RiSpeakFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SecureLS from "secure-ls";
import { UserProfileDetails } from "../../redux/ReduxSlices/ProfileSlice";
import { fetchCreditBalance } from "../../redux/ReduxSlices/creditSlice.jsx";
import { clearState } from "../../redux/ReduxSlices/openAISlice.jsx";


const sidebarItems = [
  {
    text: "Sample Songs",
    icon: <FaHome />,
  },
  {
    text: "Create Song",
    icon: <FaMusic />,
  },
  {
    text: "Slideshow Video",
    icon: <FaVideo />,
  },
  {
    text: "Singing Selfie",
    icon: <RiSpeakFill />,
  },
  {
    text: "Library",
    icon: <FaFolder />,
  },
];

function SidebarItem({ text, icon, isActive, onClick }) {
  return (
    <div
      onClick={onClick}
      className={`flex items-center text-[16px] cursor-pointer transition-all duration-300
          ${isActive ? "text-teal-300 font-bold" : "text-white"}
          hover:text-teal-300`}
    >
      <div
        className={`h-10 rounded-full
            ${isActive ? "custom-button" : "bg-transparent"}
          `}
      ></div>

      <div className="flex items-center gap-2">
        <span className="text-lg">{icon}</span>
        <span className={`text-sm md:text-base lg:text-base my-auto`}>
          {text}
        </span>
      </div>
    </div>
  );
}

export default function Sidebar({ onItemClick, onClose, setIsSidebarOpen, isSidebarOpen, activeContent }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userDetails, loading, error } = useSelector((state) => state.user);
  const [activeItem, setActiveItem] = useState("Create Song");
  const { balance } = useSelector((state) => state.credits);
  const ls = new SecureLS();

  const sumCount = (userDetails?.free_trial_count ?? 0) + (balance?.credit_point ?? 0);

  const sumTotalVideoCredits = (userDetails?.video_credit_point ?? 0) + (userDetails?.video_free_trial_count ?? 0);

  const [isIphone, setIsIphone] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (/iphone|ipod/.test(userAgent)) {
      setIsIphone(true);
    }
  }, []);

  useEffect(() => {
    dispatch(UserProfileDetails());
    dispatch(fetchCreditBalance());

  }, [dispatch, sumCount, sumTotalVideoCredits]);

  const handleContactClick = (path) => {
    navigate(path);
  };

  useEffect(() => {
    dispatch(clearState());
  }, [activeItem, dispatch]);

  const handleLogout = async () => {
    const savedCredentials = ls.get("credentials");

    localStorage.clear();
    if (savedCredentials) {
      ls.set("credentials", savedCredentials);
    }

    navigate("/signin");

    dispatch(UserProfileDetails());
  };

  const avatarLetter = userDetails?.first_name
    ? userDetails?.first_name.charAt(0).toUpperCase()
    : "U";

  const handleItemClick = (itemText) => {
    setActiveItem(itemText);
    // onItemClick(itemText);
    navigate("/dashboard", { state: { tab: itemText } });
    onClose();
  };

  useEffect(()=>{
    setActiveItem(activeContent)
  },[activeContent])

  const handleUpgradeClick = () => {
    navigate("/pricing");
  };

  const abbreviateEmail = (email) => {
    if (!email) return "";
    const [username, domain] = email.split("@");
    if (!domain) return email;
    const abbreviatedDomain = domain.length > 5 ? `${domain[0]}..` : domain;
    return `${username}@${abbreviatedDomain}`;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.detail || error.toString()}</div>;
  }

  return (
    <aside
      className="flex flex-col w-full max-md:w-[80%] bg-black h-full text-sm text-white md:text-base px-4 sidebar-right-border"
      style={{ height: "100%" }}
    >
      {/* User Info Section */}
      <div className="text-center mt-6 text-lg font-bold bg-gradient-to-r from-green-500 via-teal-500 to-pink-500 bg-clip-text text-transparent">
        I MADE A SONG
      </div>
      <div
        className="user-info flex items-center p-4 bg-gradient-to-r from-green-500 via-teal-500 to-pink-500 text-white rounded-md mt-6 cursor-pointer"
        onClick={() => handleItemClick("Profile")}
      >
        <div className="avatar text-black custom-button rounded-full w-12 h-12 flex items-center justify-center mr-4">
          {avatarLetter}
        </div>
        <div className="email">
          {/* Full name, visible on all screen sizes */}
          <p className="font-bold text-base">
           { userDetails?.first_name ? (`${userDetails?.first_name} ${userDetails?.last_name}`): userDetails?.username}
          </p>
          {/* Abbreviated email for large screens */}
          <p className="text-xs hidden sm:block">{abbreviateEmail(userDetails?.email)}</p>
          {/* Full email for small screens */}
          <p className="font-10 block sm:hidden">{userDetails?.email}</p>
        </div>

      </div>

      {/* Sidebar Navigation */}
      <nav className="sidebar-mt-6 w-full px-4">
        {sidebarItems.map((item, index) => (
          <SidebarItem
            key={index}
            text={item.text}
            icon={item.icon}
            isActive={activeItem === item.text}
            onClick={() => handleItemClick(item.text)}
          />
        ))}
      </nav>

      <div className="flex-grow" />

      {/* Desktop-only Content */}
      <div className="flex-col justify-between items-center sidebar-mg-12 sm:mb-0 hidden sm:flex w-full">
        <div className="flex items-center justify-between space-x-2 max-md:space-x-8 mb-4">
          <div className="text-white credit-balence text-start text-sm lg:text-l d-flex justify-content-between align-items-center">
            <a className="flex justify-center"><MdAudiotrack /></a>
            {sumCount} {sumCount === 1 || sumCount === 0 ? 'Credit' : 'Credits'}
          </div>
          <div className="text-white credit-balence text-start text-sm lg:text-l d-flex justify-content-between align-items-center">
            <a className="flex justify-center"><FaVideo /></a>
            {sumTotalVideoCredits} {sumTotalVideoCredits === 1 || sumTotalVideoCredits === 0 ? 'Credit' : 'Credits'}
          </div>
        </div>
      </div>

      <div >
        <div className="flex align-start">
          <div className="sidebar-mt-6 w-full sm:hidden credit-shown-btn  flex items-center">
            <a><MdAudiotrack /> </a>
            <SidebarItem text={`${sumCount} ${sumCount === 1 || sumCount === 0 ? 'Credit' : 'Credits'}`} />
          </div>
          <div className={"sidebar-mt-6 pl-6 ml-4 w-full sm:hidden credit-shown-btn  flex items-center"}>
            <a className=""><FaVideo /></a>
            <SidebarItem text={`${sumTotalVideoCredits} ${sumTotalVideoCredits === 1 || sumTotalVideoCredits === 0 ? 'Credit' : 'Credits'}`} />
          </div>
        </div>
        <div className="flex items-center justify-between">
          {/* Subscribe section */}
          <div className="mt-4 pl-6 w-full sm:hidden logout-sm-button mb-40 md:mb-60 flex items-center">
            <a><FaMoneyCheck /></a>
            <SidebarItem text="Subscribe" onClick={() => navigate("/pricing")} />
          </div>

          {/* Log Out section */}
          <div className="mt-4 pl-6 w-full sm:hidden logout-sm-button mb-40 md:mb-60 flex items-center">
            <a><IoLogOut /></a>
            <SidebarItem text="Log Out" onClick={() => setIsModalOpen(true)} />
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 p-4 flex flex-wrap justify-center items-center w-full h-full z-[1000] before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.90)] overflow-auto font-[sans-serif]">
          <div className="w-[90%] max-w-md logout-popup shadow-lg rounded-lg px-8 py-6 relative">
            {/* Close Icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-3 cursor-pointer shrink-0 fill-white   hover:fill-red-500 float-right"
              onClick={() => setIsModalOpen(false)}
              viewBox="0 0 320.591 320.591"
            >
              <path
                d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
                data-original="#000000"
              ></path>
              <path
                d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
                data-original="#000000"
              ></path>
            </svg>

            {/* Modal Content */}
            <div className="my-8 text-center">
              <h4 className="text-white text-l font-semibold mt-4">
                Do you really want to log out ?
              </h4>
            </div>

            {/* Action Buttons */}
            <div className="flex justify-evenly items-center flex-row  ">
              <button
                type="button"
                className="px-8 py-2 rounded-lg text-gray-800 text-sm tracking-wide bg-white"
                onClick={handleLogout}
              >
                Yes
              </button>
              <button
                type="button"
                className="px-8 py-2 rounded-lg text-gray-800 text-sm tracking-wide bg-white"
                onClick={() => setIsModalOpen(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </aside>
  );
}
