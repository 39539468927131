const genresOption = {
  fast: "Fast",
  slow: "Slow",
  peppy: "Peppy",
  bollywood_pop: "Bollywood Pop",
pop: "Pop",
  disco: "Disco",

  // classical: "Classical",
  // indian_classical: "Indian classical",

  // bhangra: "Bhangra",
  // sufi:"Sufi",
  // metal: "Metal",
  // heavy_metal: "Heavy Metal",
  // jazz: "Jazz",
  // blues: "Blues",
  // rock: "Rock",
  // hip_hop: "Hip-Hop",
  // rap: "Rap",
  // rnb: "R&B",
  // soul: "Soul",
  // country: "Country",
  // reggae: "Reggae",
  // ska: "Ska",
  // funk: "Funk",
  // electronic_dance: "Electronic/Dance",
  // house: "House",
  // techno: "Techno",
  // trance: "Trance",
  // dubstep: "Dubstep",
  // punk: "Punk",
  // folk: "Folk",
  // gospel: "Gospel",
  // opera: "Opera",
  // latin: "Latin",
  // salsa: "Salsa",
  // bossa_nova: "Bossa Nova",
  // afrobeat: "Afrobeat",
  // k_pop: "K-Pop",
  // j_pop: "J-Pop",
  // flamenco: "Flamenco",
  // tango: "Tango",
  // ambient: "Ambient",
  // lo_fi: "Lofi",
  // synthwave: "Synthwave",
  // grunge: "Grunge",
  // indie: "Indie",
  // alternative_rock: "Alternative Rock",
  // new_age: "New Age",
  // industrial: "Industrial",
  // trap: "Trap",
  // garage: "Garage",
  // bluegrass: "Bluegrass",
  // celtic: "Celtic",
  // zydeco: "Zydeco",
  // world_music: "World Music",
  // experimental: "Experimental",
};

export const getGenresOption = () => {
  return Object.entries(genresOption).map(([key, value]) => ({
    key,
    value,
  }));
};
