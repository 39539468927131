import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSquareInstagram } from "react-icons/fa6";
import { motion } from 'framer-motion';

const MenuPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    return (
        <motion.div
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            transition={{ duration: 0.5 }}
            className="w-full h-screen bg-black text-white p-6 relative">
            {/* Close Button */}
            <button
                className="absolute top-28  right-4 text-2xl text-white"
                onClick={() => navigate(-1)} // Go back
            >
                ✕
            </button>

            {/* Menu Options */}
            <ul className="space-y-4 text-2xl aboutus-margin-36 about-menu">
                <li onClick={() => navigate('/about-us')} className="cursor-pointer">About Us</li>
                <li onClick={() => navigate('/faq')} className="cursor-pointer">FAQ</li>
                <li onClick={() => navigate('/pricing')} className="cursor-pointer">Pricing</li>
                <li onClick={() => navigate('/contact')} className="cursor-pointer">Contact Us</li>
                <li onClick={() => navigate('/cancellation-policy')} className="cursor-pointer">Cancellation Policy</li>
                <li onClick={() => navigate('/privacy-policy')} className="cursor-pointer">Privacy Policy</li>
                <li onClick={() => navigate('/terms-of-service')} className="cursor-pointer">Terms of Service</li>

            </ul>

            {/* Bottom Social Icons */}
            <div className="mt-6 bottom-9 w-full text-4xl">
                <a href="https://www.instagram.com/imadeasong/" target="_blank" rel="noopener noreferrer">
                    <FaSquareInstagram />
                </a>
            </div>

        </motion.div>
    );
};

export default MenuPage;
