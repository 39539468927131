import React, { useState } from 'react';

const paymentMethods = [
    { name: "Card", icon: "https://cdn.builder.io/api/v1/image/assets/d49ea5ac8d14424bb883f83e8d4c08bb/2f202595f408e1db90a2b8a21d7961f44323483f56b3f8e88a65912db1972d01?apiKey=d49ea5ac8d14424bb883f83e8d4c08bb&", },
    { name: "UPI", icon: "https://cdn.builder.io/api/v1/image/assets/d49ea5ac8d14424bb883f83e8d4c08bb/9e4945d0bf478f70da061aede6ddafc0398edfe3762a80a5fee5f8c159090876?apiKey=d49ea5ac8d14424bb883f83e8d4c08bb&" },
    { name: "Wallet", icon: "https://cdn.builder.io/api/v1/image/assets/d49ea5ac8d14424bb883f83e8d4c08bb/cb199f0535ea7e337fc6c282ee5ddab95210bdfef043e500530a2c65a9af67a6?apiKey=d49ea5ac8d14424bb883f83e8d4c08bb&" }
];

function PaymentMethod() {
    const [selectedMethod, setSelectedMethod] = useState(paymentMethods[0].name);

    const handleSelect = (methodName) => {
        setSelectedMethod(methodName);
    };

    return (
        <div className="flex flex-col items-start mt-4">
            <h3 className="self-start text-3xl font-semibold text-white mb-3 max-md:text-xl">Payment Info</h3>
        </div>
    );
}

export default PaymentMethod;
