import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrl from "../../services/api";
import showNotification from "../../hooks/notificationHook";

// Login async thunk
export const loginUser = createAsyncThunk(
  "auth/login",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await axios.post(apiUrl.login, {
        username: credentials.userName,
        password: credentials.password,
      });
      const token = response.data.access_token;
      const eptToken = response.data.hmac.signature;
      const eptTime = response.data.hmac.timestamp;
      if (response.data.user.otp_verification_status) {
        localStorage.setItem("token", token);
        localStorage.setItem("eptToken", eptToken);
        localStorage.setItem("eptTime", eptTime);
      }

      return { ...response.data, token };
    } catch (error) {
      const errorMsg =
        error.response?.data?.detail ||
        error.response?.data?.msg ||
        "Login failed!";

      showNotification("error", errorMsg);

      return rejectWithValue(errorMsg);
    }
  }
);

// Signup async thunk
export const signUpUser = createAsyncThunk(
  "auth/signUpUser",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post(apiUrl.UserSignUp, userData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);

// Logout async thunk
export const logoutUser = createAsyncThunk(
  "auth/logoutUser",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        apiUrl.logout,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      localStorage.removeItem("token");

      showNotification("success", "Logout successful!");

      return response.data;
    } catch (error) {
      const errorMsg = error.response?.data?.detail || "Logout failed!";
      showNotification("error", errorMsg);

      return rejectWithValue(errorMsg);
    }
  }
);

// OTP Verification async thunk
export const verifyOtp = createAsyncThunk(
  "auth/verifyOtp",
  async ({ email, otp }, { rejectWithValue }) => {
    try {
      const response = await axios.post(apiUrl.verifyOtp, { email, otp });
      const token = response.data.access_token;
      const eptToken = response.data.hmac.signature;
      const eptTime = response.data.hmac.timestamp;

      localStorage.setItem("token", token);
      localStorage.setItem("eptToken", eptToken);
      localStorage.setItem("eptTime", eptTime);
      showNotification("success", "OTP verified successfully!");
      return response.data;
    } catch (error) {
      const errorMsg =
        error.response?.data?.detail || "OTP verification failed!";

      showNotification("error", errorMsg);

      return rejectWithValue(errorMsg);
    }
  }
);

// Resend OTP async thunk
export const resendOtp = createAsyncThunk(
  "auth/resendOtp",
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.post(apiUrl.resendOtp, { email });

      showNotification("success", "OTP resent successfully!");
      return response.data;
    } catch (error) {
      const errorMsg = error.response?.data?.detail || "Failed to resend OTP!";

      showNotification("error", errorMsg);

      return rejectWithValue(errorMsg);
    }
  }
);

export const sendLoginOtp = createAsyncThunk(
  "auth/sendLoginOtp",
  async (payload, { rejectWithValue }) => {
    console.log("payload", payload);
    try {
      const response = await axios.post(apiUrl.getLoginOtp, payload);

      if (response?.data?.success) {
        showNotification("success", "OTP sent successfully!");
      } else {
        showNotification(
          "error",
          "This mobile number is already registered. Please try using a different number."
        );
      }
      return response.data;
    } catch (error) {
      const errorMsg = error.response?.data?.detail || "Failed to send OTP!";

      showNotification("error", errorMsg);

      return rejectWithValue(errorMsg);
    }
  }
);

export const verifyLoginOtp = createAsyncThunk(
  "auth/verifyLoginOtp",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(apiUrl.verifyLoginOtp, payload);
      showNotification("success", "OTP verified successfully!");
      const token = response.data.access_token;
      const eptToken = response.data.hmac.signature;
      const eptTime = response.data.hmac.timestamp;

      localStorage.setItem("token", token);
      localStorage.setItem("eptToken", eptToken);
      localStorage.setItem("eptTime", eptTime);
      return { ...response.data, token };
    } catch (error) {
      const errorMsg =
        error.response?.data?.detail || "OTP verification failed!";

      showNotification("error", errorMsg);

      return rejectWithValue(errorMsg);
    }
  }
);

export const resendLoginOtp = createAsyncThunk(
  "auth/resendLoginOtp",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(apiUrl.resendLoginOtp, payload);

      showNotification("success", "OTP resent successfully!");
      return response.data;
    } catch (error) {
      const errorMsg = error.response?.data?.detail || "Failed to resend OTP!";

      showNotification("error", errorMsg);

      return rejectWithValue(errorMsg);
    }
  }
);

export const googleLogin = createAsyncThunk(
  "auth/googleLogin",
  async (googleToken, { rejectWithValue }) => {
    try {
      const response = await axios.post(apiUrl.googleSocialLogin, {
        google_token: googleToken,
      });

      const token = response.data.access_token;
      const eptToken = response.data.hmac.signature;
      const eptTime = response.data.hmac.timestamp;

      // Save token in local storage
      localStorage.setItem("token", token);

      // Todo set again accessToken
      localStorage.setItem("accessToken", token);

      localStorage.setItem("eptToken", eptToken);
      localStorage.setItem("eptTime", eptTime);

      return { ...response.data, token };
    } catch (error) {
      const errorMsg = error?.response?.data?.errors;

      showNotification("error", errorMsg);

      return rejectWithValue(errorMsg);
    }
  }
);

const initialState = {
  isLoggedIn: !!localStorage.getItem("token"),
  userInfo: null,
  loading: false,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.userInfo = action.payload;
    },
    loginFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    logout: (state) => {
      localStorage.removeItem("token");
      state.userInfo = null;
    },
    login(state) {
      state.isLoggedIn = true;
    },
    logout(state) {
      state.isLoggedIn = false;
      localStorage.removeItem("token"); // Ensure token is removed on logout
      state.userInfo = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.isLoggedIn = true;
        state.userInfo = action.payload;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.isLoggedIn = false;
      })
      .addCase(googleLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(googleLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.isLoggedIn = true;
        state.userInfo = action.payload;
      })
      .addCase(googleLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(signUpUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signUpUser.fulfilled, (state, action) => {
        state.loading = false;
        state.userInfo = action.payload;
      })
      .addCase(signUpUser.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = true;
      })
      .addCase(logoutUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.loading = false;
        state.isLoggedIn = false;
        state.userInfo = null;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(verifyOtp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.loading = false;
        state.userInfo = action.payload;
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(resendOtp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(resendOtp.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(resendOtp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(sendLoginOtp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendLoginOtp.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(sendLoginOtp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(verifyLoginOtp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyLoginOtp.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(verifyLoginOtp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { loginRequest, loginSuccess, loginFail, logout, login } =
  authSlice.actions;
export default authSlice.reducer;

// Function to get the token from localStorage
export const getToken = () => localStorage.getItem("token");
