// freeTrailSlice.jsx
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrl from "../../services/api";
import { useNavigate } from 'react-router-dom';
import SecureLS from "secure-ls";

const ls = new SecureLS();

// Async thunk to fetch free trial count
export const fetchFreeTrialCount = createAsyncThunk(
  "freeTrial/fetchCount",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(apiUrl.freeTrial, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {

      if (error.response?.status === 401) {
        // Handle 401 Unauthorized
        localStorage.clear();
        const navigate = useNavigate();

        const savedCredentials = ls.get("credentials");

        localStorage.clear();
        if (savedCredentials) {
          ls.set("credentials", savedCredentials);
        }
        navigate("/signin");

        return rejectWithValue("Unauthorized - Redirecting to login");
      }

      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Async thunk to deduct free trial count
export const deductFreeTrialCount = createAsyncThunk(
  "freeTrial/deductCount",
  async (data, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const eptToken = localStorage.getItem("eptToken");
      const eptTime = localStorage.getItem("eptTime");

      const response = await axios.post(
        apiUrl.deductFreeTrial,
        {
          hmac_signature: eptToken,
          hmac_timestamp: eptTime,
        },
        {
          headers: {
            // "X-HMAC-SIGNATURE": eptToken,
            // "X-TIMESTAMP": eptTime,
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Using the token for authorization
          },
        }
      );
      return response.data; // Returning the response data
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message // Handle errors
      );
    }
  }
);

const freeTrailSlice = createSlice({
  name: "freeTrial",
  initialState: {
    freeTrailData: [],
    deductFreeTrailData: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFreeTrialCount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFreeTrialCount.fulfilled, (state, action) => {
        state.loading = false;
        state.freeTrailData = action.payload;
      })
      .addCase(fetchFreeTrialCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deductFreeTrialCount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deductFreeTrialCount.fulfilled, (state, action) => {
        state.loading = false;
        state.deductFreeTrailData = action.payload;
      })
      .addCase(deductFreeTrialCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default freeTrailSlice.reducer;
