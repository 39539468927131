import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';
import apiUrl from '../../services/api';
import showNotification from '../../hooks/notificationHook';

// Thunk for downloading the video
export const downloadCollectionVideo = createAsyncThunk(
  'video/download',
  async ({ videoURL, downloadName }, { rejectWithValue }) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(
        apiUrl.downloadCollageVideo,

        { video_url: videoURL },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          responseType: 'blob',
        }
      );

      // Create a blob from the response
      const blob = new Blob([response.data], { type: response.data.type });
      const url = window.URL.createObjectURL(blob);

      // Create a link and trigger download
      const link = document.createElement('a');
      link.href = url;
      link.download = downloadName || 'video.mp4'; // Default name if none provided
      link.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      return { success: true };
    } catch (error) {
      return rejectWithValue( 'Failed to download the video,Please try again.');
    }
  }
);

const videoSlice = createSlice({
  name: 'video',
  initialState: {
    isVideoloading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(downloadCollectionVideo.pending, (state) => {
        state.isVideoloading = true;
        state.error = null;
      })
      .addCase(downloadCollectionVideo.fulfilled, (state) => {
        state.isVideoloading = false;
        showNotification("success",'Video downloaded successfully!');
      })
      .addCase(downloadCollectionVideo.rejected, (state, action) => {
        state.isVideoloading = false;
        state.error = action.payload;
        showNotification('error',action.payload || 'Failed to download the video.');
      });
  },
});

export default videoSlice.reducer;
