import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrl from "../../services/api";
import showNotification from "../../hooks/notificationHook";

// Async thunk for API call to generate lyrics
export const generateLyrics = createAsyncThunk(
  "lyrics/generateLyrics",
  async (prompt, { rejectWithValue }) => {
    try {
      if (!prompt) {
        throw new Error("Prompt is required");
      }

      // Uncomment this for Local API
      // const response = await axios.post('/api/generate_lyrics', { prompt });

      const response = await axios.post(
        apiUrl.generateLyrics,
        { prompt },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      const errorMsg = error.response ? error.response.data : error.message;

      showNotification("error",errorMsg || "Failed to generate lyrics")
      return rejectWithValue(errorMsg);
    }
  }
);

const lyricsSlice = createSlice({
  name: "lyrics",
  initialState: {
    lyrics: "",
    loading: false,
    error: null,
  },
  reducers: {
    clearLyrics(state) {
      state.lyrics = "";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateLyrics.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(generateLyrics.fulfilled, (state, action) => {
        state.loading = false;
        state.lyrics = action.payload.text || "";
      })
      .addCase(generateLyrics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const loadingForGenerateLyrics = (state) => state.lyrics.loading;
export const { clearLyrics } = lyricsSlice.actions;
export default lyricsSlice.reducer;
