import { notification } from "antd";

const showNotification = (type, description,durationTime=3) => {
  const isMobile = window.innerWidth <= 768;
  notification.config({
    top: isMobile ? 20 : 79,
  });
  setTimeout(() => {
    notification[type]({
      description: description,
      placement: "top",
      duration: durationTime,
      style: {
        transition: "top 0.5s ease-in-out",
        opacity: 0.9,
        padding: isMobile ? "11px" : "11px",
        zIndex: 9999,
        background: "linear-gradient(45deg, #4dcfe2, #abf9ed, #2c706a)",
        border: "none",
        borderRadius: "8px",
        fontWeight: 500,
        fontSize: isMobile ? "14px" : "16px",
      },
      icon: null, // Hides the default icon
      closeIcon: null, // Hides the close button
    });
  }, 0);
};

export default showNotification;
