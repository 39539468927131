import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrl from "../../services/api";
import { useNavigate } from 'react-router-dom';
import SecureLS from "secure-ls";

const ls = new SecureLS();

// export const uploadAudios = createAsyncThunk(
//   "uploadAudio/uploadAudio",
//   async (payload, { rejectWithValue }) => {
//     var finalPayload = JSON.stringify(payload);
//     try {
//       const token = localStorage.getItem("token");
//       const response = await axios.post(
//         apiUrl.uploadAudio,
//         finalPayload,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       return response;
//     } catch (error) {
//       return rejectWithValue(
//         error.response ? error.response.data : error.message
//       );
//     }
//   }
// );

export const uploadAudios = createAsyncThunk(
  "uploadAudio/uploadAudio",
  async (payload, { rejectWithValue }) => {
    try {

      const token = localStorage.getItem("token");

      var audioPayload = localStorage.getItem("generatedSongdata");


      // Add gpt_description_prompt and tags fields to each object in the array

      const finalPayload = audioPayload.map((item) => ({
        ...item,
        gpt_description_prompt:
          item.gpt_description_prompt || "Default prompt text",
        tags: item.tags || "classic",
      }));


      const response = await axios.post(apiUrl.uploadAudio, finalPayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });


      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const getCollection = createAsyncThunk(
  "uploadAudio/getCollection",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(apiUrl.getCollection, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {

      if (error.response?.status === 401) {
        // Handle 401 Unauthorized
        localStorage.clear();
        const navigate = useNavigate();

        const savedCredentials = ls.get("credentials");

        localStorage.clear();
        if (savedCredentials) {
          ls.set("credentials", savedCredentials);
        }
        navigate("/signin");

        return rejectWithValue("Unauthorized - Redirecting to login");
      }

      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

const uploadAudioSlice = createSlice({
  name: "uploadAudio",
  initialState: {
    uploadResponse: null,
    collectionData: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadAudios.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadAudios.fulfilled, (state, action) => {
        state.loading = false;
        state.uploadResponse = action.payload;
      })
      .addCase(uploadAudios.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle getCollection cases
      .addCase(getCollection.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCollection.fulfilled, (state, action) => {
        state.loading = false;
        state.collectionData = action.payload;
      })
      .addCase(getCollection.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default uploadAudioSlice.reducer;
