import React, { useEffect, useRef, useState } from "react";
import { FaPause, FaPlay, FaChevronLeft, FaChevronRight, FaSpinner } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { clearError, fetchData } from "../../../src/redux/ReduxSlices/homeCollectionSlice.jsx";
import DefaultImage from "../../assets/dashboard/brand_img.png";

const Home = () => {
  const dispatch = useDispatch();
  const [isPlaying, setIsPlaying] = useState(null);
  const audioRef = useRef(null);
  const isProcessing = useRef(false);

  const { data, loading, error } = useSelector((state) => state.homeData);

  const scrollRefs = useRef({});
  const [scrollStates, setScrollStates] = useState({});

  useEffect(() => {
    dispatch(fetchData());

    const handleGlobalClick = (event) => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        audioRef.current = null;
        setIsPlaying(null);
      }
    };

    document.addEventListener("click", handleGlobalClick);

    return () => {
      document.removeEventListener("click", handleGlobalClick);
      dispatch(clearError());
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, [dispatch]);

  useEffect(() => {
    const handleScroll = (category) => {
      const scrollContainer = scrollRefs.current[category];
      if (scrollContainer) {
        const { scrollLeft, scrollWidth, clientWidth } = scrollContainer;
        setScrollStates((prevState) => ({
          ...prevState,
          [category]: {
            showLeft: scrollLeft > 0,
            showRight: scrollLeft + clientWidth < scrollWidth,
          },
        }));
      }
    };

    Object.keys(scrollRefs.current).forEach((category) => {
      const scrollContainer = scrollRefs.current[category];
      if (scrollContainer) {
        scrollContainer.addEventListener("scroll", () => handleScroll(category));
        handleScroll(category); // Initialize
      }
    });

    return () => {
      Object.keys(scrollRefs.current).forEach((category) => {
        const scrollContainer = scrollRefs.current[category];
        if (scrollContainer) {
          scrollContainer.removeEventListener("scroll", () => handleScroll(category));
        }
      });
    };
  }, [data]);

  const togglePlayPause = async (audioUrl, videoId, event) => {
    event.stopPropagation();

    if (isProcessing.current) return;
    isProcessing.current = true;

    try {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        audioRef.current = null;
      }

      if (isPlaying === videoId) {
        setIsPlaying(null);
        isProcessing.current = false;
        return;
      }

      const newAudio = new Audio(audioUrl);
      newAudio.onended = () => {
        setIsPlaying(null);
        audioRef.current = null;
      };

      await newAudio.play();
      audioRef.current = newAudio;
      setIsPlaying(videoId);
    } catch (error) {
      console.error("Error playing audio:", error);
      setIsPlaying(null);
    } finally {
      isProcessing.current = false;
    }
  };

  const handleScroll = (category, direction) => {
    const scrollContainer = scrollRefs.current[category];
    if (scrollContainer) {
      if (direction === "left") {
        scrollContainer.scrollBy({ left: -400, behavior: "smooth" });
      } else {
        scrollContainer.scrollBy({ left: 400, behavior: "smooth" });
      }
    }
  };

  const groupedData = data.reduce((acc, video) => {
    const category = video.category || "Uncategorized";
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(video);
    return acc;
  }, {});

  const categories = [
    "Thank You Parents",
    "Thank You Mother",
    "Thank You Father",
    "Express Love",
    "Valentine's Special",
    "Happy Birthday",
    "Happy Anniversary",
  ];

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <FaSpinner className="text-teal-400 text-3xl animate-spin opacity-75 mb-20" />
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 text-center">Error: {error}</div>;
  }

  return (
    <div className="home">
      <div className="homeCards max-md:mt-6">
        {categories.map((category) => (
          <div key={category}>
            <h2 className="home-title-gradient text-start text-3xl max-md:ml-5 max-md:text-lg text-white bg-clip-text font-semibold mt-2 max-md:mt-2 md:mt-6 mb-5 max-md:mb-0">
              {category}
            </h2>
            <div className="relative">
              {scrollStates[category]?.showLeft && (
                <button
                  className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 bg-black/50 p-3 rounded-full text-white hover:bg-black/70"
                  onClick={() => handleScroll(category, "left")}
                >
                  <FaChevronLeft />
                </button>
              )}
              <div
                ref={(ref) => (scrollRefs.current[category] = ref)}
                className="relative mx-auto pr-4 max-md:p-4 flex overflow-x-auto gap-6 max-md:mb-4 mb-12 scrollbar-hide overflow-y-clip"
              >
                {groupedData[category]?.map((video) => (
                  <div
                    key={video._id}
                    className={`relative w-[200px]
                      //  min-w-[200px]
                      max-md:min-w-[140px] overflow-hidden shadow-md hover:scale-100 transition-transform duration-300 cursor-pointer showcase-card ${isPlaying === video._id ? "bg-blue-500" : "bg-gray-800"
                      }`}
                  >
                    <div className="relative">
                      <img
                        src={video.image_url || DefaultImage}
                        alt={video.title}
                        className="w-full image-height object-cover"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = DefaultImage;
                        }}
                      />
                    </div>
                    <div className="p-4 max-md:p-2">
                      <h3 className="text-lg max-md:text-sm font-semibold truncate text-gray-400">
                        {video.title}
                      </h3>
                      <p className="text-sm max-md:font-10 text-gray-400 truncate">
                        {video.category}
                      </p>
                      <div className="flex items-center justify-between mt-2">
                        <div className="text-gray-400 text-xs flex gap-2">
                          <span>{video.views} Views</span>
                          {/* <span>{video.likes} Likes</span> */}
                        </div>
                      </div>
                    </div>
                    <button
                      onClick={(e) => togglePlayPause(video.audio_url, video._id, e)}
                      className="absolute top-2 right-2 bg-black/50 p-2 rounded-full text-white hover:bg-black/70 transition"
                      style={{
                        zIndex: 1,
                      }}
                    >
                      {isPlaying === video._id ? (
                        <FaPause className="w-5 h-5" />
                      ) : (
                        <FaPlay className="w-5 h-5" />
                      )}
                    </button>
                  </div>
                ))}
              </div>
              {scrollStates[category]?.showRight && (
                <button
                  className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 bg-black/50 p-3 rounded-full text-white hover:bg-black/70"
                  onClick={() => handleScroll(category, "right")}
                >
                  <FaChevronRight />
                </button>
              )}
            </div>
            {!groupedData[category]?.length && (
              <p className="text-gray-500 text-center mt-4">
                No videos available in this category.
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home;
