export const countryData = [
    { code: "+93", name: "Afghanistan", regex: /^\d{9}$/ },
    { code: "+355", name: "Albania", regex: /^\d{9}$/ },
    { code: "+213", name: "Algeria", regex: /^\d{9}$/ },
    { code: "+1-684", name: "American Samoa", regex: /^\d{10}$/ },
    { code: "+376", name: "Andorra", regex: /^\d{6}$/ },
    { code: "+244", name: "Angola", regex: /^\d{9}$/ },
    { code: "+1-264", name: "Anguilla", regex: /^\d{10}$/ },
    { code: "+672", name: "Antarctica", regex: /^\d{6,10}$/ },
    { code: "+1-268", name: "Antigua and Barbuda", regex: /^\d{10}$/ },
    { code: "+54", name: "Argentina", regex: /^\d{10}$/ },
    { code: "+374", name: "Armenia", regex: /^\d{8}$/ },
    { code: "+297", name: "Aruba", regex: /^\d{7}$/ },
    { code: "+61", name: "Australia", regex: /^\d{9}$/ },
    { code: "+43", name: "Austria", regex: /^\d{10}$/ },
    { code: "+994", name: "Azerbaijan", regex: /^\d{9}$/ },
    { code: "+1-242", name: "Bahamas", regex: /^\d{10}$/ },
    { code: "+973", name: "Bahrain", regex: /^\d{8}$/ },
    { code: "+880", name: "Bangladesh", regex: /^\d{10}$/ },
    { code: "+1-246", name: "Barbados", regex: /^\d{10}$/ },
    { code: "+375", name: "Belarus", regex: /^\d{9}$/ },
    { code: "+32", name: "Belgium", regex: /^\d{9}$/ },
    { code: "+501", name: "Belize", regex: /^\d{7}$/ },
    { code: "+229", name: "Benin", regex: /^\d{8}$/ },
    { code: "+1-441", name: "Bermuda", regex: /^\d{10}$/ },
    { code: "+975", name: "Bhutan", regex: /^\d{8}$/ },
    { code: "+591", name: "Bolivia", regex: /^\d{8}$/ },
    { code: "+387", name: "Bosnia and Herzegovina", regex: /^\d{8}$/ },
    { code: "+267", name: "Botswana", regex: /^\d{7}$/ },
    { code: "+55", name: "Brazil", regex: /^\d{10,11}$/ },
    { code: "+246", name: "British Indian Ocean Territory", regex: /^\d{7}$/ },
    { code: "+1-284", name: "British Virgin Islands", regex: /^\d{10}$/ },
    { code: "+673", name: "Brunei", regex: /^\d{7}$/ },
    { code: "+359", name: "Bulgaria", regex: /^\d{9}$/ },
    { code: "+226", name: "Burkina Faso", regex: /^\d{8}$/ },
    { code: "+257", name: "Burundi", regex: /^\d{8}$/ },
    { code: "+855", name: "Cambodia", regex: /^\d{8,9}$/ },
    { code: "+237", name: "Cameroon", regex: /^\d{9}$/ },
    { code: "+1", name: "Canada", regex: /^\d{10}$/ },
    { code: "+238", name: "Cape Verde", regex: /^\d{7}$/ },
    { code: "+1-345", name: "Cayman Islands", regex: /^\d{10}$/ },
    { code: "+236", name: "Central African Republic", regex: /^\d{8}$/ },
    { code: "+235", name: "Chad", regex: /^\d{8}$/ },
    { code: "+56", name: "Chile", regex: /^\d{9}$/ },
    { code: "+86", name: "China", regex: /^\d{11}$/ },
    { code: "+61", name: "Christmas Island", regex: /^\d{9}$/ },
    { code: "+61", name: "Cocos Islands", regex: /^\d{9}$/ },
    { code: "+57", name: "Colombia", regex: /^\d{10}$/ },
    { code: "+269", name: "Comoros", regex: /^\d{7}$/ },
    { code: "+682", name: "Cook Islands", regex: /^\d{5}$/ },
    { code: "+506", name: "Costa Rica", regex: /^\d{8}$/ },
    { code: "+385", name: "Croatia", regex: /^\d{8,12}$/ },
    { code: "+53", name: "Cuba", regex: /^\d{8}$/ },
    { code: "+599", name: "Curacao", regex: /^\d{7}$/ },
    { code: "+357", name: "Cyprus", regex: /^\d{8}$/ },
    { code: "+420", name: "Czech Republic", regex: /^\d{9}$/ },
    { code: "+243", name: "Democratic Republic of the Congo", regex: /^\d{9}$/ },
    { code: "+45", name: "Denmark", regex: /^\d{8}$/ },
    { code: "+253", name: "Djibouti", regex: /^\d{6}$/ },
    { code: "+1-767", name: "Dominica", regex: /^\d{10}$/ },
    { code: "+1-809", name: "Dominican Republic", regex: /^\d{10}$/ },
    { code: "+670", name: "East Timor", regex: /^\d{8}$/ },
    { code: "+593", name: "Ecuador", regex: /^\d{9}$/ },
    { code: "+20", name: "Egypt", regex: /^\d{10}$/ },
    { code: "+503", name: "El Salvador", regex: /^\d{8}$/ },
    { code: "+240", name: "Equatorial Guinea", regex: /^\d{9}$/ },
    { code: "+291", name: "Eritrea", regex: /^\d{7}$/ },
    { code: "+372", name: "Estonia", regex: /^\d{7,8}$/ },
    { code: "+251", name: "Ethiopia", regex: /^\d{9}$/ },
    { code: "+500", name: "Falkland Islands", regex: /^\d{5}$/ },
    { code: "+298", name: "Faroe Islands", regex: /^\d{6}$/ },
    { code: "+679", name: "Fiji", regex: /^\d{7}$/ },
    { code: "+358", name: "Finland", regex: /^\d{10}$/ },
    { code: "+33", name: "France", regex: /^\d{9}$/ },
    { code: "+689", name: "French Polynesia", regex: /^\d{8}$/ },
    { code: "+241", name: "Gabon", regex: /^\d{7}$/ },
    { code: "+220", name: "Gambia", regex: /^\d{7}$/ },
    { code: "+995", name: "Georgia", regex: /^\d{9}$/ },
    { code: "+49", name: "Germany", regex: /^\d{10}$/ },
    { code: "+233", name: "Ghana", regex: /^\d{9}$/ },
    { code: "+350", name: "Gibraltar", regex: /^\d{8}$/ },
    { code: "+30", name: "Greece", regex: /^\d{10}$/ },
    { code: "+299", name: "Greenland", regex: /^\d{6}$/ },
    { code: "+1-473", name: "Grenada", regex: /^\d{10}$/ },
    { code: "+1-671", name: "Guam", regex: /^\d{10}$/ },
    { code: "+502", name: "Guatemala", regex: /^\d{8}$/ },
    { code: "+44-1481", name: "Guernsey", regex: /^\d{6}$/ },
    { code: "+224", name: "Guinea", regex: /^\d{9}$/ },
    { code: "+245", name: "Guinea-Bissau", regex: /^\d{7}$/ },
    { code: "+592", name: "Guyana", regex: /^\d{7}$/ },
    { code: "+509", name: "Haiti", regex: /^\d{8}$/ },
    { code: "+504", name: "Honduras", regex: /^\d{8}$/ },
    { code: "+852", name: "Hong Kong", regex: /^\d{8}$/ },
    { code: "+36", name: "Hungary", regex: /^\d{9}$/ },
    { code: "+354", name: "Iceland", regex: /^\d{7}$/ },
    { code: "+91", name: "India", regex: /^\d{10}$/ },
    { code: "+62", name: "Indonesia", regex: /^\d{9,12}$/ },
    { code: "+98", name: "Iran", regex: /^\d{10}$/ },
    { code: "+964", name: "Iraq", regex: /^\d{10}$/ },
    { code: "+353", name: "Ireland", regex: /^\d{9}$/ },
    { code: "+44-1624", name: "Isle of Man", regex: /^\d{6}$/ },
    { code: "+972", name: "Israel", regex: /^\d{9}$/ },
    { code: "+39", name: "Italy", regex: /^\d{9,10}$/ },
    { code: "+225", name: "Ivory Coast", regex: /^\d{8}$/ },
    { code: "+1-876", name: "Jamaica", regex: /^\d{10}$/ },
    { code: "+81", name: "Japan", regex: /^\d{10}$/ },
    { code: "+44-1534", name: "Jersey", regex: /^\d{6}$/ },
    { code: "+962", name: "Jordan", regex: /^\d{9}$/ },
    { code: "+7", name: "Kazakhstan", regex: /^\d{10}$/ },
    { code: "+254", name: "Kenya", regex: /^\d{9}$/ },
    { code: "+686", name: "Kiribati", regex: /^\d{8}$/ },
    { code: "+383", name: "Kosovo", regex: /^\d{8}$/ },
    { code: "+965", name: "Kuwait", regex: /^\d{8}$/ },
    { code: "+996", name: "Kyrgyzstan", regex: /^\d{9}$/ },
    { code: "+856", name: "Laos", regex: /^\d{8,9}$/ },
    { code: "+371", name: "Latvia", regex: /^\d{8}$/ },
    { code: "+961", name: "Lebanon", regex: /^\d{8}$/ },
    { code: "+266", name: "Lesotho", regex: /^\d{8}$/ },
    { code: "+231", name: "Liberia", regex: /^\d{7,8}$/ },
    { code: "+218", name: "Libya", regex: /^\d{9}$/ },
    { code: "+423", name: "Liechtenstein", regex: /^\d{7}$/ },
    { code: "+370", name: "Lithuania", regex: /^\d{8}$/ },
    { code: "+352", name: "Luxembourg", regex: /^\d{9}$/ },
    { code: "+853", name: "Macau", regex: /^\d{8}$/ },
    { code: "+389", name: "North Macedonia", regex: /^\d{8}$/ },
    { code: "+261", name: "Madagascar", regex: /^\d{9}$/ },
    { code: "+265", name: "Malawi", regex: /^\d{9}$/ },
    { code: "+60", name: "Malaysia", regex: /^\d{9,10}$/ },
    { code: "+960", name: "Maldives", regex: /^\d{7}$/ },
    { code: "+223", name: "Mali", regex: /^\d{8}$/ },
    { code: "+356", name: "Malta", regex: /^\d{8}$/ },
    { code: "+692", name: "Marshall Islands", regex: /^\d{7}$/ },
    { code: "+222", name: "Mauritania", regex: /^\d{8}$/ },
    { code: "+230", name: "Mauritius", regex: /^\d{8}$/ },
    { code: "+262", name: "Mayotte", regex: /^\d{9}$/ },
    { code: "+52", name: "Mexico", regex: /^\d{10}$/ },
    { code: "+691", name: "Micronesia", regex: /^\d{7}$/ },
    { code: "+373", name: "Moldova", regex: /^\d{8}$/ },
    { code: "+377", name: "Monaco", regex: /^\d{8}$/ },
    { code: "+976", name: "Mongolia", regex: /^\d{8}$/ },
    { code: "+382", name: "Montenegro", regex: /^\d{8}$/ },
    { code: "+1-664", name: "Montserrat", regex: /^\d{10}$/ },
    { code: "+212", name: "Morocco", regex: /^\d{9}$/ },
    { code: "+258", name: "Mozambique", regex: /^\d{9}$/ },
    { code: "+95", name: "Myanmar", regex: /^\d{9}$/ },
    { code: "+264", name: "Namibia", regex: /^\d{9}$/ },
    { code: "+674", name: "Nauru", regex: /^\d{7}$/ },
    { code: "+977", name: "Nepal", regex: /^\d{10}$/ },
    { code: "+31", name: "Netherlands", regex: /^\d{9}$/ },
    { code: "+687", name: "New Caledonia", regex: /^\d{6}$/ },
    { code: "+64", name: "New Zealand", regex: /^\d{8,10}$/ },
    { code: "+505", name: "Nicaragua", regex: /^\d{8}$/ },
    { code: "+227", name: "Niger", regex: /^\d{8}$/ },
    { code: "+234", name: "Nigeria", regex: /^\d{10}$/ },
    { code: "+683", name: "Niue", regex: /^\d{4}$/ },
    { code: "+850", name: "North Korea", regex: /^\d{9}$/ },
    { code: "+47", name: "Norway", regex: /^\d{8}$/ },
    { code: "+968", name: "Oman", regex: /^\d{8}$/ },
    { code: "+92", name: "Pakistan", regex: /^\d{10}$/ },
    { code: "+680", name: "Palau", regex: /^\d{7}$/ },
    { code: "+970", name: "Palestine", regex: /^\d{9}$/ },
    { code: "+507", name: "Panama", regex: /^\d{8}$/ },
    { code: "+675", name: "Papua New Guinea", regex: /^\d{8}$/ },
    { code: "+595", name: "Paraguay", regex: /^\d{9}$/ },
    { code: "+51", name: "Peru", regex: /^\d{9}$/ },
    { code: "+63", name: "Philippines", regex: /^\d{10}$/ },
    { code: "+48", name: "Poland", regex: /^\d{9}$/ },
    { code: "+351", name: "Portugal", regex: /^\d{9}$/ },
    { code: "+1-787", name: "Puerto Rico", regex: /^\d{10}$/ },
    { code: "+974", name: "Qatar", regex: /^\d{8}$/ },
    { code: "+242", name: "Republic of the Congo", regex: /^\d{9}$/ },
    { code: "+262", name: "Reunion", regex: /^\d{9}$/ },
    { code: "+40", name: "Romania", regex: /^\d{9}$/ },
    { code: "+7", name: "Russia", regex: /^\d{10}$/ },
    { code: "+250", name: "Rwanda", regex: /^\d{9}$/ },
    { code: "+290", name: "Saint Helena", regex: /^\d{4}$/ },
    { code: "+1-869", name: "Saint Kitts and Nevis", regex: /^\d{10}$/ },
    { code: "+1-758", name: "Saint Lucia", regex: /^\d{10}$/ },
    { code: "+590", name: "Saint Martin", regex: /^\d{9}$/ },
    { code: "+508", name: "Saint Pierre and Miquelon", regex: /^\d{6}$/ },
    { code: "+1-784", name: "Saint Vincent and the Grenadines", regex: /^\d{10}$/ },
    { code: "+685", name: "Samoa", regex: /^\d{5,7}$/ },
    { code: "+378", name: "San Marino", regex: /^\d{9}$/ },
    { code: "+239", name: "Sao Tome and Principe", regex: /^\d{7}$/ },
    { code: "+966", name: "Saudi Arabia", regex: /^\d{9}$/ },
    { code: "+221", name: "Senegal", regex: /^\d{9}$/ },
    { code: "+381", name: "Serbia", regex: /^\d{8,9}$/ },
    { code: "+248", name: "Seychelles", regex: /^\d{7}$/ },
    { code: "+232", name: "Sierra Leone", regex: /^\d{8}$/ },
    { code: "+65", name: "Singapore", regex: /^\d{8}$/ },
    { code: "+421", name: "Slovakia", regex: /^\d{9}$/ },
    { code: "+386", name: "Slovenia", regex: /^\d{9}$/ },
    { code: "+677", name: "Solomon Islands", regex: /^\d{7}$/ },
    { code: "+252", name: "Somalia", regex: /^\d{7,9}$/ },
    { code: "+27", name: "South Africa", regex: /^\d{9}$/ },
    { code: "+82", name: "South Korea", regex: /^\d{9,10}$/ },
    { code: "+211", name: "South Sudan", regex: /^\d{9}$/ },
    { code: "+34", name: "Spain", regex: /^\d{9}$/ },
    { code: "+94", name: "Sri Lanka", regex: /^\d{9}$/ },
    { code: "+249", name: "Sudan", regex: /^\d{9}$/ },
    { code: "+597", name: "Suriname", regex: /^\d{7}$/ },
    { code: "+47", name: "Svalbard and Jan Mayen", regex: /^\d{8}$/ },
    { code: "+268", name: "Eswatini", regex: /^\d{8}$/ },
    { code: "+46", name: "Sweden", regex: /^\d{7,10}$/ },
    { code: "+41", name: "Switzerland", regex: /^\d{9}$/ },
    { code: "+963", name: "Syria", regex: /^\d{9}$/ },
    { code: "+886", name: "Taiwan", regex: /^\d{9}$/ },
    { code: "+992", name: "Tajikistan", regex: /^\d{9}$/ },
    { code: "+255", name: "Tanzania", regex: /^\d{9}$/ },
    { code: "+66", name: "Thailand", regex: /^\d{9,10}$/ },
    { code: "+670", name: "Timor-Leste", regex: /^\d{7}$/ },
    { code: "+228", name: "Togo", regex: /^\d{8}$/ },
    { code: "+690", name: "Tokelau", regex: /^\d{4}$/ },
    { code: "+676", name: "Tonga", regex: /^\d{5}$/ },
    { code: "+1-868", name: "Trinidad and Tobago", regex: /^\d{10}$/ },
    { code: "+216", name: "Tunisia", regex: /^\d{8}$/ },
    { code: "+90", name: "Turkey", regex: /^\d{10}$/ },
    { code: "+993", name: "Turkmenistan", regex: /^\d{8}$/ },
    { code: "+1-649", name: "Turks and Caicos Islands", regex: /^\d{10}$/ },
    { code: "+688", name: "Tuvalu", regex: /^\d{5}$/ },
    { code: "+256", name: "Uganda", regex: /^\d{9}$/ },
    { code: "+380", name: "Ukraine", regex: /^\d{9}$/ },
    { code: "+971", name: "United Arab Emirates", regex: /^\d{9}$/ },
    { code: "+44", name: "United Kingdom", regex: /^\d{10,11}$/ },
    { code: "+1", name: "United States", regex: /^\d{10}$/ },
    { code: "+598", name: "Uruguay", regex: /^\d{8}$/ },
    { code: "+998", name: "Uzbekistan", regex: /^\d{9}$/ },
    { code: "+678", name: "Vanuatu", regex: /^\d{5,7}$/ },
    { code: "+379", name: "Vatican City", regex: /^\d{8}$/ },
    { code: "+58", name: "Venezuela", regex: /^\d{10}$/ },
    { code: "+84", name: "Vietnam", regex: /^\d{9,10}$/ },
    { code: "+1-284", name: "British Virgin Islands", regex: /^\d{10}$/ },
    { code: "+1-340", name: "US Virgin Islands", regex: /^\d{10}$/ },
    { code: "+681", name: "Wallis and Futuna", regex: /^\d{6}$/ },
    { code: "+212", name: "Western Sahara", regex: /^\d{9}$/ },
    { code: "+967", name: "Yemen", regex: /^\d{9}$/ },
    { code: "+260", name: "Zambia", regex: /^\d{9}$/ },
    { code: "+263", name: "Zimbabwe", regex: /^\d{9}$/ }
];
