


import React from 'react';
import { FaCheck } from 'react-icons/fa';
import { IoCloseOutline } from "react-icons/io5";
import { IoMdRefresh } from "react-icons/io";
import guide1 from "../../assets/dashboard/guide-image4.png";
import guide2 from "../../assets/dashboard/guide-image1.png";
import guide3 from "../../assets/dashboard/guide-image2.png";
import guide4 from "../../assets/dashboard/guide-image3.png";
import guide5 from "../../assets/dashboard/guide-image6.png";
import guide6 from "../../assets/dashboard/guide-image5.png";
import guide7 from "../../assets/dashboard/guide-image7.png";
import { GrFormUpload } from 'react-icons/gr';

const ImageGenerationGuide = ({ onclose }) => {
  const guidelines = [
    "Use a well-lit environment.",
    "Avoid heavy shadows or blurry images.",
    "Describe the character (anime woman, cyberpunk warrior, 90s sitcom character).",
    // "Describe the camera position (close-up, shoulders up, facing camera).",
    // "Describe the background (forest at night, quiet city).",
    // "Describe the lighting (cinematic, soft lighting, blazing sun).",
  ];

  const examples = [
    {
      image: guide5,
      title: "90s sitcom character",
      description: "Shoulders-up",
    },
    {
      image: guide6,
      title: "Anime woman, 2d",
      description: "Close-up on face",
    },
    {
      image: guide7,
      title: "Woman, cyberpunk",
      description: "Matte black armorty",
    },
  ];

  const examples2 = [
    {
      image: guide1,
      icon: <FaCheck className="text-green-500 text-2xl" />,
      description: "Shoulders-up, in living room, soft",
    },
    {
      image: guide2,
      icon: <IoCloseOutline className="text-red-500 font-extrabold text-2xl" />,
      description: "Close-up on face, forest at night, cinematic",
    },
    {
      image: guide3,
      icon: <FaCheck className="text-green-500 text-2xl" />,
      description: "Matte black armor, dirty face, close-up, city",
    },
    {
      image: guide4,
      icon: <IoCloseOutline className="text-red-500 font-extrabold text-2xl" />,
      description: "Close-up on face, forest at night, cinematic",
    },
  ];

  return (
   <div className='h-[66vh]'>
     <div className="relative p-6 bg-black text-white w-full lg:max-w-2xl mx-auto rounded-lg ">
      {/* Header */}
      <button
        onClick={onclose}
        className="absolute top-4 right-4 text-white font-extrabold text-2xl hover:text-red-700 focus:outline-none"
      >
        <IoCloseOutline />
      </button>
      <h2 className="text-base font-bold mb-4">Image Generation Guide</h2>

      {/* Instructions */}
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 mb-6">
        {examples2.map((example, index) => (
          <div
            key={index}
            className="rounded-lg p-3 text-center shadow-md flex flex-col justify-center items-center"
          >
            <img
              src={example.image}
              alt={example.description}
              className="w-full h-auto object-cover rounded-md mb-2"
            />
            <div className="flex justify-center items-center h-10">{example.icon}</div>
          </div>
        ))}
      </div>

      <div className="space-y-4 mb-6 ">
        <div className="text-gray-300 space-y-2">
        <p className="flex items-center space-x-2">
        <GrFormUpload/>
            <h3 className='text-white text-md font-semibold'>Upload Image:</h3>

          </p>
          <ul className='list-disc pl-5 space-y-2 text-gray-300 text-sm'>
            <li>Click on the "Upload Image" button to upload your photo.</li>
            <li>
            Ensure that you upload a clear selfie for the best results.
            </li>
          </ul>

        </div>


        <div>
          <h3 className="text-sm font-semibold text-gray-300">For best results:</h3>
          <ul className="list-disc pl-5 space-y-2 text-gray-300 text-sm">
            {guidelines.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      </div>
      <div className="text-gray-300 space-y-2">
        <p className="flex items-center space-x-2">
        <GrFormUpload/>
            <h3 className='text-white text-md font-semibold'>You can upload an image in two ways:</h3>

          </p>
          <ul className='list-disc pl-5 space-y-2 text-gray-300 text-sm'>
            <li> Click a photo using your camera.</li>
            <li>
            Select an existing photo from your gallery.
            </li>
          </ul>
          <p className='text-gray-300 text-sm'>If you're unsure about the uploaded image, you can click the "Reset" button to upload a new photo.</p>

        </div>
        <div className="text-gray-300 space-y-2">
        <p className="flex items-center space-x-2">
        <GrFormUpload/>
            <h3 className='text-white text-md font-semibold'>Upload Audio:</h3>

          </p>
          <ul className='list-disc pl-5 space-y-2 text-gray-300 text-sm'>
            <li> Click on the "Upload Audio" button to upload an audio file as per your requirement.</li>
            <li>
            Choose the audio file from your existing files.
            </li>
          </ul>

        </div>
        <div className="text-gray-300 space-y-2 mb-2">
        <p className="flex items-center space-x-2">
        <GrFormUpload/>
            <h3 className='text-white text-md font-semibold'>Generate Video</h3>

          </p>
          <ul className='list-disc pl-5 space-y-2 text-gray-300 text-sm'>
            <li> Click on the "Generate Video" button.</li>
            <li>
            The video generation process may take a few minutes.
            </li>
            <li>
            For the best results, ensure the input image and audio are high quality.
            </li>
            <li>
            Once the video is generated, you will find it in your Video Library Collection.
            </li>
          </ul>

        </div>
      {/* Examples */}
      <div>
        <h3 className="text-sm font-semibold mb-4">Here are some examples:</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {examples.map((example, index) => (
            <div
              key={index}
              className="rounded-lg p-3 text-center shadow-md"
            >
              <img
                src={example.image}
                alt={example.title}
                className="w-full h-auto object-cover rounded-lg mb-2"
              />
              <p className="text-gray-300 text-xs">{example.title}</p>
              <p className="text-gray-300 text-xs">{example.description}</p>
            </div>
          ))}
        </div>
      </div>
      <div>
        <p className="text-gray-300 text-sm">
          {/* The image you generate must comply with our Terms of Use and Acceptable Use Policy. */}
           Attempting to bypass our safety filters to produce nudity, public figures, or subjects under 18 is prohibited.
        </p>
      </div>
    </div>
   </div>
  );
};

export default ImageGenerationGuide;
