import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { FaPauseCircle, FaPlayCircle } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { MdOutlineInfo } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import img1 from "../../assets/collargePhoto/1.png";
import img2 from "../../assets/collargePhoto/2.png";
import img3 from "../../assets/collargePhoto/3.png";
import img4 from "../../assets/collargePhoto/4.png";
import img5 from "../../assets/collargePhoto/5.png";
import PageLoader from "../../components/PageLoader.jsx";
import { UserProfileDetails } from "../../redux/ReduxSlices/ProfileSlice.jsx";
import apiUrl from "../../services/api";
import GenratedVideo from "./GenratedVideo.jsx";
import HowToUseVideoCollage from "./HowToUseVideoCollage.jsx";
import LibraryCollage from "./LibraryCollage.jsx";
import showNotification from "../../hooks/notificationHook.js";
import axios from "axios";
import { downloadCollectionVideo } from "../../redux/ReduxSlices/videocollectionownload.jsx";

const VideoCollage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const audioRef = useRef(new Audio());

  const [images, setImages] = useState([]);
  const [audio, setAudio] = useState(null);

  const modalRef = useRef(null);
  const videoRef = useRef(null);
  const containerRef = useRef(null);
  const scrollContainerRef = useRef(null);

  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const [collageTitle, setCollageTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [isNull, setIsNull] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [libraryModal, setLibraryModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isVideoModalOpen, setVideoModalOpen] = useState(false);
  const [isTryNowVisible, setIsTryNowVisible] = useState(false);

  const [videoURL, setVideoURL] = useState(null);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [isLibrarySong, setIsLibrarySong] = useState(null);
  const [playingVoiceId, setPlayingVoiceId] = useState(null);
  const [songFromLibrary, setSongFromLibrary] = useState(null);
  const [downloadFileName, setDownloadFileName] = useState(null);
  const [generatedVideoTitle, setGeneratedVideoTitle] = useState(null);
  const [selectedAspectRatio, setSelectedAspectRatio] = useState(null);

  const [selectedDuration, setSelectedDuration] = useState(5);
  const { userDetails } = useSelector((state) => state.user);
  const { data, isLoading, error } = useSelector((state) => state.slideshow);

  const [audioDetails, setAudioDetails] = useState({ title: "", duration: "" });

  const { isVideoloading } = useSelector((state) => state.video)

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft =
        scrollContainerRef.current.scrollWidth;
    }
  }, [images]);
  // Pause video when clicking outside the container
  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {

      if (videoRef.current) {
        // console.log("Pausing video");
        videoRef.current.pause();
        // setVideoURL(null);
      }
    } else {
      console.log("Clicked inside the container");
    }
  };
  
  // Add and clean up event listener for outside clicks
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    const validFiles = files.filter((file) => {
      const validTypes = ["image/png", "image/webp", "image/jpeg"];
      if (!validTypes.includes(file.type)) {
        showNotification("info", `${file.name} is not a valid image file (PNG, WebP, JPEG).`)
        return false;
      }
      if (file.size > 10 * 1024 * 1024) {

        showNotification("info", `${file.name} exceeds the 5 MB size limit.`)
        return false;
      }
      return true;
    });

    const totalImages = images.length + validFiles.length;
    if (totalImages < 2 || totalImages > 30) {
      if (totalImages < 2) {

        showNotification("info", "You need to select at least 2 images.")
      } else {

        showNotification("info", "You can only select up to 30 images.")
      }
      return;
    }

    // Add valid files to the list
    setImages((prevImages) => [...prevImages, ...validFiles]);
    e.target.value = null;
  };

  const handleRemoveImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleAudioChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (currentAudio) {
        currentAudio.pause();
        currentAudio.currentTime = 0;
        setCurrentAudio(null);
        setPlayingVoiceId(null);
        setIsPlaying(false);
      }

      setAudio({ file, isLibrarySong: false });

      // Set the audio file details
      setAudioDetails({ title: file.name, duration: "" });
      setDownloadFileName(file.name);
      // Create an object URL for the audio file and assign it to the audioRef
      const objectURL = URL.createObjectURL(file);
      audioRef.current.src = objectURL;

      // Reset playback state and calculate duration
      setIsPlaying(false);
      audioRef.current.pause();
      audioRef.current.currentTime = 0;

      // Load metadata to fetch the duration
      audioRef.current.addEventListener("loadedmetadata", () => {
        const duration = audioRef.current.duration;
        const minutes = Math.floor(duration / 60);
        const seconds = Math.floor(duration % 60);
        setAudioDetails((prev) => ({
          ...prev,
          duration: `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`,
        }));
      });
    }
  };

  const clearAudio = () => {
    setAudio(null); // Clears the selected audio file
    setIsPlaying(false); // Resets the play/pause state
    if (audioRef.current) {
      audioRef.current.pause(); // Pauses the audio if it's playing
      audioRef.current.currentTime = 0; // Resets the audio playback to the beginning
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if images are selected
    if (!images || images.length < 2) {
      return showNotification("error", "Please select at least two images for Video Collage.")

    }

    // Check if audio is selected
    if (!audio) {
      return showNotification("error", "Please select an audio file.")


    }

    // Check if aspect ratio is selected
    if (!selectedAspectRatio) {
      return showNotification("error", "Please select an Aspect Ratio.")

    }

    if (!collageTitle || collageTitle.trim() === "") {
      return showNotification("error", "Please provide a title for the Slideshow video.")

    }



    if (userDetails.video_credit_point === 0 && userDetails.video_free_trial_count === 0) {

      showNotification("error", "Insufficient Credit to Generate Video. Please Upgrade Your Plan")
      audioRef.current.pause();
      if (currentAudio) {
        currentAudio.pause();
        currentAudio.currentTime = 0;
        setCurrentAudio(null);
        setPlayingVoiceId(null);
        setIsPlaying(false);
      }


      setTimeout(() => {
        navigate("/pricing");
      }, 800);

      return;
    }

    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
      setCurrentAudio(null);
      setPlayingVoiceId(null);
      setIsPlaying(false);
    }

    const MAX_TOTAL_SIZE = 100 * 1024 * 1024; // 100 MB
    let totalSize = 0;

    // Validate file sizes
    images.forEach((image) => (totalSize += image.size));
    totalSize += audio.size;

    if (totalSize > MAX_TOTAL_SIZE) {
      return showNotification("error", "Total file size exceeds the limit of 100 MB.")

    }

    setImages([]);
    clearAudio();
    setVideoURL(null);
    setGeneratedVideoTitle(null);
    setSelectedAspectRatio(null);

    // Create FormData
    const formData = new FormData();
    if (audio.isLibrarySong) {
      // Payload for library song
      formData.append("song_id", songFromLibrary);
      formData.append("aspect_ratio", selectedAspectRatio);
      formData.append("image_time", Number(selectedDuration));
      formData.append("title", collageTitle);
      images.forEach((image) => {
        formData.append("images", image);
      });
    } else {
      // Payload for uploaded file
      formData.append("audio", audio.file);
      formData.append("aspect_ratio", selectedAspectRatio);
      formData.append("image_time", Number(selectedDuration));
      formData.append("title", collageTitle);
      images.forEach((image) => {
        formData.append("images", image);
      });
    }

    // Set loading to true
    setLoading(true);

    try {
      const token = localStorage.getItem("token");
      const response = await fetch(apiUrl.generateSlideShow, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.status === 404) {
        audioRef.current.pause();
        showNotification("error", "Insufficient credit to generate video.")



        navigate("/pricing");
        return; // Exit early
      }

      if (!response.ok) {
        throw new Error("Failed to upload files.");
      }

      await dispatch(UserProfileDetails());

      const data = await response.json();

      const watermarkedVideo = data.slideshow_url;
      setVideoURL(watermarkedVideo);
      setGeneratedVideoTitle(data.title);
      // Show success modal
      // setSuccessMessage("Upload successful!");
      // setShowSuccessModal(true);

      showNotification("success", "Upload successful!")
    } catch (error) {
      console.error("Error:", error);
      // setErrorMessage("An error occurred during upload.");
      showNotification("error", "An error occurred during upload.")
    } finally {
      // Set loading to false after API call
      setLoading(false);
      setCollageTitle("");
    }
  };




  const handleDownload = (videoURL, downloadName) => {

    dispatch(downloadCollectionVideo({ videoURL, downloadName }));
  };


  const handlePlayPause = () => {
    if (isPlaying) {
      if (currentAudio) {
        currentAudio.pause();
      } else {
        audioRef.current.pause();
      }
    } else {
      if (currentAudio) {
        currentAudio.play();
      } else {
        audioRef.current.play();
      }
    }
    setIsPlaying(!isPlaying);
  };

  const handleLibrary = () => {
    if (isPlaying) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPlaying(false);
    }
    setLibraryModal(true);
  };

  const handlePlayPause1 = async (songId, songName) => {
    if (!songId) {
      if (currentAudio) {
        currentAudio.pause();
        currentAudio.currentTime = 0;
      }
      setCurrentAudio(null);
      setPlayingVoiceId(null);
      setIsPlaying(false);
      return;
    }
    if (playingVoiceId === songId.id) {
      // Pause and reset current audio if the same song is clicked
      if (currentAudio) {
        currentAudio.pause();
        currentAudio.currentTime = 0;
      }
      setCurrentAudio(null);
      setPlayingVoiceId(null);
      setIsPlaying(false);
      return;
    }

    // Stop and reset any currently playing audio
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
      setCurrentAudio(null);
      setPlayingVoiceId(null);
      setIsPlaying(false);
    }

    try {
      setLoading(true);

      const token = localStorage.getItem("token");
      const response = await fetch(`${apiUrl.getStreamBlob}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ id: songId.id }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json(); // Assuming the API returns JSON with an audio URL
      const audioUrl = data.audio_url; // Replace with the correct key in your response if different

      if (audioUrl) {
        const audio = new Audio(audioUrl);

        // Attach event listeners for audio
        audio.onloadedmetadata = () => {
          setDuration(audio.duration); // Set the total duration
        };
        audio.ontimeupdate = () => {
          setCurrentTime(audio.currentTime); // Update the current time
        };
        audio.onended = () => {
          setPlayingVoiceId(null); // Reset when playback ends
          setIsPlaying(false);
        };

        // Play the audio
        audio
          .play()
          .then(() => {
            setCurrentAudio(audio);
            setPlayingVoiceId(songId.id); // Correctly set the playing voice ID
            setIsPlaying(true);
          })
          .catch((error) => {
            console.error("Error playing audio:", error);
            setPlayingVoiceId(null); // Reset playing state on error
            setIsPlaying(false);
          });
      } else {
        throw new Error("No URL provided in the response.");
      }
    } catch (error) {
      console.error("Download or playback failed:", error);
      if (error.message.includes("HTTP error")) {

        showNotification("error", `Failed to download. Server responded with: ${error.message}`)
      } else {

        showNotification("error", "An unexpected error occurred during download or playback.")

      }
    } finally {
      setLoading(false);
    }
  };

  const addSongToPlayer = (song) => {
    clearAudio();
    // Stop the currently playing audio
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
      setCurrentAudio(null);
      setPlayingVoiceId(null);
      setIsPlaying(false);
    }

    // Set the new song
    setAudio({ ...song, isLibrarySong: true });
    setAudioDetails({
      title: song.title,
      duration: "",
    });
    setLibraryModal(false);
    setSongFromLibrary(song.id);
    setDownloadFileName(song.title);
    // Handle song play/pause logic
    handlePlayPause1(song);
  };

  const handleInfoClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsModalOpen(false);
      }
    };

    if (isModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isModalOpen]);

  const resetValues = () => {
    // Reset all state variables to their initial values
    setImages([]);
    setAudio(null);
    setAudioDetails({});
    setSelectedAspectRatio("");
    setSelectedDuration("");
    setVideoURL(null);
    setIsPlaying(false);
    setLoading(true); // Reset loading state
    setLoading(false);
    setIsTryNowVisible(false); // Reset video loading state
  };

  const handleDemoClick = () => {
    setVideoModalOpen(true);
  };

  const handleVideoCloseModal = () => {
    setVideoModalOpen(false);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    const trimmedValue = value.replace(/\s/g, "");
    const regex = /^[\p{L}\p{N}\p{Z}\p{Emoji}]*$/u;
    if (trimmedValue.length > 30) {

      showNotification("error", "30 characters are not allowed (excluding spaces)!")

    } else if (!regex.test(value)) {

      showNotification("error", "Special characters are not allowed!")
    } else {
      setCollageTitle(value);
    }
  };

  const handleCloseLibraryModal = () => {
    setLibraryModal(false)
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
      setCurrentAudio(null);
      setPlayingVoiceId(null);
      setIsPlaying(false);
    } 
  }


  return (
    <>

      <div className="min-h-screen text-white p-4 mt-2">

        {isVideoloading ? (
          <div>
            <PageLoader />
          </div>
        ) : (
          <div>
            <div className="mx-auto grid grid-cols-1 md:grid-cols-12 gap-6 ">
              {/* Column 4 */}
              <motion.div
                className="col-span-12 md:col-span-4 p-1 shadow-lg videocomponent-overflow-y"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
                style={{ maxHeight: "100vh", scrollbarWidth: "thin" }}
              >
                <div className="flex justify-between items-center w-full">
                  <h1 className="text-xl font-bold">Slideshow Video</h1>

                  {/* Try Now Button next to the info button */}
                  <div className="flex items-center space-x-4">
                    {" "}
                    <button
                      // onClick={handleSurpriseClick}
                      onClick={handleDemoClick}
                      className="text-white text-sm font-bold text-right px-2 py-0 rounded-md border border-gray-500  hover:text-gray-500"
                    >
                      View Demo
                    </button>
                    <h2
                      className="text-white text-2xl font-bold cursor-pointer"
                      onClick={handleInfoClick}
                    >
                      <MdOutlineInfo />
                    </h2>
                  </div>
                </div>
                {/* Aspect Ration */}
                <div className="mt-4 mb-4">
                  <h2 className="text-sm font-medium mb-2">
                    Choose The Aspect Ratio For Your Video
                  </h2>
                  <div className="flex gap-3">
                    {[
                      {
                        ratio: "9:16",
                        icon: (
                          <svg
                            className="h-4 w-3"
                            viewBox="0 0 9 16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="9"
                              height="16"
                              rx="2"
                              fill="currentColor"
                            />
                          </svg>
                        ),
                      },
                      {
                        ratio: "16:9",
                        icon: (
                          <svg
                            className="h-3 w-6"
                            viewBox="0 0 16 9"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="16"
                              height="9"
                              rx="2"
                              fill="currentColor"
                            />
                          </svg>
                        ),
                      },
                      {
                        ratio: "1:1",
                        icon: (
                          <svg
                            className="h-4 w-4"
                            viewBox="0 0 10 10"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="10"
                              height="10"
                              rx="2"
                              fill="currentColor"
                            />
                          </svg>
                        ),
                      },
                    ].map(({ ratio, icon }) => (
                      <button
                        key={ratio}
                        onClick={() => setSelectedAspectRatio(ratio)}
                        className={`h-9 w-32 rounded-lg flex items-center gap-2 px-2 transition-all duration-300 border ${selectedAspectRatio === ratio
                          ? "border-teal-200 bg-[#328f84]"
                          : "border-gray-600 bg-gray-800"
                          }`}
                      >
                        {icon}
                        <p className="text-sm">{ratio}</p>
                      </button>
                    ))}
                  </div>
                </div>

                {/* Image Picker */}
                <div className="mb-4 ">
                  <label className="block text-sm font-medium mb-2">
                    Select Images (PNG, WebP, JPEG)
                  </label>
                  <div className="border border-dashed border-gray-600 rounded-lg p-4 flex flex-col items-center justify-center">
                    <input
                      type="file"
                      accept="image/png, image/webp, image/jpeg"
                      multiple
                      onChange={handleImageChange}
                      className="hidden"
                      id="imagePicker"
                    />
                    <label
                      htmlFor="imagePicker"
                      className="cursor-pointer bg-gray-700 text-sm font-semibold text-gray-300 py-2 px-4 rounded-lg hover:bg-gray-600"
                    >
                      Upload Images
                    </label>
                  </div>
                  <div>
                    {images.length > 0 && (
                      <div className="mb-3">
                        <h2 className="text-lg mt-2 font-semibold mb-2">
                          Selected Images
                        </h2>
                        {images.length !== 0 && (
                          <p className="mt-2 mb-2 text-green-400">
                            {images.length} Images Selected
                          </p>
                        )}
                        {images.length !== 0 && (
                          <p className="mt-2 text-sm mb-2 text-gray-400">
                            Please arrange the images in the desired order for the
                            Slideshow video. You can remove any image from the bellow
                            list by clicking the red close button.
                          </p>
                        )}
                        <div
                          ref={scrollContainerRef}
                          className="p-4 relative w-full h-40 sm:h-44 lg:h-50 overflow-x-auto rounded-lg border border-gray-600"
                          style={{
                            overflowY: "hidden",
                            overflowX: "auto",
                            scrollbarWidth: "thin",
                            scrollbarColor: "",
                          }}
                        >
                          <div className="flex gap-4">
                            {images.map((image, index) => (
                              <div key={index} className="relative flex-shrink-0">
                                <img
                                  src={
                                    typeof image === "string"
                                      ? image
                                      : URL.createObjectURL(image)
                                  }
                                  alt={`Preview ${index + 1}`}
                                  className="w-32 h-32 object-cover rounded-lg"
                                />
                                <button
                                  onClick={() => handleRemoveImage(index)}
                                  className="absolute top-1 right-1 bg-red-600 text-white p-1 text-xs rounded-full"
                                >
                                  ✕
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Audio Picker */}
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">
                    Select Audio File (MP3 Only)
                  </label>
                  <div className="border border-dashed border-gray-600 rounded-lg p-4 flex flex-col">
                    <div className="flex flex-col sm:flex-row sm:items-center justify-between space-y-4 sm:space-y-0">
                      <div className="flex justify-center sm:justify-start">
                        <input
                          type="file"
                          accept="audio/mpeg"
                          onChange={handleAudioChange}
                          className="hidden"
                          id="audioPicker"
                        />
                        <label
                          htmlFor="audioPicker"
                          className="cursor-pointer bg-gray-700 text-sm font-semibold text-gray-300 py-2 px-4 rounded-lg hover:bg-gray-600 w-full sm:w-auto text-center"
                        >
                          Upload Audio
                        </label>
                      </div>
                      <div className="text-center text-gray-400 text-sm">OR</div>
                      <div className="flex justify-center sm:justify-end">
                        <button
                          onClick={handleLibrary}
                          className="cursor-pointer bg-gray-700 text-sm font-semibold text-gray-300 py-2 px-4 rounded-lg hover:bg-gray-600 w-full sm:w-auto text-center"
                        >
                          Your Library
                        </button>
                      </div>
                    </div>
                    {/* Audio Section */}
                    {audio && (
                      <div className="mt-4 w-full max-w-lg mx-auto p-2 rounded-lg shadow-lg flex items-center gap-16">
                        {/* Play/Pause Button */}
                        <div className="flex-shrink-0">
                          <button
                            onClick={() => {
                              if (audio?.isLibrarySong) {
                                handlePlayPause1(audio);
                              } else {
                                handlePlayPause();
                              }
                            }}
                            className="bg-gray-700 w-10 h-10 rounded-full flex items-center justify-center"
                          >
                            {isPlaying ? (
                              <FaPauseCircle className="text-2xl text-teal-500" />
                            ) : (
                              <FaPlayCircle className="text-2xl text-teal-500" />
                            )}
                          </button>
                        </div>
                        {/* Audio Details */}
                        <div className="text-gray-300 flex flex-col justify-center">
                          <p className="font-semibold text-lg sm:text-sm md:text-sm">
                            {audioDetails.title}
                          </p>
                          {/* <p className="text-sm">
                        {audioDetails.duration || "Loading..."}
                      </p> */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Duration */}
                <div className="mb-4">
                  <h2 className="text-sm font-medium mb-2">
                    Duration For Each Image
                  </h2>
                  <select
                    id="duration"
                    className="h-10 px-4 bg-gray-800  text-white border border-gray-600 rounded-lg w-full custom-dropdown"
                    onChange={(e) => setSelectedDuration(e.target.value)}
                  >
                    {["5", "6", "7", "8", "9", "10"].map((duration) => (
                      <option
                        key={duration}
                        value={duration}
                      >{`${duration} sec`}</option>
                    ))}
                  </select>
                </div>

                {/* Added Title */}
                <div className="mb-4">
                  <h2 className="text-sm font-medium mb-2">
                    Enter Title For Video
                  </h2>
                  <input
                    type="text"
                    name="title"
                    className="w-full px-4 py-2 rounded-lg bg-gray-600 bg-opacity-80 text-white placeholder-text-white"
                    placeholder="Enter Video Title"
                    value={collageTitle}
                    onChange={handleInputChange}
                  />
                </div>

                <button
                  onClick={handleSubmit}
                  className="w-full text-base font-semibold text-black py-3 px-4 rounded-lg custom-button  mt-2 mb-10"
                >
                  Generate Video
                </button>
              </motion.div>

              {/* Column 8 */}
              <motion.div
                className="col-span-12 md:col-span-8 backdrop-blur-md rounded-lg p-1 shadow-lg videocomponent-overflow-y"
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
                style={{ maxHeight: "100vh", scrollbarWidth: "thin" }}
              >
                {/* Video Section */}
                {videoURL && (
                  <div className="text-center mb-6" ref={containerRef}>
                    <div className="inline-block w-96 h-80 max-md:w-full  max-md:h-56 overflow-hidden rounded-lg border border-gray-600 bg-gray-900 generate-collage-video">
                      <video
                        controls
                        ref={videoRef}
                        className="w-full h-full"
                        controlsList="nodownload noremoteplayback"
                        disablePictureInPicture
                      >
                        <source src={videoURL} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                    <div className="text-white text-lg font-semibold mb-4">
                      {generatedVideoTitle}
                    </div>
                    <div>
                      {isTryNowVisible && (
                        <button
                          onClick={resetValues}
                          className="mt-4 bg-teal-600 hover:bg-teal-500 text-white py-2 px-8 rounded-lg"
                        >
                          Try yourself
                        </button>
                      )}
                    </div>
                  </div>
                )}

                {/* GenratedVideo Component */}
                <div
                  className="overflow-y-auto"
                  style={{
                    height: "auto",
                    scrollbarWidth: "thin",
                    scrollbarColor: "",
                  }}
                >
                  <div className="text-white text-xl font-bold mb-5 max-md:mb-3">
                    Your Collage Library
                  </div>
                  <GenratedVideo handleDownload={handleDownload} />
                </div>


              </motion.div>

              <div className="flex justify-center">{loading && <PageLoader />}</div>
            </div>
          </div>
        )}


      </div>

      {/* Library for select audio Section */}
      {libraryModal && (
        <div className="fixed inset-0 flex justify-center items-center w-full h-full z-[1000] bg-black bg-opacity-90">
          {/* Modal Container */}
          <motion.div
            className="h-[400px] w-[60%] max-md:w-[90%] bg-black rounded-lg shadow-xl relative overflow-hidden song-model-border"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            {/* Modal Header */}
            <div className="flex justify-between items-center px-4 py-2 border-b-[0.2px] border-gray-400">
              <h2 className="text-xl font-semibold text-white">Your Library</h2>
              {/* Close Icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4 max-md:w-3 max-md:h-3 cursor-pointer text-white hover:text-red-600"
                onClick={handleCloseLibraryModal}
                viewBox="0 0 320.591 320.591"
              >
                <path
                  d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
                  fill="currentColor"
                ></path>
                <path
                  d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>

            {/* Modal Content */}
            <div className="flex flex-col justify-center items-center h-full p-4">
              <div className="flex-grow overflow-auto w-full mb-10">
                <LibraryCollage
                  handlePlayPause1={handlePlayPause1}
                  playingVoiceId={playingVoiceId}
                  addSongToPlayer={addSongToPlayer}
                />
              </div>
            </div>
          </motion.div>
        </div>
      )}

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-80 z-50 flex justify-center items-center text-black shadow-lg ">
          <div
            className="rounded-lg p-4 model-margin sm:p-6 w-full sm:w-3/4 md:w-1/2 overflow-auto bg-black border border-gray-700"
            ref={modalRef}
          >
            <HowToUseVideoCollage onClose={closeModal} />
          </div>
        </div>
      )}

      {isVideoModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black  z-[1000] ">
          <div className=" w-full h-full max-w-screen-sm">
            {/* Close Button */}
            <button
              onClick={handleVideoCloseModal}
              className="absolute top-4 right-4 z-10 text-2xl hover:text-red-600 text-white px-1 py-0 border border-white rounded-md"
            >
              <IoMdClose />
            </button>

            <video
              className="w-full h-full object-contain " // Changed to object-contain to prevent cropping
              controls
              autoPlay
            >
              <source
                src="https://imas-ai-home.s3.eu-central-003.backblazeb2.com/collage_video_tutorial.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      )}
    </>
  );
};

export default VideoCollage;
