import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SecureLS from "secure-ls";
import brandImage from "../assets/dashboard/brand_img.png";
import { logout } from "../redux/ReduxSlices/UserSlice";
import "../styles/navbar.css";
// import { UserProfileDetails } from "../../redux/ReduxSlices/ProfileSlice";
import { FaBell } from "react-icons/fa6";
import { UserProfileDetails } from "../redux/ReduxSlices/ProfileSlice";

const ls = new SecureLS();

const Navbar = () => {


  const notifications = [
    {
      id: 1,
      name: "Cody Edwards",
      action: "commented on your latest story.",
      image: "https://via.placeholder.com/40",
    },
    {
      id: 2,
      name: "Jean Reeves",
      action: "loved your latest Photo you posted.",
      image: "https://via.placeholder.com/40",
    },
    {
      id: 3,
      name: "Tyler Warren",
      action: "loved your latest Story you posted.",
      image: "https://via.placeholder.com/40",
    },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const dropdownRef = useRef(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { userDetails } = useSelector((state) => state.user);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const handleDropdownToggle = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  const handleOptionClick = (action) => {
    setIsDropdownOpen(false); // Close dropdown
    action(); // Execute the passed action
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const websiteUrl = "https://www.imadeasong.com";

  const handleInviteFriends = (action) => {
    if (action === "whatsapp") {
      const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(
        `Hey! I found this amazing website where you can create personalized songs for any occasion: ${websiteUrl}. Check it out!`
      )}`;
      const link = document.createElement("a");
      link.href = whatsappUrl;
      link.target = "_blank";
      link.rel = "noopener noreferrer";
      link.click();
    } else if (action === "copy") {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(websiteUrl).then(() => {
          alert("Link copied to clipboard!");
        }).catch(() => {
          alert("Failed to copy. Please try again!");
        });
      } else {
        // Fallback for older browsers
        const tempInput = document.createElement("input");
        tempInput.value = websiteUrl;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        alert("Link copied to clipboard!");
      }
    }
  };


  const hiddenPages = [
    "/india_song",
    "/modiji_song",
    "/custom_song_for_parents",
    "/partners",
    "/partners-thanks",
    "/signupI",
    "/signup",
    "/signin",
    "",
  ];
  const hiddenPagesUserLogo = [
    "/india_song",
    "/modiji_song",
    "/custom_song_for_parents",
    "/partners",
    "/partners-thanks",
    "",
  ];

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const avatarLetter = userDetails?.first_name
    ? userDetails?.first_name.charAt(0).toUpperCase()
    : "U";

  useEffect(() => {
    dispatch(UserProfileDetails());
  }, [dispatch, avatarLetter]);

  const handleLogout = async () => {
    const savedCredentials = ls.get("credentials");

    localStorage.clear();
    if (savedCredentials) {
      ls.set("credentials", savedCredentials);
    }

    dispatch(logout());
    setIsModalOpen(false);
    navigate("/signin");
  };

  const accessToken = localStorage.getItem("accessToken");

  return (
    <nav className="w-full flex items-center justify-between py-2 px-6 max-md:px-2 bg-black fixed top-0 z-10 navbar-bottom-radius">
      {/* Logo */}
      <motion.div
        initial={{ y: "-100%" }}
        animate={{ y: 0 }}
        transition={{ duration: 0.3 }}
        className="flex items-center sm:order-1 order-2 sm:justify-start justify-end w-full sm:w-auto"
      >
        {!hiddenPages.includes(location.pathname) && (
          <div
            onClick={() => navigate("/pricing")}
            className="subscribe-btn mr-4 mobile-only"
          >
            Subscribe
          </div>
        )}

        {/* <div className="flex items-center gap-2 sm:gap-2 relative sm:hidden">
          <div className=" absolute right-7 bottom-2 ">
            <FaBell
              className="text-white cursor-pointer text-xl absolute"
              onClick={() => setIsOpen(!isOpen)}
            />
            {isOpen && (
              <div className="absolute right-[-140px] mt-10 w-80 custom-button shadow-lg rounded-lg flex items-center justify-center flex-col">
                <div className="flex items-center justify-between px-4 py-2 border-b">
                  <span className="font-semibold">Notifications</span>
                  <span className="bg-green-500 text-white text-sm px-2 py-1 rounded-full">
                    {notifications.length}
                  </span>
                </div>
                <ul className="divide-y divide-gray-600">
                  {notifications.map((notification) => (
                    <li key={notification.id} className="flex items-center p-4">
                      <img
                        src={notification.image}
                        alt={notification.name}
                        className="w-10 h-10 rounded-full"
                      />
                      <div className="ml-3">
                        <p className="text-sm font-medium text-gray-800">
                          {notification.name}
                        </p>
                        <p className="text-sm text-gray-600">{notification.action}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div> */}

        {!hiddenPagesUserLogo.includes(location.pathname) && (
          <div className="relative" ref={dropdownRef}>
            {/* User Icon */}
            {accessToken === null || userDetails === null ? (
              <FaUserCircle
                className="text-teal-600 mr-2 block sm:hidden cursor-pointer"
                fontSize={38}
                onClick={handleDropdownToggle}
              />
            ) : (
              <div
                className="avatar text-black custom-button rounded-full w-16 h-16 flex items-center justify-center mr-4 sm:hidden p-4"
                style={{
                  padding: "18px",
                }}
                onClick={handleDropdownToggle}
              >
                {avatarLetter}
              </div>
            )}

            {/* Dropdown Menu */}
            {isDropdownOpen && (
              <div className="absolute -right-20 mt-2 w-56 bg-[#232323] text-white shadow-lg rounded-md z-10">
                {accessToken !== null && userDetails !== null ? (
                  <>
                    <div className="px-4 py-2">
                      <div className="font-bold truncate">
                        {userDetails.username}
                      </div>
                      <div className="text-sm text-gray-400">
                        {userDetails.email}
                      </div>
                    </div>
                    <div className="border-t border-gray-700"></div>
                    <ul className="py-2">
                      <li
                        className="px-4 py-2 hover:bg-gray-700 cursor-pointer"
                        onClick={() =>
                          handleOptionClick(() =>
                            navigate("/dashboard", {
                              state: { tab: "Profile" },
                            })
                          )
                        }
                      >
                        View Profile
                      </li>
                      <li
                        className="px-4 py-2 hover:bg-gray-700 cursor-pointer"
                        onClick={() =>
                          handleOptionClick(() => navigate("/about-us"))
                        }
                      >
                        About Us
                      </li>
                      <li
                        className="px-4 py-2 hover:bg-gray-700 cursor-pointer"
                        onClick={() => handleInviteFriends("whatsapp")}
                      >
                        Invite Friends
                      </li>
                      <li
                        className="px-4 py-2 hover:bg-gray-700 cursor-pointer"
                        onClick={() =>
                          handleOptionClick(() => navigate("/pricing"))
                        }
                      >
                        Subscription
                      </li>
                      <li
                        className="px-4 py-2 hover:bg-gray-700 cursor-pointer"
                        onClick={() => handleOptionClick(() => handleLogout())}
                      >
                        Log Out
                      </li>
                    </ul>
                  </>
                ) : (
                  <>
                    <div className="px-4 py-2">
                      <div className="font-bold truncate">Hello, User</div>
                      <div className="text-sm text-gray-400">
                        Welcome to IMadeASong
                      </div>
                    </div>
                    <div className="border-t border-gray-700"></div>
                    <ul className="py-2">
                      <li
                        className="px-4 py-2 hover:bg-gray-700 cursor-pointer"
                        onClick={() => handleInviteFriends("whatsapp")}
                      >
                        Invite Friends
                      </li>
                      <li
                        className="px-4 py-2 hover:bg-gray-700 cursor-pointer"
                        onClick={() =>
                          handleOptionClick(() => navigate("/signin"))
                        }
                      >
                        Sign In
                      </li>
                    </ul>
                  </>
                )}
              </div>
            )}
          </div>
        )}

        <Link to="/">
          <img
            src={brandImage}
            alt="I Made a Song"
            className="h-12 w-20 cursor-pointer sm:h-16 sm:w-24"
          />
        </Link>
      </motion.div>

      {/* Navbar Links - Hidden on mobile */}
      <div className="hidden sm:flex gap-16 mx-auto sm:order-2">
        <Link
          to="/dashboard"
          className="text-white hover:text-[#b2e668] transition duration-300 title-link"
        >
          Create
        </Link>

        <Link
          to="/faq"
          className="text-white hover:text-[#b2e668] transition duration-300 title-link"
        >
          FAQ
        </Link>
        <Link
          to="/pricing"
          className="text-white hover:text-[#b2e668] transition duration-300 title-link"
        >
          Subscribe
        </Link>
        <Link
          to="/contact"
          className="text-white hover:text-[#b2e668] transition duration-300 title-link"
        >
          Contact Us
        </Link>
      </div>

      {/* Navbar Right Side - Log Out button hidden on mobile */}
      <div className="flex gap-4 items-center sm:gap-6 sm:order-3 order-1">
        {/* 
        <div className="flex items-center gap-4 sm:gap-6 relative hidden sm:block">
          <div className=" absolute right-3 bottom-2">
            <FaBell
              className="text-white cursor-pointer text-xl absolute"
              onClick={() => setIsOpen(!isOpen)}
            />
            {isOpen && (
              <div className="absolute right-0 mt-10 w-80 custom-button shadow-lg rounded-lg">
                <div className="flex items-center justify-between px-4 py-2 border-b">
                  <span className="font-semibold">Notifications</span>
                  <span className="bg-green-500 text-white text-sm px-2 py-1 rounded-full">
                    {notifications.length}
                  </span>
                </div>
                <ul className="divide-y divide-gray-600">
                  {notifications.map((notification) => (
                    <li key={notification.id} className="flex items-center p-4">
                      <img
                        src={notification.image}
                        alt={notification.name}
                        className="w-10 h-10 rounded-full"
                      />
                      <div className="ml-3">
                        <p className="text-sm font-medium text-gray-800">
                          {notification.name}
                        </p>
                        <p className="text-sm text-gray-600">{notification.action}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div> */}

        {isLoggedIn && userDetails !== null  ? (
          <button
            onClick={() => setIsModalOpen(true)}
            className="hidden sm:block text-white btn-border py-2 px-4 rounded hover:text-[#00aaff] hover:border-[#00aaff]"
          >
            Log Out
          </button>
        ) : (
          <Link
            to="/signin"
            className="hidden sm:block text-white btn-border py-2 px-4 rounded hover:text-[#00aaff] hover:border-[#00aaff]"
          >
            Sign In
          </Link>
        )}
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 p-4 flex flex-wrap justify-center items-center w-full h-full z-[1000] before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.5)] overflow-auto font-[sans-serif]">
          <div className="w-full max-w-md delete-popup shadow-lg rounded-lg p-6 relative">
            {/* Close Icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-3 cursor-pointer shrink-0 fill-white   hover:fill-red-500 float-right"
              onClick={() => setIsModalOpen(false)}
              viewBox="0 0 320.591 320.591"
            >
              <path
                d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
                data-original="#000000"
              ></path>
              <path
                d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
                data-original="#000000"
              ></path>
            </svg>

            {/* Modal Content */}
            <div className="my-8 text-center">
              <h4 className="text-white text-l font-semibold mt-4">
                Are you sure you want to log out?
              </h4>
            </div>

            {/* Action Buttons */}
            <div className="flex justify-evenly items-center flex-row  ">
              <button
                type="button"
                className="px-8 py-2 rounded-lg text-gray-800 text-sm tracking-wide bg-white"
                onClick={handleLogout}
              >
                Yes
              </button>
              <button
                type="button"
                className="px-8 py-2 rounded-lg text-gray-800 text-sm tracking-wide bg-white"
                onClick={() => setIsModalOpen(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;



