import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { submitContactUsForm } from "../redux/ReduxSlices/contactUsSlice.jsx";
import digikoreOffice from "../assets/dashboard/modern-minimalist-office-black-white.jpg";
import { countryData } from "../utils/countryCode.js";
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import showNotification from "../hooks/notificationHook.js";

function ContactPage() {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    country_code: "+91",
    mobile: "",
    message: "",
  });

  const [captchaText, setCaptchaText] = useState("");
  const [captchaInput, setCaptchaInput] = useState("");
  const [captchaValid, setCaptchaValid] = useState(false);


  const [errorMessages, setErrorMessages] = useState({});
  // Redux state for contact form submission
  const { loading, success, error } = useSelector((state) => state.contactUs);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const generateCaptcha = () => {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 6; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    setCaptchaText(result);
    setCaptchaInput("");
    setCaptchaValid(false);
  };


  const validateForm = () => {
    const errors = {};

    // Validate mobile number
    const selectedCountry = countryData.find((c) => c.code === formData.country_code);
    if (selectedCountry && !selectedCountry.regex.test(formData.mobile)) {
      errors.mobile = `Invalid mobile number for ${selectedCountry.name}.`;
    }

    // Validate other fields (basic example)
    if (!formData.email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
      errors.email = "Please enter a valid email address.";
    }

    setErrorMessages(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!captchaValid) {
      showNotification("error", "Please validate the CAPTCHA before submitting.")

      return;
    }
    if (!validateForm()) {

      return;
    }

    // Dispatch Redux thunk to handle form submission
    dispatch(submitContactUsForm(formData))
      .unwrap()
      .then(() => {
        showNotification("success", "Your request has been successfully submitted.")

        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          country_code: "",
          mobile: "",
          message: "",
        });
        generateCaptcha(); // Reset CAPTCHA
      })
      .catch((error) => {
        console.error("Error submitting the form:", error);
        showNotification("error", "Failed to submit the form. Please try again.")

      });
  };

  useEffect(() => {
    generateCaptcha();
  }, []);

  useEffect(() => {
    if (success) {
      // toast.success("Form submitted successfully!");
    }
    if (error) {
      showNotification("error", "Failed to submit the form. Please try again.")

    }
  }, [success, error]);

  return (
    <section className="overflow-hidden min-h-screen flex items-center justify-start contact-img-height bg-black">
      <div className="relative w-full">
        <img
          className="office-image-contact w-full h-full object-cover rounded-lg"
          src={digikoreOffice}
          alt="digikore office"
        />
        <div className="absolute inset-0 bg-black bg-opacity-80 flex items-center justify-center">
          <div className="max-md:p-2 p-8  rounded-xl shadow-xl w-full">
            <div className="grid grid-cols-1 max-md:mt-8 lg:grid-cols-2 gap-8 mx-4 md:mx-10 lg:mx-20">
              {/* Contact Info */}
              <div className="flex flex-col text-center lg:text-left gap-6">
                <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold text-white">
                  Contact Us
                </h1>
                <div className="text-white space-y-10 ">
                  <div className="flex items-center justify-center max-md:justify-start lg:justify-start gap-5 ">
                    <MdEmail className="theme-color text-3xl" />
                    <a
                      href="mailto:support@imadeasong.com"
                      className="text-sm lg:text-xl"
                    >
                      support@imadeasong.com
                    </a>
                  </div>
                </div>
                <div className="text-white space-y-10">
                  <div className="flex items-center justify-center  max-md:justify-start lg:justify-start gap-5">
                    <FaLocationDot className="theme-color text-3xl sm:text-3xl" />
                    <p className="text-sm lg:text-xl  max-md:text-start">
                      Digikore Studios Lalwani Triumph, Viman Nagar, Pune, MH
                      411014
                    </p>
                  </div>
                </div>
              </div>

              {/* Contact Form */}
              <div className="flex justify-center items-center lg:justify-end">
                <form
                  id="contactForm"
                  onSubmit={handleSubmit}
                  className="space-y-4 w-full lg:w-full"
                >
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <input
                      type="text"
                      name="first_name"
                      className="w-full px-4 py-2 rounded-lg bg-white bg-opacity-80 text-black placeholder-gray-700"
                      placeholder="First Name"
                      required
                      value={formData.first_name}
                      onChange={handleChange}
                    />
                    <input
                      type="text"
                      name="last_name"
                      className="w-full px-4 py-2 rounded-lg bg-white bg-opacity-80 text-black placeholder-gray-700"
                      placeholder="Last Name"
                      required
                      value={formData.last_name}
                      onChange={handleChange}
                    />
                  </div>
                  <input
                    type="email"
                    name="email"
                    className="w-full px-4 py-2 rounded-lg bg-white bg-opacity-80 text-black placeholder-gray-700"
                    placeholder="Email"
                    required
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errorMessages.email && <p className="text-red-700">{errorMessages.email}</p>}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <select
                      name="country_code"
                      className="w-full px-4 py-2.5 rounded-lg bg-white bg-opacity-80 text-black"
                      required
                      value={formData.country_code}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        Select Country Code
                      </option>
                      {countryData.map((country, index) => (
                        <option key={index} value={country.code}>
                          {country.name} ({country.code})
                        </option>
                      ))}
                    </select>
                    <input
                      type="number"
                      name="mobile"
                      className="w-full px-4 py-2 rounded-lg bg-white bg-opacity-80 text-black placeholder-gray-700"
                      placeholder="Mobile"
                      required
                      value={formData.mobile}
                      onChange={handleChange}
                    />
                    {errorMessages.mobile && <p className="error-text text-red-700">{errorMessages.mobile}</p>}
                  </div>
                  <textarea
                    name="message"
                    className="w-full px-4 py-2 rounded-lg bg-white bg-opacity-80 text-black placeholder-gray-700"
                    rows="2"
                    placeholder="Your Message"
                    required
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                  <div>
                    <div className="flex items-center justify-center space-x-24">
                      <div
                        className="bg-gray-100 text-black px-3 py-1 rounded-lg font-mono text-2xl captch-style"
                        aria-hidden="true"
                      >
                        {captchaText}
                      </div>
                      <button
                        type="button"
                        className="text-white underline text-sm mx-1"
                        onClick={generateCaptcha}
                      >
                        Refresh
                      </button>
                    </div>
                    <input
                      type="text"
                      className="w-full px-4 py-2 mt-2 max-md:mt-4 rounded-lg bg-white bg-opacity-80 text-black placeholder-gray-700"
                      placeholder="Enter CAPTCHA"
                      value={captchaInput}
                      onChange={(e) => {
                        setCaptchaInput(e.target.value);
                        setCaptchaValid(e.target.value === captchaText);
                      }}
                    />
                  </div>

                  <button
                    type="submit"
                    className={`w-full py-2 text-center text-lg font-bold rounded-lg transition-all ${!captchaValid
                      ? "custom-button cursor-not-allowed"
                      : "custom-button"
                      }`}
                    disabled={!captchaValid}
                  >
                    {loading ? "Sending..." : "Send"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactPage;
