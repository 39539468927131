import React, { useState } from "react";
import { IoMdImages, IoMdMusicalNotes, IoMdSettings, IoMdCreate, IoMdDownload } from "react-icons/io";
import { IoClose } from "react-icons/io5";

const HowToUseVideoCollage = ({ onClose }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  // Steps for the guide
  const steps = [
    {
      id: 1,
      icon: <IoMdImages className="text-lg text-blue-500" />,
      description: "You can upload a minimum of 2 images and a maximum of 30 images, with each image size not exceeding 5 MB.",
    },
    {
      id: 2,
      icon: <IoMdMusicalNotes className="text-lg text-green-500" />,
      description: "Choose music from your device or library.",
    },
    {
      id: 3,
      icon: <IoMdSettings className="text-lg text-yellow-500" />,
      description: "Select the images duration and aspect ratio.",
    },
    {
      id: 4,
      icon: <IoMdCreate className="text-lg text-red-500" />,
      description: "Click 'Generate video' to create your own video collage."
    },
    {
      id: 5,
      icon: <IoMdDownload className="text-lg text-purple-500" />,
      description: "Download the generated video from your library.",
    },
  ];

  return (
    <div className="p-6  rounded-lg shadow-md bg-black">
      <div className="relative">
        <h2 className="text-base font-bold mb-4 text-white">How to Create a Video Collage</h2>
        <button className="absolute top-0 right-0 text-white  rounded-md text-2xl hover:text-red-700" onClick={onClose}><IoClose /></button>
      </div>

      <div className="space-y-4">
        {steps.map((step) => (
          <div key={step.id} className="flex items-center space-x-3">
            <span>{step.icon}</span>
            <span className="text-gray-300 text-sm"  >{step.description}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowToUseVideoCollage;
