import React, { useRef, useState } from 'react';
import { FaPauseCircle, FaPlay } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import FamilyImage from '../../assets/viral_images/PARENTS BG.png';
import parentImageBG from '../../assets/viral_images/PARENTS_BG.png';
import ParentImage from '../../assets/viral_images/Vector Smart Object.png';
import '../../styles/viralPages.css';
import { FaWhatsapp } from "react-icons/fa";

const India = () => {
  const audioRef = useRef(null);
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const websiteUrl = "https://www.imadeasong.com/india_song";

  const handleInviteFriends = (action) => {
    if (action === "whatsapp") {
      const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(
        `Hey! This is too good. A Tribute song to our great nation made in AI. Must see: ${websiteUrl}`
      )}`;
      const link = document.createElement("a");
      link.href = whatsappUrl;
      link.target = "_blank";
      link.rel = "noopener noreferrer";
      link.click();
    } else if (action === "copy") {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(websiteUrl).then(() => {
          alert("Link copied to clipboard!");
        }).catch(() => {
          alert("Failed to copy. Please try again!");
        });
      } else {
        // Fallback for older browsers
        const tempInput = document.createElement("input");
        tempInput.value = websiteUrl;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        alert("Link copied to clipboard!");
      }
    }
  };

  const togglePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      const currentTime = audioRef.current.currentTime;
      const duration = audioRef.current.duration;
      setProgress((currentTime / duration) * 100);
    }
  };

  const handleSeek = (e) => {
    if (audioRef.current) {
      const newTime = (e.target.value / 100) * audioRef.current.duration;
      audioRef.current.currentTime = newTime;
      setProgress(e.target.value);
    }
  }

  return (
    <div className="min-h-screen">
      <div className="mx-auto bg-white ">
        {/* Header Section */}
        <img src={ParentImage} alt="Parentimage" className='w-full h-96 max-md:h-44' />
        <div className="text-center py-2  first-patch-bg">
          <h1
            className="max-md:text-xl text-6xl font-bold header-text-color font-mono "
            style={{
              fontFamily: '"Cormorant Garamond", "Playfair Display", serif',
              letterSpacing: '0.10em',
              fontWeight: 500,
              lineHeight: '1.2',
            }}
          >
            INDIA’S FIRST-EVER
            <span
              className="block"
              style={{
                display: 'inline-block',
                fontFamily: '"Cormorant Garamond", serif',
                letterSpacing: '0.10em',
              }}
            >
              AI-POWERED SONG PLATFORM!
            </span>
          </h1>

          {/* Message Section */}
          <div className="text-center py-4 first-patch-bg ">
            <p className="max-md:text-base text-2xl text-gray-800 secondary-text-color max-md:font-normal font-bold  leading-tight mb-6 max-md:mb-0 md:hidden" style={{ lineHeight: '1.2' }}>
              A heartfelt tribute to our great nation.<br /> This isn’t just a song it’s a celebration of
              India’s spirit, created entirely by AI, <br />capturing the essence of our homeland.
            </p>
            <p className="max-md:text-base text-2xl text-gray-800 secondary-text-color max-md:font-normal font-semibold  leading-tight mb-6 max-md:mb-0 hidden md:block">
              A heartfelt tribute to our great nation. This isn’t just a song it’s a celebration of
              India’s spirit, created entirely by AI, <br />capturing the essence of our homeland.
            </p>
          </div>

          <div className="flex items-center justify-center w-full py-2 bg-[#f8f1e7]">
            {/* Line before button */}
            <div className="flex items-center w-full max-w-2xl px-4 max-md:px-8">
              <span className="flex-grow h-[1px] bg-[#4d2d32]"></span>

              {/* Button */}
              <button className="px-6 py-2 text-black font-bold text-base md:text-base rounded-full">
                LISTEN NOW
              </button>

              {/* Line after button */}
              <span className="flex-grow h-[1px] bg-[#4d2d32]"></span>
            </div>
          </div>

          <div className="flex items-center justify-center mt-8 max-md:mt-4 first-patch-bg mb-8 max-md:mb-4">
            <div className="middle-banner max-md:w-[80%] w-[20%] px-4 py-3 rounded-lg shadow-md flex items-center">
              <div className="flex items-center justify-center w-16 h-16 bg-gradient-to-r from-[#FF9933] via-[#FFFFFF] to-[#138808] rounded-full mr-4">
                <button className="flex justify-center" onClick={togglePlayPause}>
                  {!isPlaying ? (
                    <FaPlay color="#96471b" size={24} />
                  ) : (
                    <FaPauseCircle color="#96471b" size={24} />
                  )}
                </button>
              </div>
              <div className="flex-1">
                <h2 className="text-white text-start text-base font-semibold">
                  Naya Bharat Udega
                </h2>
                <div className="relative mt-0">
                  <input
                    type="range"
                    className="w-full h-1 bg-white rounded-lg appearance-none cursor-pointer accent-red-500"
                    value={progress}
                    onChange={handleSeek}
                  />
                </div>
              </div>
            </div>
            {/* Hidden audio element */}
            <audio
              ref={audioRef}
              src="https://imas-ai-home.s3.eu-central-003.backblazeb2.com/indiasong.mp3"
              onTimeUpdate={handleTimeUpdate}
            />
          </div>
        </div>

        <p className='bg-[#fef4e5] text-center font-bold  pb-4 max-md:pb-1 text-2xl max-md:text-base'>
          🇮🇳 A Proud Moment for Every Indian! 🇮🇳
        </p>
        <p className='bg-[#fef4e5] text-center pb-4 max-md:pb-1 secondary-text-color leading-tight' style={{ lineHeight: '1.2' }}>
          Let every Indian hear this beautiful creation!
        </p>
        <p className='bg-[#fef4e5] text-center font-bold  pb-4 max-md:pb-1 text-xl max-md:text-base'>
          Let’s make this song go viral!
        </p>
        <p className='bg-[#fef4e5] text-center pb-8 max-md:pb-3 secondary-text-color px-2 leading-tight' style={{ lineHeight: '1.2' }} >
          This is not just a song – it’s the heartbeat of our Bharat! Share it far and wide so the world can feel our pride, our unity, and our love for India. ❤️
        </p>

        <div className="flex justify-center bg-[#fef4e5] pb-6">
          <button
            onClick={() => handleInviteFriends("whatsapp")}
            className="text-white font-bold text-base viral-share-btn max-md:w-[70%]"
          >
            <FaWhatsapp className="h-5 w-5 mr-4" />
            Share on WhatsApp
          </button>
        </div>

        <img src={parentImageBG} alt="parent bg image" className='lg:hidden' />

        {/* Highlight Section */}
        <div className="middle-banner text-white text-center max-md:py-2 py-10  px-4">
          {/* Mobile Screen */}
          <h2 className="text-2xl font-bold md:hidden">
            BECAUSE SOME EMOTIONS <span className="block">DESERVE A SPECIAL SONG!</span>
          </h2>

          {/* Desktop Screen */}
          <h2 className="text-5xl font-bold hidden md:block">
            BECAUSE SOME EMOTIONS DESERVE A SPECIAL SONG!
          </h2>

          <p className="max-md:mt-2  mt-6 max-md:text-sm text-xl leading-tight">
            Whether it's the pride of being Indian or the love for our parents, some emotions are best
            expressed through a song.
          </p>
          <div className="justify-center items-center py-2 hidden max-md:block">
            <button onClick={() => navigate("/custom_song_for_parents")} className="bg-white text-black font-bold text-sm md:text-base py-2 px-6 rounded-full shadow-lg hover:shadow-xl transition-shadow">
              CREATE A SONG FOR FREE
            </button>
          </div>
        </div>

        {/* Call to Action Section */}
        <div className='flex justify-center max-md:flex-col mt-6 max-md:mt-0'>
          <div className="bg-white text-center rounded-lg py-6 px-8 mt-4 relative lg:py-12 lg:px-16 max-md:mb-0">
            <div className="patch-border relative pb-12 lg:pb-16">
              <p className="text-lg md:text-2xl lg:text-3xl text-black font-semibold leading-tight">
                Your parents have always been there for you,
                <span className="font-normal block">
                  now it’s your turn to show them
                </span>
                <span className="block">
                  how much they mean to you!
                </span>
              </p>
              <p className="mt-6 text-base md:text-lg lg:text-2xl font-bold secondary-text-color mb-8 lg:mt-8 lg:mb-12 leading-tight">
                Make a personalized song to thank <br />them for everything they have done for you!
              </p>
            </div>

            <button
              className="create-song-bg create-song-text text-white text-sm py-2 px-6 mt-6 max-md:mb-0 mb-5 rounded-full border-2 absolute bottom-[10px] left-1/2 transform -translate-x-1/2 lg:py-3 lg:px-8"
              style={{ borderColor: '#c4484a' }}
              onClick={() => navigate("/custom_song_for_parents")}
            >
              CREATE A SONG FOR FREE
            </button>
          </div>

          {/* Image Section */}
          <div className="relative w-full mb-10 max-md:mt-0 flex-1">
            {/* Main content */}
            <div className="relative flex  justify-center items-center mt-2 max-md:hidden">
              <div className="blur-border">
                <img
                  src={FamilyImage}
                  alt="Couple Smiling with Tablet"
                  className="w-full max-md:h-60 h-80"
                />
              </div>

            </div>
          </div>
        </div>
      </div>
    </div >
  );

};

export default India;
