// store.js or index.js where you configure your store
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./ReduxSlices/UserSlice";
import userReducer from "./ReduxSlices/ProfileSlice.jsx";
import creditReducer from "./ReduxSlices/creditSlice.jsx";
import revieweReducer from "./ReduxSlices/reviewSlice.jsx";
import voicesReducer from "./ReduxSlices/voicesSlice.jsx";
import uploadAudioReducer from "./ReduxSlices/uploadAudioSlice.jsx";
import downloadAudioReducer from "./ReduxSlices/downloadAudioSlice.jsx";
import openAiReducer from "./ReduxSlices/openAISlice.jsx";
import freeTrialReducer from "./ReduxSlices/freeTrialSlice.jsx";
import lyricsReducer from "./ReduxSlices/lyricsSlice.jsx";
import customeGenerateReducer from "./ReduxSlices/customGenerateSlice.jsx";
import ForgotPasswordReducer from "./ReduxSlices/forgotPasswordSlice.jsx";
import contactUsReducer from "./ReduxSlices/contactUsSlice.jsx";
import waterMarkAudioReducer from "./ReduxSlices/waterMarkSlice.jsx";
import slideshowReducer from "./ReduxSlices/slideShowSlice.jsx";
import ipDataReducer from "./ReduxSlices/ipGetInfo.jsx"
import voicesSliceAudioReducer from "./ReduxSlices/voicesSlice.jsx"
import dataHomeCollectionReducer from "./ReduxSlices/homeCollectionSlice.jsx"
import videoDownloadSlice from "./ReduxSlices/videocollectionownload.jsx"


const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    credits: creditReducer,
    review: revieweReducer,
    voices: voicesReducer,
    uploadAudio: uploadAudioReducer,
    downloadAudio: downloadAudioReducer,
    openAi: openAiReducer,
    freeTrial: freeTrialReducer,
    lyrics: lyricsReducer,
    song: customeGenerateReducer,
    forgotPassword: ForgotPasswordReducer,
    contactUs: contactUsReducer,
    audio: waterMarkAudioReducer,
    slideshow: slideshowReducer,
    ipData: ipDataReducer,
    voiceAudio: voicesSliceAudioReducer,
    homeData: dataHomeCollectionReducer,
    video:videoDownloadSlice,
  },
});

export default store;
