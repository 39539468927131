import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";




const wave = keyframes`
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

const Body = styled.div`
  background: linear-gradient(45deg, rgb(0, 0, 0), rgb(6, 7, 6), rgb(0, 0, 0), rgb(11, 12, 13), rgb(0, 0, 0));
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  font-size: 24px;
`;

const WaveLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  gap: 5px;
`;

const Wave = styled.div`
  width: 10px;
  height: 90px;
  background: linear-gradient(180deg, #00d2ff, #3ae374);
  border-radius: 20px;
  animation: ${wave} 1.5s linear infinite;

  &:nth-child(2) {
    animation-delay: 0.1s;
  }
  &:nth-child(3) {
    animation-delay: 0.2s;
  }
  &:nth-child(4) {
    animation-delay: 0.3s;
  }
  &:nth-child(5) {
    animation-delay: 0.4s;
  }
  &:nth-child(6) {
    animation-delay: 0.5s;
  }
  &:nth-child(7) {
    animation-delay: 0.6s;
  }
  &:nth-child(8) {
    animation-delay: 0.5s;
  }
  &:nth-child(9) {
    animation-delay: 0.5s;
  }
  &:nth-child(10) {
    animation-delay: 0.5s;
  }
`;

// const Message = styled.p`
//   margin-top: 52px;
//   text-align: center;
//   font-size: 1.5rem;
// `;




const CustomCenterLoader = () => {
  const [dots, setDots] = useState("");
  const [message, setMessage] = useState("Hold on a moment... I'm working on the lyrics");

  useEffect(() => {
    const dotInterval = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots.length === 3) return "";
        return prevDots + ".";
      });
    }, 500);

    // Cycle through the messages
    const messageTimeouts = [
      setTimeout(() => setMessage("Your melody is ready to play, just a little tune-up away"), 22000),
      setTimeout(() => setMessage("We're creating a beautiful song... just for you"), 15000),
    ];

    return () => {
      clearInterval(dotInterval);
      messageTimeouts.forEach(timeout => clearTimeout(timeout));
    };
  }, []);

  return (
    // <StyledWrapper
    //   style={{
    //     top: '0',
    //     left: '0',
    //     background: "linear-gradient(45deg, rgb(0, 0, 0), rgb(6 7 6), rgb(0 0 0), rgb(11 12 13), rgb(0 0 0))",
    //     height: "100vh",
    //     width: "100vw",
    //     position: "fixed",
    //     zIndex: 9999,
    //     padding: 0,
    //     margin: 0,
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     flexDirection: "column",
    //     color: "#fff",
    //     fontSize: "24px",
    //   }}
    // >
    //   <div className="cube-loader">
    //     <div className="cube-top"></div>
    //     <div className="cube-wrapper">
    //       <span style={{ "--i": 0 }} className="cube-span"></span>
    //       <span style={{ "--i": 1 }} className="cube-span"></span>
    //       <span style={{ "--i": 2 }} className="cube-span"></span>
    //       <span style={{ "--i": 3 }} className="cube-span"></span>
    //     </div>
    //   </div>
    //   <p className="mt-12 text-xl main-loader-title ">{message}{dots}</p>
    // </StyledWrapper>




    <Body style={{
      top: '0',
      left: '0',
      background: "linear-gradient(45deg, rgb(0, 0, 0), rgb(6 7 6), rgb(0 0 0), rgb(11 12 13), rgb(0 0 0))",
      height: "100vh",
      width: "100vw",
      position: "fixed",
      zIndex: 9999,
      padding: 0,
      margin: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: "#fff",
      fontSize: "24px",
    }} >
      <WaveLoader>
        {[...Array(10)].map((_, index) => (
          <Wave key={index} />
        ))}
      </WaveLoader>

      <p className="mt-20 text-xl main-loader-title ">{message}{dots}</p>


    </Body>

  );
};

// const StyledWrapper = styled.div`
//   .cube-loader {
//     margin-top: 0px;
//     position: relative;
//     width: 75px;
//     height: 75px;
//     transform-style: preserve-3d;
//     transform: rotateX(-30deg);
//     animation: animate 4s linear infinite;
//   }

//   @keyframes animate {
//     0% {
//       transform: rotateX(-30deg) rotateY(0);
//     }
//     100% {
//       transform: rotateX(-30deg) rotateY(360deg);
//     }
//   }

//   .cube-loader .cube-wrapper {
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     transform-style: preserve-3d;
//   }

//   .cube-loader .cube-wrapper .cube-span {
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     transform: rotateY(calc(90deg * var(--i))) translateZ(37.5px);
//     background: linear-gradient(
//       to bottom,
//       hsl(330, 3.13%, 25.1%) 0%,
//       hsl(177.27, 21.71%, 32.06%) 5.5%,
//       hsl(176.67, 34.1%, 36.88%) 12.1%,
//       hsl(176.61, 42.28%, 40.7%) 19.6%,
//       hsl(176.63, 48.32%, 43.88%) 27.9%,
//       hsl(176.66, 53.07%, 46.58%) 36.6%,
//       hsl(176.7, 56.94%, 48.91%) 45.6%,
//       hsl(176.74, 62.39%, 50.91%) 54.6%,
//       hsl(176.77, 69.86%, 52.62%) 63.4%,
//       hsl(176.8, 76.78%, 54.08%) 71.7%,
//       hsl(176.83, 83.02%, 55.29%) 79.4%,
//       hsl(176.85, 88.44%, 56.28%) 86.2%,
//       hsl(176.86, 92.9%, 57.04%) 91.9%,
//       hsl(176.88, 96.24%, 57.59%) 96.3%,
//       hsl(176.88, 98.34%, 57.93%) 99%,
//       hsl(176.89, 99.07%, 58.04%) 100%
//     );
//   }

//   .cube-top {
//     position: absolute;
//     width: 75px;
//     height: 75px;
//     background: hsl(330, 3.13%, 25.1%) 0%;
//     transform: rotateX(90deg) translateZ(37.5px);
//     transform-style: preserve-3d;
//   }

//   .cube-top::before {
//     content: '';
//     position: absolute;
//     width: 75px;
//     height: 75px;
//     background: hsl(176.61, 42.28%, 40.7%) 19.6%;
//     transform: translateZ(-90px);
//     filter: blur(10px);
//     box-shadow: 0 0 10px #323232,
//                 0 0 20px hsl(176.61, 42.28%, 40.7%) 19.6%,
//                 0 0 30px #323232,
//                 0 0 40px hsl(176.61, 42.28%, 40.7%) 19.6%;
//   }
// `;


export default CustomCenterLoader;
