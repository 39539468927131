import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrl from "../../services/api";
import showNotification from "../../hooks/notificationHook";

export const fetchVoicesAudioCloning = createAsyncThunk(
  "voices/fetchVoices",
  async (_, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(apiUrl.fetchVoiceAudioCloning, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const generateAiClone = createAsyncThunk(
  "voices/generateClone",
  async (jobId, { rejectWithValue }) => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.get(
        apiUrl.generateAiCloneVideo.replace("{job_id}", jobId),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const aiCloneStatus = createAsyncThunk(
  "voices/aiCloneStatus",
  async (jobId, { rejectWithValue }) => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.get(apiUrl.getAiCloneStatus, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message); // Handle errors
    }
  }
);

export const generateImageUrl = createAsyncThunk(
  "voices/generateImage",
  async (payload, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const formData = new FormData();
      formData.append("file", payload);
      const response = await axios.post(apiUrl.getImageUrlClone, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error("generateImageUrl error:", error);
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);
export const getImageOpinion = createAsyncThunk(
  "voices/getImageOpinion",
  async (payload, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const formData = new FormData();
      formData.append("file", payload);
      const response = await axios.post(apiUrl.getImageOpinion, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.status === "error") {
        showNotification("warning", `Info: ${response.data.reason}`, 7);
      }
      if (response.data.status === "warning") {
        showNotification("warning", `Info: ${response.data.feedback[0]}`, 7);
      }
      return response.data;
    } catch (error) {
      console.error("generateImageUrl error:", error);
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// export const uploadAudioFile = createAsyncThunk(
//   "audio/uploadFile",
//   async (payload, { rejectWithValue }) => {
//     console.log(typeof(payload), "check type of payload")
//     const token = localStorage.getItem("token");
//     console.log("inst",payload instanceof Blob)
//     try {
//       const formData = new FormData();
//       formData.append("file", payload);
//       const response = await axios.post(apiUrl.uploadAudioClone, formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//           Authorization: `Bearer ${token}`,
//         },
//       });

//       return response.data;
//     } catch (error) {
//       console.error("uploadAudioFile error:", error);
//       return rejectWithValue(error.response?.data?.message || error.message);
//     }
//   }
// );

export const uploadAudioFile = createAsyncThunk(
  "audio/uploadFile",
  async (payload, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const formData = new FormData();

      if (payload instanceof Blob) {
        formData.append("file", payload);
      } else {
        formData.append("id", payload);
      }

      const response = await axios.post(apiUrl.uploadAudioClone, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error("uploadAudioFile error:", error);
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const submitVoiceData = createAsyncThunk(
  "voices/submitVoiceData",
  async (payload, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(apiUrl.submitVoiceClone, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error("submitVoiceData error:", error);
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const processVoiceGeneration = createAsyncThunk(
  "voices/processVoiceGeneration",
  async (
    { croppedImage, audio, selectedAspectRatio, selectedOption, title },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const imageResponse = await dispatch(
        generateImageUrl(croppedImage)
      ).unwrap();

      const audioResponse = await dispatch(uploadAudioFile(audio)).unwrap();

      const submitPayload = {
        text: "",
        voiceId: selectedOption.voice_id ? selectedOption.voice_id : "",
        voiceUrl: audioResponse?.data?.url,
        avatarImage: imageResponse?.data?.url,
        aspectRatio: selectedAspectRatio ? selectedAspectRatio : "",
        audioSource: "tts",
        title: title ? title : "",
        avatarImageInput: {
          seed: 5,
          prompt: "Renaissance painter with a beret",
        },
      };

      const submitResponse = await dispatch(
        submitVoiceData(submitPayload)
      ).unwrap();

      const jobId = submitResponse?.data?.jobId;
      // localStorage.setItem("jobIdLocal", jobId)
      const cloneResponse = await dispatch(generateAiClone(jobId)).unwrap();

      return audioResponse;
    } catch (error) {
      console.error("Error in processVoiceGeneration:", error);
      return rejectWithValue(error);
    }
  }
);

export const downloadAiVideo = createAsyncThunk(
  "slideshow/downloadVideo",
  async (payload, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(apiUrl.downloadAiCloneVideo, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      return response.data; // Return the API response data
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
          "An error occurred while downloading the video."
      );
    }
  }
);

export const deleteAiVideo = createAsyncThunk(
  "slideshow/deleteAiVideo",
  async (originalUrl, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        apiUrl.deleteAiCloneVideo,
        { original_url: originalUrl },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
          "An error occurred while deleting the video."
      );
    }
  }
);

const voicesSliceAudio = createSlice({
  name: "voices",
  initialState: {
    voices: [],
    loading: false,
    error: null,
    cloneLoading: false,
    downloadClone: false,
    job: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVoicesAudioCloning.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchVoicesAudioCloning.fulfilled, (state, action) => {
        state.voices = action.payload;
        state.loading = false;
      })
      .addCase(fetchVoicesAudioCloning.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(processVoiceGeneration.pending, (state) => {
        state.cloneLoading = true;
        state.error = null;
      })
      .addCase(processVoiceGeneration.fulfilled, (state, action) => {
        state.voices = action.payload;
        state.cloneLoading = false;
      })
      .addCase(processVoiceGeneration.rejected, (state, action) => {
        state.error = action.payload;
        state.cloneLoading = false;
      })
      .addCase(downloadAiVideo.pending, (state) => {
        state.downloadAiVideo = true;
        state.error = null;
      })
      .addCase(downloadAiVideo.fulfilled, (state, action) => {
        state.voices = action.payload;
        state.downloadAiVideo = false;
      })
      .addCase(downloadAiVideo.rejected, (state, action) => {
        state.error = action.payload;
        state.downloadAiVideo = false;
      })
      .addCase(submitVoiceData.pending, (state) => {
        state.cloneLoading = true;
        state.error = null;
      })
      .addCase(submitVoiceData.fulfilled, (state, action) => {
        state.voices = action.payload;
        state.job = action.payload;
        state.cloneLoading = false;
      })
      .addCase(submitVoiceData.rejected, (state, action) => {
        state.error = action.payload;
        state.cloneLoading = false;
      })
      .addCase(deleteAiVideo.pending, (state) => {
        state.cloneLoading = true;
        state.error = null;
      })
      .addCase(deleteAiVideo.fulfilled, (state, action) => {
        state.voices = action.payload;
        state.job = action.payload;
        state.cloneLoading = false;
      })
      .addCase(deleteAiVideo.rejected, (state, action) => {
        state.error = action.payload;
        state.cloneLoading = false;
      })
      .addCase(aiCloneStatus.pending, (state) => {
        state.cloneLoading = true;
        state.error = null;
      })
      .addCase(aiCloneStatus.fulfilled, (state, action) => {
        state.voices = action.payload;
        state.job = action.payload;
        state.cloneLoading = false;
      })
      .addCase(aiCloneStatus.rejected, (state, action) => {
        state.error = action.payload;
        state.cloneLoading = false;
      })
      .addCase(getImageOpinion.pending, (state) => {
        state.cloneLoading = true;
        state.error = null;
      })
      .addCase(getImageOpinion.fulfilled, (state, action) => {
        state.voices = action.payload;
        state.job = action.payload;
        state.cloneLoading = false;
      })
      .addCase(getImageOpinion.rejected, (state, action) => {
        state.error = action.payload;
        state.cloneLoading = false;
      });
  },
});

export default voicesSliceAudio.reducer;
