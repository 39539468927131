import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { AiFillPauseCircle, AiFillPlayCircle } from "react-icons/ai";
import { FaPlusCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import MusicImage from "../../assets/dashboard/music.webp";
import PageLoader from "../../components/PageLoader";
import showNotification from "../../hooks/notificationHook";
import { newYearSongs } from "../../utils/newYearSongs";
import LibraryCollage from "../VideoCollage/LibraryCollage.jsx";
import apiUrl from "../../services/api.jsx";

export default function UploadAudio({
  audio,
  setAudio,
  selectedOption,
  setSelectedOption,
  checkAudio,
  setCheckAudio
}) {
  // const [audio, setAudio] = useState(null);
  const [audioName, setAudioName] = useState("");
  const [preview, setPreview] = useState(audio);
  const [isPlaying, setIsPlaying] = useState(false);
  const [dropdownAudioStates, setDropdownAudioStates] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [libraryModal, setLibraryModal] = useState(false);
  const [category, setCategory] = useState("male");
  const [newyearSongsList, setNewYearSongsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [yourLibraryModal, setYourLibraryModal] = useState(false);
  const audioRef = useRef(null);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [playingVoiceId, setPlayingVoiceId] = useState(null);
  const [songFromLibrary, setSongFromLibrary] = useState(null);
  const [selectedSongFromLibrary, setSelectedSongFromLibrary] = useState(null);
  const [libraryModalPreview, setLibraryModalPreview] = useState(null);
  const [isLibrarySong, setIslibrarySong] = useState(false);
  const dispatch = useDispatch();

  const { voices } = useSelector((state) => state.voiceAudio);

  // useEffect(() => {
  //   dispatch(fetchVoicesAudioCloning());
  // }, [dispatch]);

  const stopCurrentAudio = () => {
    if (audioRef.current && isPlaying) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0; // Reset playback position to the beginning
      setIsPlaying(false); // Update the state to reflect that audio is no longer playing
    }
  };

  useEffect(() => {
    if (audio) {
      if (audio instanceof Blob || audio instanceof File) {
        try {
          const audioUrl = URL.createObjectURL(audio);
          setPreview(audioUrl);
        } catch (error) {
          console.error("Error creating object URL:", error);
        }
      } else {
        console.error("Invalid audio type. Must be Blob or File.");
      }
    } else {
      setPreview(null);
      console.warn("Audio is undefined or null.");
    }
  }, [audio]);

  const handleAudioChange = (input, audioName = "") => {
    let file;
    if (input instanceof Blob || input instanceof File) {
      file = input;
    } else if (input?.target?.files) {
      file = input.target.files[0];
      if (currentAudio) {
        currentAudio.pause();
        currentAudio.currentTime = 0;
        setCurrentAudio(null);
        setPlayingVoiceId(null);
        setIsPlaying(false);
      }
      showNotification("success", `${audioName} Song Selected successfully`);
    } else {
      console.error("Invalid input provided to handleAudioChange");
      return;
    }

    const maxFileSize = 5 * 1024 * 1024;
    const validType = "audio/mpeg";

    if (file) {
      if (file.type !== validType) {
        showNotification(
          "error",
          "Invalid file type. Please upload an MP3 file."
        );

        return;
      }

      // if (file.size > maxFileSize) {
      // showNotification("error","File size exceeds 5 MB. Please select a smaller file.")

      //   return;
      // }

      setAudio(file);
      setAudioName(file.name ? file.name : audioName);
      setIslibrarySong(false);
      setPreview(URL.createObjectURL(file));
      setIsPlaying(false);
    }
  };

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleAudioEnded = () => {
    setIsPlaying(false);
  };

  const handleSeek = (e) => {
    const progressBar = e.currentTarget;
    const rect = progressBar.getBoundingClientRect();
    const clickX = e.clientX - rect.left;
    const newTime = (clickX / rect.width) * duration;
    if (audioRef.current) {
      audioRef.current.currentTime = newTime;
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  const options = voices?.data?.supported_voices || [];
  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  // const handleDropdownAudioPlayPause = (optionIndex, url) => {
  //   // Stop all other audios before playing the new one
  //   Object.keys(newyearSongsList).forEach((index) => {
  //     if (index !== optionIndex && dropdownAudioStates[index]?.audio) {
  //       dropdownAudioStates[index].audio.pause();
  //       setDropdownAudioStates((prevState) => ({
  //         ...prevState,
  //         [index]: { ...prevState[index], isPlaying: false },
  //       }));
  //     }
  //   });

  //   // Check if the audio for this index is already playing
  //   const currentAudio =
  //     dropdownAudioStates[optionIndex]?.audio || new Audio(url);

  //   // Play or pause the current audio based on its current state
  //   if (dropdownAudioStates[optionIndex]?.isPlaying) {
  //     currentAudio.pause();
  //   } else {
  //     currentAudio.play();
  //   }

  //   // Set the audio state
  //   setDropdownAudioStates((prevState) => ({
  //     ...prevState,
  //     [optionIndex]: {
  //       audio: currentAudio,
  //       isPlaying: !dropdownAudioStates[optionIndex]?.isPlaying,
  //     },
  //   }));

  //   // Handle when the audio ends
  //   currentAudio.onended = () => {
  //     setDropdownAudioStates((prevState) => ({
  //       ...prevState,
  //       [optionIndex]: { isPlaying: false },
  //     }));
  //   };
  // };

  const handleDropdownAudioPlayPause = (optionIndex, url) => {
    // Pause all other audios and reset their states
    Object.keys(dropdownAudioStates).forEach((index) => {
      if (index !== optionIndex && dropdownAudioStates[index]?.audio) {
        const otherAudio = dropdownAudioStates[index].audio;
        otherAudio.pause();
        otherAudio.currentTime = 0; // Reset the audio playback position
        setDropdownAudioStates((prevState) => ({
          ...prevState,
          [index]: { ...prevState[index], isPlaying: false },
        }));
      }
    });

    // Get the audio object for the current option or create a new one
    let currentAudio =
      dropdownAudioStates[optionIndex]?.audio || new Audio(url);

    if (dropdownAudioStates[optionIndex]?.isPlaying) {
      // Pause the current audio if it's already playing
      currentAudio.pause();
      setDropdownAudioStates((prevState) => ({
        ...prevState,
        [optionIndex]: { ...prevState[optionIndex], isPlaying: false },
      }));
    } else {
      // Play the current audio if it's not already playing
      currentAudio.play();
      setDropdownAudioStates((prevState) => ({
        ...prevState,
        [optionIndex]: {
          audio: currentAudio,
          isPlaying: true,
        },
      }));

      // Handle audio end event
      currentAudio.onended = () => {
        setDropdownAudioStates((prevState) => ({
          ...prevState,
          [optionIndex]: { ...prevState[optionIndex], isPlaying: false },
        }));
      };
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };
  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };
  const progressPercentage = (currentTime / duration) * 100;

  const handleLibrary = () => {
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
      setCurrentAudio(null);
      setPlayingVoiceId(null);
      setIsPlaying(false);
    }
    setLibraryModal(true);
  };

  const handleYourLibrarySong = () => {
    if (isPlaying) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPlaying(false);
    }
    setYourLibraryModal(true);
  };

  useEffect(() => {
    setNewYearSongsList(
      newYearSongs.filter((song) => {
        return song.category === category;
      })
    );
  }, [category]);

  const fetchAudioAsBlob = async (url) => {
    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`Failed to fetch audio file: ${response.statusText}`);
      }

      const audioBlob = await response.blob();

      return audioBlob;
    } catch (error) {
      console.error("Error fetching or converting audio file:", error);
    }
  };

  useEffect(() => {
    if (!libraryModal) {
      Object.keys(dropdownAudioStates)?.forEach((index) => {
        dropdownAudioStates[index].audio.pause();
        setDropdownAudioStates((prevState) => ({
          ...prevState,
          [index]: { ...prevState[index], isPlaying: false },
        }));
      });
    }
  }, [libraryModal]);
  const addSongToPlayer1 = (url, audioName, id) => {
    Object.keys(dropdownAudioStates)?.forEach((index) => {
      dropdownAudioStates[index].audio.pause();
      setDropdownAudioStates((prevState) => ({
        ...prevState,
        [index]: { ...prevState[index], isPlaying: false },
      }));
    });
    try {
      setLoading(true);
      fetchAudioAsBlob(url).then((blob) => {
        if (blob) {
          handleAudioChange(blob, audioName);
        }
        setLoading(false);
      });
      setLibraryModal(false);
      setIslibrarySong(false);
      showNotification("success", `${audioName} Song Selected successfully`);
    } catch {
      showNotification("error", "Failed to upload audio. Please try again.");

      setLibraryModal(false);
    }
  };

  if (loading) {
    return <PageLoader />;
  }

  const handlePlayPause1 = async (songId, songName) => {
    if (!songId) {
      if (currentAudio) {
        currentAudio.pause();
        currentAudio.currentTime = 0;
      }
      setCurrentAudio(null);
      setPlayingVoiceId(null);
      setIsPlaying(false);
      return;
    }
    if (playingVoiceId === songId.id) {
      // Pause and reset current audio if the same song is clicked
      if (currentAudio) {
        currentAudio.pause();
        currentAudio.currentTime = 0;
      }
      setCurrentAudio(null);
      setPlayingVoiceId(null);
      setIsPlaying(false);
      return;
    }

    // Stop and reset any currently playing audio
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
      setCurrentAudio(null);
      setPlayingVoiceId(null);
      setIsPlaying(false);
    }

    try {
      setLoading(true);

      const token = localStorage.getItem("token");
      const response = await fetch(`${apiUrl.getStreamBlob}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ id: songId.id }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json(); // Assuming the API returns JSON with an audio URL
      const audioUrl = data.audio_url; // Replace with the correct key in your response if different

      if (audioUrl) {
        const audio = new Audio(audioUrl);

        // Attach event listeners for audio
        audio.onloadedmetadata = () => {
          setDuration(audio.duration); // Set the total duration
        };
        audio.ontimeupdate = () => {
          setCurrentTime(audio.currentTime); // Update the current time
        };
        audio.onended = () => {
          setPlayingVoiceId(null); // Reset when playback ends
          setIsPlaying(false);
        };

        // Play the audio
        audio
          .play()
          .then(() => {
            setCurrentAudio(audio);
            setPlayingVoiceId(songId.id); // Correctly set the playing voice ID
            setIsPlaying(true);
          })
          .catch((error) => {
            console.error("Error playing audio:", error);
            setPlayingVoiceId(null); // Reset playing state on error
            setIsPlaying(false);
          });
      } else {
        throw new Error("No URL provided in the response.");
      }
    } catch (error) {
      console.error("Download or playback failed:", error);
      if (error.message.includes("HTTP error")) {
        showNotification(
          "error",
          `Failed to download. Server responded with: ${error.message}`
        );
      } else {
        showNotification(
          "error",
          "An unexpected error occurred during download or playback."
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const addSongToPlayer = (song) => {
    clearAudio();
    // Stop the currently playing audio
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
      setCurrentAudio(null);
      setPlayingVoiceId(null);
      setIsPlaying(false);
    }

    // Set the new song
    setSelectedSongFromLibrary(song);
    setYourLibraryModal(false);
    setSongFromLibrary(song.id);
    setLibraryModalPreview({ ...song });
    setAudioName(song.title);
    setIslibrarySong(true);
    setAudio(song.id);
    showNotification("success", `${song.title} Song Selected successfully`);
    // Handle song play/pause logic
    handlePlayPause1(song);
  };

  const clearAudio = () => {
    setAudio(null); // Clears the selected audio file
    setIsPlaying(false); // Resets the play/pause state
    if (audioRef.current) {
      audioRef.current.pause(); // Pauses the audio if it's playing
      audioRef.current.currentTime = 0; // Resets the audio playback to the beginning
    }
  };

  const handleYourLibraryModalClose = () => {
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
      setCurrentAudio(null);
      setPlayingVoiceId(null);
      setIsPlaying(false);
    }
    setYourLibraryModal(false); 
  }

  if(checkAudio === true){
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
      setCurrentAudio(null);
      setPlayingVoiceId(null);
      setIsPlaying(false);
    }
    setCheckAudio(false)
  }

  return (
    <div className="w-2/5 max-md:w-full upload-audio-container flex flex-col justify-center mt-8">
      <div className="flex flex-col justify-center items-center">
        <div className="w-full mb-4">
          <label className="block text-sm font-medium mb-2 text-white">
            Select Audio File (MP3 Only)
          </label>
          <div className="border border-dashed border-gray-600 rounded-lg p-4 flex flex-col items-center justify-center">
            <input
              type="file"
              accept="audio/mpeg"
              onChange={handleAudioChange}
              className="hidden"
              id="audioPicker"
            />
            <label
              htmlFor="audioPicker"
              className="cursor-pointer bg-gray-700 text-sm font-semibold text-gray-300 py-2 px-4 rounded-lg hover:bg-gray-600"
            >
              Upload Audio
            </label>
            <div className="text-center text-gray-400 text-sm mt-1 mb-1">
              OR
            </div>
            <div className="flex justify-center sm:justify-end">
              <div className="relative inline-block">
                <button
                  onClick={handleYourLibrarySong}
                  className="relative cursor-pointer bg-gray-700 text-sm font-semibold text-gray-300 py-2 px-4 rounded-lg hover:bg-gray-600 w-full sm:w-auto text-center"
                >
                  Your Library Songs
                </button>
              </div>
            </div>
            <div className="text-center text-gray-400 text-sm mt-1 mb-1">
              OR
            </div>
            <div className="flex justify-center sm:justify-end">
              <div className="relative inline-block">
                <button
                  onClick={handleLibrary}
                  className="relative cursor-pointer bg-gray-700 text-sm font-semibold text-gray-300 py-2 px-4 rounded-lg hover:bg-gray-600 w-full sm:w-auto text-center"
                >
                  Choose Friends Songs
                </button>
                <span
                  className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 text-lg"
                  role="img"
                  aria-label="sparkles"
                >
                  🎊
                </span>
              </div>
            </div>
          </div>
        </div>

        {audioName && (
          <p className="mt-2 text-white">
            <strong>{audioName}</strong>
          </p>
        )}

        {(preview || libraryModalPreview) && (
          <div className="w-full mb-4 flex items-center gap-4">
            <audio
              ref={audioRef}
              src={preview}
              className="hidden"
              onTimeUpdate={handleTimeUpdate}
              onLoadedMetadata={handleLoadedMetadata}
            ></audio>

            <div className="w-full audio-player bg-transparent p-4 flex items-center justify-between rounded-xl shadow-lg">
              <div className="text-white text-xs sm:text-sm md:text-base">
                <p className="text-gray-400">
                  {formatTime(currentTime)} / {formatTime(duration)}
                </p>
              </div>

              <div className="flex-grow mx-4 relative" onClick={handleSeek}>
                <div className="w-full h-1 bg-gray-600 rounded-full overflow-hidden cursor-pointer">
                  <div
                    className="h-full bg-gradient-to-r from-green-400 to-blue-500"
                    style={{
                      width: `${progressPercentage}%`,
                      transition: "width 0.5s ease-out",
                    }}
                  />
                </div>
              </div>

              <div className="flex items-center space-x-4">
                <button
                  onClick={() => {
                    stopCurrentAudio(); // Stop previous audio before handling play/pause
                    if (libraryModalPreview && isLibrarySong === true) {
                      handlePlayPause1(libraryModalPreview);
                    } else {
                      handlePlayPause();
                    }
                  }}
                  // className={`${
                  //   libraryModalPreview
                  //     ? "bg-gray-700 w-10 h-10 rounded-full flex items-center justify-center"
                  //     : "text-white transform hover:scale-110 transition-transform"
                  // }`}
                  className="text-white transform hover:scale-110 transition-transform"
                >
                  {isPlaying ? (
                    <AiFillPauseCircle
                      size={34}
                      // className={
                      //   libraryModalPreview ? "text-2xl text-teal-500" : ""
                      // }
                      className="text-2xl"
                    />
                  ) : (
                    <AiFillPlayCircle
                      size={34}
                      // className={
                      //   libraryModalPreview ? "text-2xl text-teal-500" : ""
                      // }
                      className="text-2xl"
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
        )}

        {/* {preview && (
          <div className="w-full mb-4 flex items-center gap-4">
            <audio
              ref={audioRef}
              src={preview}
              className="hidden"
              onTimeUpdate={handleTimeUpdate}
              onLoadedMetadata={handleLoadedMetadata}
            ></audio>

            <div className="w-full audio-player bg-transparent p-4 flex items-center justify-between rounded-xl shadow-lg">
              <div className="text-white text-xs sm:text-sm md:text-base">
                <p className="text-gray-400">
                  {formatTime(currentTime)} / {formatTime(duration)}
                </p>
              </div>

              <div className="flex-grow mx-4 relative" onClick={handleSeek}>
                <div className="w-full h-1 bg-gray-600 rounded-full overflow-hidden cursor-pointer">
                  <div
                    className="h-full bg-gradient-to-r from-green-400 to-blue-500"
                    style={{
                      width: `${progressPercentage}%`,
                      transition: "width 0.5s ease-out",
                    }}
                  />
                </div>
              </div>

              <div className="flex items-center space-x-4">
                <button
                  onClick={handlePlayPause}
                  className="text-white transform hover:scale-110 transition-transform"
                >
                  {isPlaying ? (
                    <AiFillPauseCircle size={34} />
                  ) : (
                    <AiFillPlayCircle size={34} />
                  )}
                </button>
              </div>
            </div>
          </div>
        )} */}
        {libraryModal && (
          <div className="fixed inset-0 flex justify-center items-center w-full h-full z-[1000] bg-black bg-opacity-90">
            {/* Modal Container */}
            <motion.div
              className="h-[400px] w-[60%] max-md:w-[90%] bg-black rounded-lg shadow-xl relative overflow-hidden song-model-border"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              {/* Modal Header */}
              <div className="flex justify-between items-center px-4 py-2 border-b-[0.2px] border-gray-400">
                <h2 className="text-xl font-semibold text-white">
                  Friends Songs
                </h2>
                {/* Close Icon */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-4 h-4 max-md:w-3 max-md:h-3 cursor-pointer text-white hover:text-red-600"
                  onClick={() => setLibraryModal(false)}
                  viewBox="0 0 320.591 320.591"
                >
                  <path
                    d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>

              {/* Modal Content */}
              <div className="flex flex-col justify-center items-center h-full p-4">
                <div className=" flex justify-center items-center gap-10 mb-4">
                  <button
                    className={`px-2 rounded-md py-0 ${
                      category == "male" ? "border-[0.5px] px-2 py-0" : ""
                    }`}
                    onClick={() => setCategory("male")}
                  >
                    Male
                  </button>
                  <button
                    className={`px-2 rounded-md py-0 ${
                      category == "female" ? "border-[0.5px] px-2 py-0" : ""
                    }`}
                    onClick={() => setCategory("female")}
                  >
                    Female
                  </button>
                </div>
                <div className="flex-grow overflow-auto w-full mb-10">
                  <div>
                    {newyearSongsList.map((song, index) => (
                      <div
                        key={song.id}
                        className="shadow-lg  sm:mt-4 p-2 song mb-2 sm:mb-6 relativ"
                      >
                        <div className="flex gap-4">
                          <img
                            src={MusicImage}
                            // alt={voice.title}
                            className="w-18 h-10 object-cover rounded-lg cursor-pointer"
                          />
                          <div className="flex flex-col flex-grow">
                            <h2 className="text-sm mt-2 font-semibold text-white heading-text-mobile">
                              {song.song_name === ""
                                ? "Song - Title"
                                : song.song_name}{" "}
                              <span className="ml-2 p-1 rounded-lg bg-black text-xs	text-gray-400">
                                {song.language}
                              </span>
                            </h2>
                          </div>
                          <div className="flex items-center justify-between gap-3 relative">
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDropdownAudioPlayPause(
                                  song.id,
                                  song.audio_url
                                );
                              }}
                              className="text-blue-500 mx-2"
                            >
                              {dropdownAudioStates[song.id]?.isPlaying ? (
                                <AiFillPauseCircle className="text-3xl fill-white" />
                              ) : (
                                <AiFillPlayCircle className="text-3xl fill-white" />
                              )}
                            </button>
                            <button
                              onClick={() =>
                                addSongToPlayer1(
                                  song.audio_url,
                                  song.song_name,
                                  song.id
                                )
                              }
                              className="text-green-500 mx-2"
                            >
                              <FaPlusCircle className="text-3xl fill-white" />
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        )}
        {yourLibraryModal && (
          <div className="fixed inset-0 flex justify-center items-center w-full h-full z-[1000] bg-black bg-opacity-90">
            {/* Modal Container */}
            <motion.div
              className="h-[400px] w-[60%] max-md:w-[90%] bg-black rounded-lg shadow-xl relative overflow-hidden song-model-border"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              {/* Modal Header */}
              <div className="flex justify-between items-center px-4 py-2 border-b-[0.2px] border-gray-400">
                <h2 className="text-xl font-semibold text-white">
                  Your Library
                </h2>
                {/* Close Icon */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-4 h-4 max-md:w-3 max-md:h-3 cursor-pointer text-white hover:text-red-600"
                  // onClick={() => setYourLibraryModal(false)}
                  onClick={handleYourLibraryModalClose}

                  viewBox="0 0 320.591 320.591"
                >
                  <path
                    d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>

              {/* Modal Content */}
              <div className="flex flex-col justify-center items-center h-full p-4">
                <div className="flex-grow overflow-auto w-full mb-10">
                  <LibraryCollage
                    handlePlayPause1={handlePlayPause1}
                    playingVoiceId={playingVoiceId}
                    addSongToPlayer={addSongToPlayer}
                  />
                </div>
              </div>
            </motion.div>
          </div>
        )}

        {/* {libraryModalPreview && (
          <div className="w-full mb-4 flex items-center gap-4">
            <div className="w-full audio-player bg-transparent p-4 flex items-center justify-between rounded-xl shadow-lg">
              <div className="text-white text-xs sm:text-sm md:text-base">
                <p className="text-gray-400">
                  {formatTime(currentTime)} / {formatTime(duration)}
                </p>
              </div>

              <div className="flex-grow mx-4 relative" onClick={handleSeek}>
                <div className="w-full h-1 bg-gray-600 rounded-full overflow-hidden cursor-pointer">
                  <div
                    className="h-full bg-gradient-to-r from-green-400 to-blue-500"
                    style={{
                      width: `${progressPercentage}%`,
                      transition: "width 0.5s ease-out",
                    }}
                  />
                </div>
              </div>

              <div className="flex items-center space-x-4">
                <button
                  onClick={() => {
                    if (libraryModalPreview?.isLibrarySong) {
                      handlePlayPause1(libraryModalPreview);
                    }
                  }}
                  className="bg-gray-700 w-10 h-10 rounded-full flex items-center justify-center"
                >
                  {isPlaying ? (
                    <AiFillPauseCircle className="text-2xl text-teal-500" />
                  ) : (
                    <AiFillPlayCircle className="text-2xl text-teal-500" />
                  )}
                </button>
              </div>
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
}
