import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FailureImage from '../../src/assets/gif/imas.gif';


function NotFoundPage() {
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate('/');
        }, 3500);
    }, [navigate]);

    return (
        <div className="flex flex-col items-center justify-center min-h-screen text-center p-4">
            <img src={FailureImage} alt="404" className="w-32 mb-6 animate-bounce" />
            <h1 className="text-4xl font-bold text-white mb-4">Page Not Found</h1>
            <p className="text-xl text-white mb-6">Oops! The page you're looking for doesn't exist.</p>
            <a href="/" className="text-lg text-cyan-200 hover:text-teal-400 transition-colors duration-300">
                Go back to Home
            </a>
        </div>
    );
}

export default NotFoundPage;
