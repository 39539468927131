import React from "react";
import NseImage from '../assets/dashboard/nse.png'
import logo from "../assets/dashboard/brand_img.png"


function Footer() {
  return (
    <footer className="flex flex-col justify-center items-center py-8 sm:mt-0 w-full max-w-full max-md:mt-0 bg-black footer-bottom-margin">
      <div className="flex flex-col justify-center p-6 max-md:p-2 w-full max-w-screen-xl max-md:px-5">
        <div className="flex flex-wrap gap-6  justify-between items-end w-full">
          <div className="flex max-md:flex-col">
            <div className="flex flex-col flex-1 shrink text-white basis-0 min-w-[240px] max-md:max-w-full">
              <div className="flex flex-col w-full">
                <h3 className="text-2xl font-medium tracking-tight">
                  Contact
                </h3>
                <div className="flex flex-col mt-2 w-full text-lg font-light tracking-normal text-teal-400">
                  <p>
                    <a href="mailto:support@imadeasong.com">
                      support@imadeasong.com
                    </a>
                  </p>
                </div>
              </div>

            </div>
            <div className="flex flex-col flex-1 shrink text-white basis-0 min-w-[240px] max-md:max-w-full max-md:mt-8">
              <div className="flex flex-col w-full">
                <h3 className="text-2xl font-medium tracking-tight">
                  Address
                </h3>
                <address className="mt-2 text-gray-500 text-start sm:text-left not-italic leading-tight hidden sm:block md:block">
                  Digikore Studios Limited,<br />  (formerly Digikore Studios Private Limited)
                  Lalwani Triumph,
                  Viman Nagar,
                  Pune, MH 411014
                </address>
                <address className="mt-2 text-gray-500 text-start sm:text-left not-italic leading-tight block sm:hidden">
                  Digikore Studios Limited, <br />(formerly Digikore Studios Private Limited)
                  <br /> Lalwani Triumph,
                  Viman Nagar,
                  <br /> Pune, MH 411014
                </address>
              </div>
            </div>
          </div>

          <div className="flex max-md:space-x-10 space-x-10">
            {/* NSE Section */}
            <div className="flex flex-col items-center mt-2">
              <p className="text-sm max-md:text-xs text-teal-400 font-light">Listed on NSE</p>
              <img src={NseImage} alt="image" className="w-[120px] max-md:w-[90px]" />
            </div>

            {/* Company Logo */}
            <img
              loading="lazy"
              src={logo}
              alt="Company logo"
              className="w-[120px] h-18  max-md:w-[100px]"
            />
          </div>

        </div>
      </div>
      <div className="w-full max-w-screen-xl mx-auto flex flex-col sm:flex-row justify-between items-center border-t border-gray-700 pt-3">
        <p className="text-gray-500 text-center sm:text-left">
          Copyright © 2025 Digikore Studios Limited
        </p>
        <div className="flex flex-wrap gap-4 justify-center sm:justify-end mt-3 sm:mt-0">
          <a href="/terms-of-service" className="text-gray-200 hover:underline">
            Terms of Services
          </a>
          <a href="/privacy-policy" className="text-gray-200 hover:underline">
            Privacy Policy
          </a>
          <a href="/cancellation-policy" className="text-gray-200 hover:underline">
            Cancellation and Refund Policy
          </a>
          <a href="/about-us" className="text-gray-200 hover:underline">
            About Us
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
