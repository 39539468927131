import React, { useState } from 'react'
import { FaChevronDown } from 'react-icons/fa6';

function ViralFAQ() {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAnswer = (index) => {
      setActiveIndex(activeIndex === index ? null : index);
    };

    const faqData = [
      {
        question: " How do I pay for the song?",
        answer: "  Once you've filled out the form and made your selections, you’ll be prompted to click on ‘Create my song’ and complete the payment through a secure checkout process.",
      },
      {
        question: "How long will it take to receive my custom song?",
        answer: "Once payment is complete, our team will process your order, and you’ll receive your personalized song via email. The turnaround time is usually 24 to 48 hours.",
      },
      {
        question: "Can I create a song for any occasion?",
        answer: " Yes! Whether it’s for a birthday, anniversary, wedding, special event, or just to say ‘I love you,’ our service is perfect for any occasion.",
      },
      {
        question: "Is there a limit to the number of photos I can upload for a slideshow?",
        answer:   "We recommend uploading between 3 to 5 photos for an ideal slideshow experience.",
      },
      {
        question: " What formats will I receive my personalized song in?",
        answer: " You will receive your personalized song in either a high-quality MP3 format (for audio-only songs) or a video format (for selfie videos and slideshows).",
      },
      {
        question: " Is the song really personalized?",
        answer: " Yes, every song is 100% personalized based on your input. We don’t use any generic templates—your song is made specifically for you and the recipient based on the details you provide.",
      },
      {
        question: " Can I share my personalized song with others?",
        answer: " Absolutely! Once you receive your song via email, you can share it with anyone you like through any medium, including social media, email, etc.",
      },
      {
        question: "Can I get a refund if I am not satisfied with the song?",
        answer: " Due to the personalized nature of the product, we are unable to offer refunds once the song is created. However, if there are any issues with your order, please reach out to us, and we’ll work with you to resolve them.",
      },
      {
        question: " How do I contact you if I need assistance?",
        answer: " For any questions or issues, simply start a chat with our WhatsApp bot, and our support team will assist you promptly.",
      },

    ];
  return (
    <div className="flex flex-col items-center w-full">
    {faqData.map((item, index) => (
      <div
        key={index}
        className="border border-gray-300 rounded-md mb-4 dark:bg-gray-800 dark:border-gray-600 w-full max-w-4xl"
      >
        {/* Question Header */}
        <div
          className="flex justify-between items-center p-4 cursor-pointer bg-[#08273B] dark:hover:bg-gray-700"
          onClick={() => toggleAnswer(index)}
          role="button"
          aria-expanded={activeIndex === index}
          aria-controls={`answer-${index}`}
        >
          <h3 className="font-medium text-lg text-white dark:text-white flex-1">
            {item.question}
          </h3>

          {/* Fixed Icon Size */}
          <FaChevronDown
            className={`transition-transform duration-300 text-white ${
              activeIndex === index ? "rotate-180" : ""
            }`}
            style={{ minWidth: "24px", minHeight: "24px", width: "24px", height: "24px" }}
          />
        </div>
        {/* Answer Section */}
        {activeIndex === index && (
          <div
            id={`answer-${index}`}
            className="p-4 text-white bg-[linear-gradient(90deg,_rgba(15,55,85,1)_0%,_rgba(15,55,85,1)_13%,_rgba(59,120,101,1)_72%)] dark:text-gray-300 text-base transition-all duration-500"
          >
            {item.answer.split("\n").map((line, i) => (
              <p key={i}>{line}</p>
            ))}
          </div>
        )}
      </div>
    ))}
  </div>

  )
}

export default ViralFAQ
