import React, { useEffect, useRef, useState } from 'react';
import ModijiImage from '../../assets/viral_images/MAIN BANNER.png';
import ModijiBg from '../../assets/viral_images/MAIN BANNER BG.png'
import FamilyImage from '../../assets/viral_images/PARENTS BG.png';
import FamilyImageNOBLUR from '../../assets/viral_images/PARENTS_BG.png';
import HouseImage from '../../assets/viral_images/SECTION 2 BG.png';
import '../../styles/viralPages.css';
import { useNavigate } from 'react-router-dom';
import { FaPlay, FaPauseCircle } from "react-icons/fa";

const ModijiPage = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const audioRef = useRef(null);
  const navigate = useNavigate();

  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };
  const handleTimeUpdate = () => {
    const currentTime = audioRef.current.currentTime;
    const duration = audioRef.current.duration;
    setProgress((currentTime / duration) * 100);
  };

  const handleRangeChange = (e) => {
    const newProgress = e.target.value;
    setProgress(newProgress);
    const duration = audioRef.current.duration;
    audioRef.current.currentTime = (newProgress / 100) * duration;
  };

  useEffect(() => {
    const audio = audioRef.current;
    audio.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      audio.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, []);
  return (
    <div className="">
      <div className="mx-auto bg-white ">
        {/* Header Section */}
        <div>
          <div className="mobile-view">
            <img src={ModijiImage} alt="image" className='w-full h-80 max-md:h-80' />
            <div className="text-center py-2 m-bg-color">
              <h1
                className="max-md:text-xl text-6xl font-bold text-white"
                style={{
                  fontSize: '35px',
                  letterSpacing: '0.07em',
                  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)',
                  WebkitTextStroke: '2px rgba(0, 0, 0, 0.2)',
                  color: '#FFFFFF',
                  lineHeight: '35px',
                  fontWeight: '900',
                }}
              >
                INDIA’S FIRST-EVER
                <span className="block">AI-POWERED SONG PLATFORM!</span>
              </h1>

              <div className='text-black max-md:mt-4 mt-8 font-size-12' style={{ fontSize: '14px' }}>Inspired by Modi Ji's words,</div>
              <div className='text-black max-md:mt-2 mt-6 font-size-12 font-bold leading-tight italic' style={{ fontSize: '12px' }}>"India Has Double Al Power... Aspirational India + Artificial Intelligence,"</div>
              <div className='text-black max-md:mt-2 mt-8 font-size-10' style={{ fontSize: '13px' }}>this anthem celebrates our nation's journey towards a brighter future.</div>

              <div className="flex items-center justify-center max-md:mt-4 py-0 px-6 rounded-lg">
                <div className="flex items-center w-full max-w-2xl px-4 max-md:px-8">
                  <span className="flex-grow h-[1px] bg-[#008080]"></span>
                  <button className="px-8 py-2 text-black font-bold text-base md:text-lg rounded-full">
                    LISTEN NOW
                  </button>
                  <span className="flex-grow h-[1px] bg-[#008080]"></span>
                </div>
              </div>

              <div className="flex items-center justify-center mt-20 max-md:mt-4 m-bg-color">
                <div className="m-videoplayer-bg w-[75%] sm:w-[55%] px-6 py-6 max-md:py-2 rounded-lg shadow-md flex items-center">
                  <div className="flex items-center justify-center w-40 h-40 bg-gradient-to-r from-[#FF9933] via-[#FFFFFF] to-[#138808] p-8 max-md:w-10 max-md:h-10 bg-white rounded-full mr-4">
                    <button className="flex justify-center" onClick={handlePlayPause}>
                      {!isPlaying ? (
                        <FaPlay color="#96471b" size={20} />
                      ) : (
                        <FaPauseCircle color="#96471b" size={28} />
                      )}
                    </button>
                  </div>
                  <div className="flex-1">
                    <h2 className="text-white text-start font-size-14 line-height-16 font-semibold" style={{ lineHeight: "1.5" }}>
                      Modi Ji’s Personalized <br /> AI Anthem!
                    </h2>
                    <div className="relative mt-0">
                      <input
                        type="range"
                        className="w-full h-1 bg-white rounded-lg  appearance-none cursor-pointer accent-orange-400"
                        defaultValue={50}
                        value={progress}
                        onChange={handleRangeChange}
                      />
                    </div>
                  </div>
                  <audio ref={audioRef} src="https://imas-ai-home.s3.eu-central-003.backblazeb2.com/ourModiji.mp3" />
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="desktop-view">
          <div className="relative">
            {/* Image Tag as Background */}
            <img
              src={ModijiBg}
              alt="Modiji Background"
              className="w-full h-[34rem] object-cover"
            />
            <div className="absolute inset-0 flex justify-end pr-8 pt-16">
              <div className="w-[60%] text-white  text-center">
                <h1
                  className="text-6xl font-bold text-white"
                  style={{
                    fontSize: window.innerWidth >= 1024 ? '50px' : '25px',
                    letterSpacing: '0.07em',
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)',
                    WebkitTextStroke: '2px rgba(0, 0, 0, 0.2)',
                    color: '#FFFFFF',
                    fontWeight: '900',
                  }}
                >
                  INDIA’S FIRST-EVER
                  <span className="block">AI-POWERED SONG PLATFORM!</span>
                </h1>

                <div className="text-black mt-8 font-size-10" style={{
                  fontSize: window.innerWidth >= 1024 ? '28px' : '10px',
                }}>
                  Inspired by Modi Ji's words,
                </div>
                <div className="text-black mt-2 font-size-12 font-bold leading-tight italic" style={{ fontSize: window.innerWidth >= 1024 ? '24px' : '12px', }}>
                  "India Has Double Al Power... Aspirational India + Artificial Intelligence,"
                </div>
                <div className="text-black mt-2 font-size-10" style={{ fontSize: window.innerWidth >= 1024 ? '24px' : '11px', }}>
                  this anthem celebrates our nation's journey towards a brighter future.
                </div>

                <div className="flex items-center justify-center mt-8 py-4 px-6 rounded-lg">
                  <div className="flex items-center w-full max-w-2xl px-4">
                    <span className="flex-grow h-[1px] bg-[#008080]"></span>
                    <button className="px-2 py-0.5 text-black font-bold text-xs md:text-sm rounded-full">
                      LISTEN NOW
                    </button>
                    <span className="flex-grow h-[1px] bg-[#008080]"></span>
                  </div>
                </div>

                <div className="flex items-center justify-center mt-2 ">
                  <div className="m-videoplayer-bg w-full px-3 py-3 rounded-lg shadow-sm flex items-center" style={{ width: '47%' }}>
                    <div className="flex items-center justify-center w-12 h-12 bg-gradient-to-r from-[#FF9933] via-[#FFFFFF] to-[#138808] rounded-full mr-2">
                      <button onClick={handlePlayPause}>
                        {!isPlaying ? (
                          <FaPlay color="#96471b" size={20} />
                        ) : (
                          <FaPauseCircle color="#96471b" size={24} />
                        )}
                      </button>
                    </div>
                    <div className="flex-1">
                      <h2 className="text-white text-start max-md:text-xs text-xl font-semibold ml-10 max-md:ml-4" style={{ lineHeight: "1.2" }}>
                        Modi Ji’s Personalized <br /> AI Anthem!
                      </h2>
                      <div className="relative mt-0">
                        <input
                          type="range"
                          className="w-full h-0.5 bg-white rounded-lg appearance-none cursor-pointer accent-orange-400"
                          defaultValue={50}
                          value={progress}
                          onChange={handleRangeChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className='mobile-view'><img src={HouseImage} alt="house image" className='w-full' /></div>


        {/* Main content */}
        <div className="relative flex justify-center items-center">
          <img
            src={FamilyImageNOBLUR}
            alt="Couple Smiling with Tablet"
            className="shadow-lg w-full max-md:h-60 h-80 md:hidden"
          />
        </div>


        {/* Highlight Section */}
        <div className='mobile-view'>
          <div className="m-videoplayer-bg text-white text-center max-md:py-2 py-20 px-2">
            <h2 className="max-md:text-2xl text-6xl font-bold">
              BECAUSE SOME EMOTIONS <span className="block">DESERVE A SPECIAL SONG!</span>
            </h2>
            <p className="max-md:mt-2 text-black mt-10 max-md:text-sm text-2xl leading-tight">
              Whether it's the pride of being Indian or the love for our parents, some emotions are best
              expressed through a song.
            </p>
            <button
              className="creat-song-text bg-white text-black py-2 mt-6 rounded-full border-2"
              style={{ borderColor: '#21b6c7', borderColor: 'rgb(33, 182, 199)' }}
              onClick={() => navigate("/custom_song_for_parents")}

            >
              CREATE A SONG FOR FREE
            </button>
          </div>
        </div>

        <div className="desktop-view ">
          <div className="m-videoplayer-bg text-white text-center py-10 px-8">
            <h2 className="text-4xl font-bold max-lg:text-2xl	max-md:text-2xl">
              BECAUSE SOME EMOTIONS DESERVE A SPECIAL SONG!
            </h2>
            <p className="mt-2 text-2xl leading-tight max-lg:text-base max-md:text-base text-black">
              Whether it's the pride of being Indian or the love for our parents, some emotions are best
              expressed through a song.
            </p>
          </div>
        </div>


        {/* Call to Action Section */}
        <div className="mobile-view">
          <div className="text-center rounded-lg py-6 px-6 relative  md:flex md:items-center md:justify-between md:text-left ">
            {/* Box with text */}
            <div className="md:w-1/3 bg-white mx-auto">
              <div className="border-radius-container relative pb-10">
                <p className="max-md:text-base text-xl text-black font-semibold leading-tight">
                  Your parents have always been there for you,
                  <p className='font-normal'>now it’s your turn to show them</p>
                  <p>how much they mean to you!</p>
                </p>
                <p className="max-md:mt-2 mt-8 max-md:text-base text-xl font-bold bg-green-text line-height-22 max-md:mb-4 mb-12 leading-tight">
                  Make a personalized song to thank them for everything they have done for you!
                </p>
              </div>
              {/* Button: Positioning adjusted */}
              <button
                className="creat-song-text bg-green text-white py-4 px-8 mt-4 rounded-full border-2 absolute bottom-0 left-1/2 transform -translate-x-1/2 md:relative md:transform-none"
                style={{ borderColor: '#067349', fontSize: '10px' }}
                onClick={() => navigate("/custom_song_for_parents")}
              >
                CREATE A SONG FOR FREE
              </button>

            </div>

            {/* Image Section */}
            <div className="relative w-full md:w-1/3 mb-10 md:block hidden">
              {/* Blurred background image */}
              <div
                className="absolute top-0 left-0 w-full h-64 bg-cover bg-center"
                style={{
                  backgroundImage: `url(${FamilyImage})`,
                  filter: "blur(8px)",
                  zIndex: -1,
                }}
              ></div>

              {/* Main content */}
              <div className="relative flex justify-center items-center mt-2">
                <img
                  src={FamilyImage}
                  alt="Couple Smiling with Tablet"
                  className="rounded-lg shadow-lg w-full max-md:h-60 h-80 mb-4"
                />
              </div>
            </div>
          </div>
        </div>

        <div className='desktop-view'>
          <div className="bg-[#dbfdfd] flex justify-between items-center">
            {/* Content Box on the Left */}
            <div className="text-center rounded-lg py-6 px-6 relative w-[40%]" style={{ marginLeft: '8%' }}>
              <div className="border-radius-container relative">
                <p className="max-md:text-sm text-2xl text-black font-semibold leading-tight">
                  Your parents have always been there for you,
                  <p className='font-normal'>now it’s your turn to show them</p>
                  <p>how much they mean to you!</p>
                </p>
                <p className="max-md:text-sm text-xl font-bold bg-green-text line-height-22 max-md:mb-4 mb-4 leading-tight">
                  Make a personalized song to thank them for everything they have done for you!
                </p>
              </div>
              <button
                className="bg-green text-white font-bold py-2 px-2 mt-4 rounded-full border-2 absolute bottom-[2px] left-1/2 transform -translate-x-1/2"
                style={{ borderColor: '#067349', fontSize: '12px' }}
                onClick={() => navigate("/custom_song_for_parents")}
              >
                CREATE A SONG FOR FREE
              </button>
            </div>

            {/* Image Section on the Right */}
            <div className="relative w-[35%]">
              {/* Blurred background image */}
              <div
                className="absolute top-0 left-0 w-full h-64 bg-cover bg-center"
                style={{
                  backgroundImage: `url(${FamilyImage})`,
                  filter: "blur(8px)",
                  zIndex: -1,
                }}
              ></div>

              {/* Main content */}
              <div className="relative flex justify-center items-center mt-2">
                <img
                  src={FamilyImage}
                  alt="Couple Smiling with Tablet"
                  className="rounded-lg shadow-lg w-full  mb-4"
                />
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  );
};

export default ModijiPage;
