import { motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import GetAllVoice from "../VoiceCreation/GetAllVoice.jsx";
import VideoCollection from "../videoCollection/VideoCollection.jsx";

const SongLibraryTabs = ({ songs }) => {
    const [activeTab, setActiveTab] = useState("song");

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    return (
        <div className="min-h-screen">
            {/* Tab Headers */}
            <div className="w-full flex justify-center max-md:justify-center items-center mt-4">
                <button
                    className={`tab-button px-6 py-2 text-lg font-medium rounded-t-lg transition-all duration-300 ${activeTab === "song"
                        ? "text-teal-300 border-b-4 border-teal-500"
                        : "text-gray-400 hover:text-teal-300 "
                        }`}
                    onClick={() => setActiveTab("song")}
                >
                    Audio Song
                </button>
                <button
                    className={`tab-button px-6 py-2 ml-6 text-lg font-medium rounded-t-lg transition-all duration-300 ${activeTab === "videoSong"
                        ? "text-teal-300 border-b-4 border-teal-500"
                        : "text-gray-400 hover:text-teal-300"
                        }`}
                    onClick={() => setActiveTab("videoSong")}
                >
                    Video Song
                </button>
            </div>

            {/* Tab Content */}
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="tab-content  rounded-lg"
            >
                {activeTab === "song" ? (
                    <GetAllVoice songs={songs} />
                ) : (
                    <VideoCollection />
                )}
            </motion.div>
        </div>
    );
};

export default SongLibraryTabs;
