import React, { useEffect, useRef, useState } from "react";
import { AiFillPauseCircle, AiFillPlayCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import songImage from "../../assets/dashboard/music.webp";
import PageLoader from "../../components/PageLoader.jsx";

function SongList({ songs, setPlayingVoiceId, handlePlayPause, isPlaying, setIsPlaying, setSelectedLyrics, setIsVisibleLyrics }) {
  const [currentAudio, setCurrentAudio] = useState(null);
  // const [isPlaying, setIsPlaying] = useState(false);
  const [activeSongId, setActiveSongId] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const { collectionData } = useSelector((state) => state.uploadAudio);
  const containerRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        if (currentAudio) {
          currentAudio.pause();
          setIsPlaying(false);
          setActiveSongId(null);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [currentAudio]);

  useEffect(() => {
    const stopAudio = () => {
      if (currentAudio) {
        currentAudio.pause();
      }
    };

    window.onbeforeunload = stopAudio;

    return () => {
      stopAudio();
      window.onbeforeunload = null;
    };
  }, [currentAudio]);

  const handleNewSongPlay = (song) => {
    setActiveSongId(song.id)
    handlePlayPause(song)
  }
  return (
    <>
      {collectionData.length === 0 ? (
        <div></div>
      ) : (
        <div
          className="flex-row gap-8 generated-card flex justify-center"
          ref={containerRef}
        >
          {songs?.map((song) => (
            <div
              key={song.id}
              className="flex generate-song-bg flex-row w-40 mt-4 gap-16 p-2 mx-2 rounded-xl  hover:shadow-xl transition-all duration-300 sm:w-56 md:w-72 lg:w-80"
              onClick={() => {
                setSelectedLyrics({
                  title: song.title,
                  lyric: song.lyric,
                });
                setIsVisibleLyrics(true)
              }}
            >
              <div className="flex w-16 justify-center h-20 items-center">
                <img
                  loading="lazy"
                  src={songImage}
                  alt={song.title}
                  className="object-contain rounded-full shadow-sm aspect-square w-32 max-md:w-32 generate-song-height"
                />
              </div>
              <div className="display-flex-row">
                <div className="">
                  <h2 className="text-sm sm:text-xl font-medium text-white text-center cursor-pointer">
                    {song.title ? song.title : "Title"}
                  </h2>
                </div>
                <div className="ml-6 mt-2" ref={containerRef}>
                  {activeSongId === song.id ? (
                    isPlaying === song.id ? (
                      <button
                        // onClick={() => handlePlay(song.id)}
                        onClick={() => handleNewSongPlay(song)}
                        className="playButton"
                      >
                        <AiFillPauseCircle size="40px" color="#FFF" />
                      </button>
                    ) : (
                      <button
                        // onClick={() => handlePlay(song.id)}
                        onClick={() => handleNewSongPlay(song)}
                        className="playButton"
                      >
                        <AiFillPlayCircle size="40px" color="#FFF" />
                      </button>
                    )
                  ) : (
                    <button
                      // onClick={() => handlePlay(song.id)}
                      onClick={() => handleNewSongPlay(song)}
                      className="playButton"
                    >
                      <AiFillPlayCircle size="40px" color="#FFF" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
          {showLoading && <PageLoader />}
        </div>
      )}
    </>
  );
}

export default SongList;
