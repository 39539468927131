import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import successImage from '../../src/assets/image_icons/success.webp';
// import NotFoundPage from '../../src/reusable/404_page.jsx'

function AlertSuccessPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location || {};
    const { status, message, transactionIds } = state || {};

    // if (!state || !state.amount) {
    //     console.warn("State is undefined or missing 'amount'.");
    //     return <NotFoundPage />;
    // }

    const price = state.amount;
    const currency = state.currency;
    const transactionId = state.order_id;
    const itemIds = ['item1', 'item2'];
    const itemCategory = 'Subscription';
    const numberItems = 1;
    const userEmail = state.email;
    const userPhoneNumber = state.contact;

    const priceInRupees = price / 100;

    return (
        <main className="flex justify-center items-center min-h-screen bg-black">
            <Helmet>
                {/* Facebook Pixel */}
                <script>
                    {`
                    !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window,document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '925192532364818'); 
                    fbq('track', 'PageView');
                    fbq('track', 'Purchase', {value: ${priceInRupees}, currency: '${currency}'});
                    `}
                </script>
                <noscript>{`<img height="1" width="1" src="https://www.facebook.com/tr?id=925192532364818&ev=PageView&noscript=1" />`}</noscript>

                {/* Snap Pixel */}
                <script type="text/javascript">
                    {`
                    (function (e, t, n) {
                        if (e.snaptr) return;
                        var a = (e.snaptr = function () {
                            a.handleRequest ? a.handleRequest.apply(a, arguments) : a.queue.push(arguments);
                        });
                        a.queue = [];
                        var s = "script";
                        var r = t.createElement(s);
                        r.async = !0;
                        r.src = n;
                        var u = t.getElementsByTagName(s)[0];
                        u.parentNode.insertBefore(r, u);
                    })(window, document, "https://sc-static.net/scevent.min.js");

                    snaptr("init", "8dea839f-006f-4f5a-a0ad-280f7a2360f3", {
                        user_email: '${userEmail}',
                    });

                    snaptr("track", "PAGE_VIEW");
                    snaptr('track', 'PURCHASE', {
                        'price': ${priceInRupees},
                        'currency': '${currency}',
                        'transaction_id': '${transactionId}',
                        'item_ids': ${JSON.stringify(itemIds)},
                        'item_category': '${itemCategory}',
                        'number_items': ${numberItems},
                        'user_email': '${userEmail}',
                        'user_phone_number': '${userPhoneNumber}'
                    });
                    `}
                </script>

                {/* GB: Purchase Event Snippet */}
                <script type="text/javascript">
                    {`
                        gtag('event', 'conversion', {
                        'send_to': 'AW-16753165277/7EFOCN6Tl_MZEN2HxLQ-',
                        'value': 1.0,
                        'currency': 'INR',
                        'transaction_id': ''
                        });
                    `}
                </script>
                <script type="text/javascript">
                    {`
                        window.goaffpro_order = {
                            number: "${transactionId}",
                            total: ${priceInRupees},
                            currency : '${currency}',
                            forceSDK:true,
                            status:"approved",
                            customer: {
                                email:'${userEmail}',
                                phone:'${userPhoneNumber}', 
                            },
                        };
                        if (typeof window.goaffproTrackConversion !== "undefined") {
                        window.goaffproTrackConversion(window.goaffpro_order);
                        }
                    `}
                </script>
            </Helmet>

            <section data-layername="alert01" className="flex pr-2.5 max-w-[600px] small-model-margin">
                <div className="flex flex-col grow shrink-0 justify-center items-center px-10 py-9 bg-gray-100 rounded-xl shadow-lg border border-solid border-gray-200 w-fit max-md:px-5 max-md:max-w-full max-md:m-6">
                    <div className="flex flex-col items-center gap-4 max-md:max-w-full">
                        <img
                            loading="lazy"
                            src={successImage}
                            alt="Success Icon"
                            className="object-contain w-28 max-md:w-20 mt-4 aspect-square animate-bounce"
                        />
                        <div className="flex flex-col items-center">
                            <h2 className="text-3xl text-center font-bold text-gray-800">
                                Payment Successful!
                            </h2>
                            <p className="mt-2 text-lg max-md:text-base text-gray-600 text-center">
                                You have activated your subscription.
                            </p>
                            <p className="mt-2 text-center text-xl max-md:text-lg font-bold text-teal-600">
                                Enjoy creating beautiful melodies!
                            </p>
                        </div>
                        <button
                            className="mt-6 px-6 py-2 custom-button text-white text-lg font-semibold rounded-lg shadow-md hover:bg-teal-600 transition-all duration-300"
                            onClick={() => navigate('/dashboard')}
                        >
                            Go to Dashboard
                        </button>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default AlertSuccessPage;




// import React, { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import successImage from '../../src/assets/image_icons/success.webp';

// function AlertSuccessPage() {
//     const navigate = useNavigate();

//     useEffect(() => {
//         const redirected = sessionStorage.getItem("alertSuccessRedirected");

//         if (!redirected) {
//             navigate('/404');
//         } else {
//             sessionStorage.removeItem("alertSuccessRedirected");
//         }
//     }, [navigate]);

//     return (
//         <main className="flex justify-center items-center min-h-screen bg-black">
//             <section data-layername="alert01" className="flex pr-2.5 max-w-[600px] small-model-margin">
//                 <div className="flex flex-col grow shrink-0 justify-center items-center px-10 py-9 bg-gray-100 rounded-xl shadow-lg border border-solid border-gray-200 w-fit max-md:px-5 max-md:max-w-full max-md:m-6">
//                     <div className="flex flex-col items-center gap-4 max-md:max-w-full">
//                         <img
//                             loading="lazy"
//                             src={successImage}
//                             alt="Success Icon"
//                             className="object-contain w-28 max-md:w-20 mt-4 aspect-square animate-bounce"
//                         />
//                         <div className="flex flex-col items-center">
//                             <h2 className="text-3xl font-bold text-gray-800">
//                                 Payment Successful!
//                             </h2>
//                             <p className="mt-2 text-lg max-md:text-base text-gray-600 text-center">
//                                 You have activated your subscription.
//                             </p>
//                             <p className="mt-2 text-xl max-md:text-lg font-bold text-teal-600">
//                                 Enjoy creating beautiful melodies!
//                             </p>
//                         </div>
//                         <button
//                             className="mt-6 px-6 py-2 bg-teal-500 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-teal-600 transition-all duration-300"
//                             onClick={() => window.location.href = '/dashboard'}
//                         >
//                             Go to Dashboard
//                         </button>
//                     </div>
//                 </div>
//             </section>
//         </main>
//     );
// }

// export default AlertSuccessPage;
