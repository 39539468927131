import React, { useEffect, useState } from "react";
import { FaCirclePause, FaCirclePlay, FaDownload } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import showNotification from "../../../src/hooks/notificationHook.js";
import { valentineData, valentineUser } from "../../utils/valentine";
// import loader from "./assets/dashboard/loader.gif";
import loader from "../../assets/dashboard/valentineLoader.gif";
import { IoArrowBackCircleOutline } from "react-icons/io5";


function SpotifyPlaylist() {
    const { id } = useParams();
    const [valentine, setValentine] = useState("");
    const [currentAudio, setCurrentAudio] = useState(null);
    const [playingSongId, setPlayingSongId] = useState(null);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const v = valentineUser.filter((n) => n.id == id);
        setValentine(v[0].name);
    }, [id]);

    // const downloadVideo = (videoUrl, videoTitle) => {
    //     // Fetch the video file from the URL
    //     setIsLoading(true);
    //     fetch(videoUrl)
    //         .then((response) => response.blob())
    //         .then((blob) => {
    //             // Create a download link
    //             const a = document.createElement("a");
    //             a.href = URL.createObjectURL(blob); // Create a URL for the blob
    //             a.download = `${videoTitle}.mp4`; // Name the video file based on its title
    //             a.click(); // Trigger the download
    //             setIsLoading(false);
    //         })
    //         .catch((error) => {
    //             console.error("Error downloading video:", error);
    //         });
    // };

    const downloadVideo = (videoUrl, videoTitle) => {

        showNotification("success", "This is sample song and cannot be downloaded.");
        // setIsLoading(true);
        // fetch(videoUrl)
        //     .then((response) => response.blob())
        //     .then((blob) => {
        //         const url = window.URL.createObjectURL(blob);
        //         const a = document.createElement('a');
        //         a.style.display = 'none';
        //         a.href = url;
        //         a.download = `${videoTitle}.mp4`;

        //         // Appending the link and triggering the click
        //         document.body.appendChild(a);
        //         a.click();

        //         // Cleanup
        //         document.body.removeChild(a);
        //         window.URL.revokeObjectURL(url); // Revoke the object URL
        //         setIsLoading(false);
        //     })
        //     .catch((error) => {
        //         console.error('Error downloading video:', error);
        //         setIsLoading(false);
        //     });
    };


    const handlePlayPause = (songAudioURL, songId) => {
        if (currentAudio && playingSongId === songId) {
            currentAudio.pause();
            setPlayingSongId(null);
            return;
        }

        if (currentAudio) {
            currentAudio.pause();
        }

        const audio = new Audio(songAudioURL);
        audio.play();
        setCurrentAudio(audio);
        setPlayingSongId(songId);

        audio.onloadedmetadata = () => {
            setDuration(audio.duration);
        };

        audio.ontimeupdate = () => {
            setCurrentTime(audio.currentTime);
        };
    };

    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = Math.floor(timeInSeconds % 60);
        // Ensure two digits for seconds
        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    };

    const handleSeek = (event) => {
        const newTime =
            (event.nativeEvent.offsetX / event.target.offsetWidth) * duration;
        currentAudio.currentTime = newTime;
        setCurrentTime(newTime);
    };

    // Function to download all songs as a zip
    const downloadAllSongs = () => {

        showNotification("success", "These are sample songs and cannot be downloaded.");

        // setIsLoading(true); // Show loader while processing
        // const zip = new JSZip(); // Create a new zip object
        // const songFiles = valentineData.filter((song) => song.Name === valentine); // Get all the songs for the current user

        // // Function to fetch a song and add it to the zip
        // const fetchAndAddSongToZip = async (song, retries = 3) => {
        //     const audioURL = song.AudioURL;
        //     const fileName = `${song.Title}.mp3`; // You can adjust the file extension based on the actual audio file format

        //     try {
        //         const response = await fetch(audioURL);
        //         if (!response.ok) {
        //             throw new Error('Failed to fetch song: ' + response.statusText);
        //         }
        //         const blob = await response.blob();
        //         zip.file(fileName, blob); // Add the song file to the zip
        //     } catch (err) {
        //         console.error(`Error fetching song ${song.Title}:`, err);

        //         // Retry if there are retries left
        //         if (retries > 0) {
        //             console.log(`Retrying... (${3 - retries + 1})`);
        //             return fetchAndAddSongToZip(song, retries - 1); // Retry the fetch
        //         } else {
        //             console.error(`Failed to fetch song after retries: ${song.Title}`);
        //         }
        //     }
        // };

        // // Sequentially download and add each song to the ZIP
        // const addAllSongsToZip = async () => {
        //     for (let i = 0; i < songFiles.length; i++) {
        //         await fetchAndAddSongToZip(songFiles[i]); // Wait for each song to be added
        //     }

        //     // After all songs are added, generate the ZIP file
        //     try {
        //         const content = await zip.generateAsync({ type: "blob" });
        //         const a = document.createElement("a");
        //         const name = `Love Album of ${valentine}`;
        //         a.href = URL.createObjectURL(content);
        //         a.download = `${name}.zip`;
        //         a.click(); // Start the download

        //         setIsLoading(false); // Hide the loader once the download starts
        //         showNotification("success", "Your album has been successfully downloaded!");
        //     } catch (error) {
        //         console.error("Error generating zip file:", error);
        //         setIsLoading(false); // Hide the loader in case of error
        //     }
        // };

        // // Start adding songs to the zip
        // addAllSongsToZip();
    };

    const handleBack = () => {
        window.location.href = 'https://mysong.imadeasong.com/';
    };


    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-screen text-white">
                <img src={loader} alt="loader" className="h-40" />
            </div>
        );
    }

    return (
        <div className="valentines-gradient min-h-screen text-white p-4">
            {/* Header */}
            <div className="flex items-center mb-6">
                <button
                    onClick={handleBack}
                    className="text-left text-2xl mr-1 font-bold"
                    aria-label="Go back"
                >
                    <IoArrowBackCircleOutline size={32} />
                </button>
                <h1 className="text-center flex-1 text-xl font-semibold">
                    Valentines Song for {valentine}
                </h1>
            </div>


            {/* Playlist Cover */}
            <div className="relative flex flex-col items-center">
                {/* Image */}
                <div className="relative">
                    <img
                        // src="https://imas-valentine.s3.eu-central-003.backblazeb2.com/General-images/valentines_heart.png"
                        src="https://imas-valentine.s3.eu-central-003.backblazeb2.com/General-images/couples_heart-image.png"
                        alt="Cover"
                        className="rounded-lg h-72"
                    />
                    {/* Overlay Text */}
                    <div className="absolute left-7 top-16 inset-0 flex items-center justify-center">
                        <p className="text-center text-2xl font-bold px-4 py-2 rounded-lg bg-gradient-to-r from-white via-red-100 to-red-300 bg-clip-text text-transparent">
                            Happy Valentines <br /> {valentine}
                        </p>
                    </div>
                </div>
                {/* Additional Info */}
                <p className="text-sm text-gray-400 mt-4">
                    Cover - Romantic, Valentines
                </p>
                <p className="mt-2 text-sm text-green-400">Made for {valentine}</p>
            </div>

            {/* Audio Progress Bar */}
            {playingSongId && (
                <div className="mt-6 px-8">
                    <p className="text-sm text-center mb-2 text-gray-400">
                        {formatTime(currentTime)} / {formatTime(duration)}
                    </p>
                    <div
                        className="w-full h-2 bg-gray-700 rounded-md cursor-pointer"
                        onClick={handleSeek}
                    >
                        <div
                            className="h-2 bg-red-800 rounded-md"
                            style={{ width: `${(currentTime / duration) * 100}%` }}
                        />
                    </div>
                </div>
            )}

            {/* Video Section */}
            <div className="mt-2">
                {valentineData
                    .filter((n) => n.Name === valentine)
                    .slice(0, 1)
                    .map((song) => (
                        <div
                            key={song.id}
                            className="flex items-center justify-between px-4 py-3 "
                        >
                            <div className="flex items-center">
                                <img
                                    src="https://imas-valentine.s3.eu-central-003.backblazeb2.com/General-images/valentines_heart.png"
                                    alt="theme-icon"
                                    className="w-12 h-12 rounded-md mr-4"
                                />
                                <div>
                                    <p className="font-medium">{song.Title}</p>
                                    <p className="text-sm text-gray-400">{song.artist}</p>
                                </div>
                            </div>
                            <div className="flex items-center space-x-4">
                                <button
                                    className="text-white bg-red-600 hover:bg-red-800 text-sm py-2 px-4 rounded-full"
                                    onClick={() => downloadVideo(song.VideoURL, song.Title)}
                                >
                                    <div className="flex">
                                        <FaDownload className="mr-2" />
                                        Video
                                    </div>
                                </button>
                            </div>
                        </div>
                    ))}
            </div>

            <hr className="w-[100%] mx-auto my-1 border-t-1 border-gray-700" />

            {/* Song List */}
            <div className="mt-2">
                {valentineData
                    .filter((n) => n.Name === valentine)
                    .map((song) => (
                        <div
                            key={song.id}
                            className="flex items-center justify-between px-4 py-3 border-b border-gray-700"
                        >
                            <div className="flex items-center">
                                <img
                                    src="https://imas-valentine.s3.eu-central-003.backblazeb2.com/General-images/valentines_heart.png"
                                    alt="theme-icon"
                                    className="w-12 h-12 rounded-md mr-4"
                                />
                                <div>
                                    <p className="font-medium">{song.Title}</p>
                                    <p className="text-sm text-gray-400">{song.artist}</p>
                                </div>
                            </div>
                            <button
                                className="text-gray-400 hover:text-white text-3xl p-2 rounded-full"
                                onClick={() => handlePlayPause(song.AudioURL, song.id)}
                            >
                                {playingSongId === song.id ? (
                                    <FaCirclePause />
                                ) : (
                                    <FaCirclePlay />
                                )}
                            </button>
                        </div>
                    ))}
            </div>

            {/* Download All Button */}
            <div className="mt-6 flex justify-center mb-10">
                <button
                    onClick={downloadAllSongs} // Trigger the download when the button is clicked
                    className="bg-red-600 hover:bg-red-700 text-white px-6 py-1 rounded-full text-lg shadow-md"
                >
                    Download All Songs
                </button>
            </div>

            <footer className="bg-black text-center">
                <p className="text-sm text-gray-400">
                    This Love Album was created especially for you at{" "}
                    <a
                        // href="https://www.imadeasong.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-green-500"
                    >
                        www.imadeasong.com
                    </a>
                </p>
            </footer>
        </div>
    );
}

export default SpotifyPlaylist;
