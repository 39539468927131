import React from "react";
import styled, { keyframes } from "styled-components";

const bounce = keyframes`
  0%, 100% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(2);
  }
`;

// Style the container div that holds the loader
const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8); /* Adds a background overlay */
  z-index: 9999;
`;

// Style the music loader (the flex container for the bars)
const MusicLoader = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 10px;
`;

// Style individual bars (the "divs" inside the music loader)
const Bar = styled.div`
  width: 6px;
  height: 20px;
  background: linear-gradient(180deg, #00d2ff, #3ae374);
  border-radius: 10%; 
  animation: ${bounce} 0.9s infinite ease-in-out;

  /* Delayed animations for each bar */
  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

const LoaderText = styled.p`
  margin-top: 25px;
  font-size: 1.2rem;
  color: white;
  letter-spacing: 1.6px;
  text-align: center;
  color: #fff;
  font-size :18px;
  font-weight : 600;
  background: linear-gradient(90deg, #a6a8a4, #b2e668, #52d6cf, #46cfb2, #398b87);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const PageLoader = () => {
  return (
    <LoaderContainer>
      <MusicLoader>
        <Bar />
        <Bar />
        <Bar />
      </MusicLoader>
      <LoaderText>Please wait...</LoaderText>
    </LoaderContainer>
  );
};

export default PageLoader;
