import React from "react";

const HowToUsePlatform = ({ onClose }) => {
  return (
    <div className="p-2  h-[66vh] rounded-lg ">
      <div className="relative w-full">
        <button
          onClick={onClose}
          className="absolute top-0 right-0 text-white  bg-black rounded-md text-2xl hover:text-red-700"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div className="w-full">
        <h1 className="text-xl sm:text-2xl text-white font-bold mb-4 mt-4">
          How to Use the "I Made a Song" Platform 🎶
        </h1>

        <div className="mb-6 text-white">
          <h2 className="text-sm font-semibold max-md:text-sm text-white mb-2">
            Create meaningful melodies for any occasion in just a few steps!
          </h2>

          <div className="mb-6 text-white">
            <h2 className="text-xl  text-white max-md:text-base font-semibold mb-2">
              1. Specify the Occasion
            </h2>
            <p className="text-sm">
              🎉 Make your song truly unique by tailoring it to the celebration.
            </p>
            <ul className="list-disc pl-5 mt-2 text-white">
              <li className="text-sm">
                Options include Anniversary, Birthdays, Express Love
                personalized events.
              </li>
              <li className="text-sm">
                This step helps craft lyrics and melodies to match the theme.
              </li>

              <p className="text-sm">
                {" "}
                💡 Example: For a Anniversary, your song could include themes of
                love, togetherness, and promises
              </p>
            </ul>
          </div>
          <div className="mb-6 text-white">
            <h2 className="text-xl  text-white max-md:text-base font-semibold mb-2">
              2. Select the Language
            </h2>
            <p className="text-sm">
              🌍 Pick the language that resonates with your message.
            </p>
            <ul className="list-disc pl-5 mt-2 text-white">
              <li className="text-sm">
                Choose from languages like English, Hindi.
              </li>
              <li className="text-sm">
                The lyrics or song’s theme will align with the language you
                select.
              </li>
              <p className="text-sm">
                💡 Example: A heartfelt Hindi song can add a cultural touch to
                an emotional family moment.
              </p>
            </ul>
          </div>
          <h2 className="text-xl  text-white max-md:text-base font-semibold mb-2">
            3. Choose Your Music Style
          </h2>
          <p className="text-sm">🎵 Start by setting the tone of your song.</p>
          <ul className="list-disc pl-5 mt-2 text-white">
            <li className="text-sm">
              Select a genre from the dropdown menu, such as Pop, peppy, disco.
            </li>
            <li className="text-sm">
              This choice will define the rhythm, beat, and overall vibe of your
              song.
            </li>
            <p className="text-sm">
              💡 Example: A Pop song will sound upbeat and energetic, perfect
              for celebrations like birthdays or weddings.
            </p>
          </ul>
        </div>

        <div className="mb-6 text-white">
          <h2 className="text-xl  text-white max-md:text-base font-semibold mb-2">
            4. Capture the Emotion
          </h2>
          <p className="text-sm">
            💖 Decide how the song should make people feel.
          </p>
          <ul className="list-disc pl-5 mt-2 ">
            <li className="text-sm">
              Choose emotional tones like Romantic, Thankful, Sentiment, or
              Heartfelt.
            </li>
          </ul>
        </div>

        <div className="mb-6 text-white">
          <h2 className="text-xl text-white max-md:text-base font-semibold mb-2">
            5. Select the Voice Type
          </h2>
          <p className="text-sm">
            🎤 Choose the perfect voice to bring your song to life.
          </p>
          <ul className="list-disc pl-5 mt-2 text-sm">
            <li className="text-sm">
              Decide between Male or Female vocals, depending on the tone and
              feel.
            </li>
            <li className="text-sm">
              {" "}
              Vocal style can also influence how the song connects emotionally.
            </li>
          </ul>
        </div>
        <div className="mb-6 text-white">
          <h2 className="text-xl  text-white max-md:text-base font-semibold mb-2">
            6. Generate Your Song
          </h2>
          <p className="text-sm">✨ Watch your creation come to life!</p>
          <ul className="list-disc pl-5 mt-2 ">
            <li className="text-sm">
              Once all your choices are made, click the "Generate" button.
            </li>
            <li className="text-sm">
              The platform will craft a customised song based on your
              preferences.
            </li>
          </ul>
        </div>
        <div className="mb-6 text-white">
          <h2 className="text-xl  text-white max-md:text-base font-semibold mb-2">
            7. Download Your Song
          </h2>
          <p className="text-sm">
            ✨Get your personalized song directly to your device and enjoy it
            anytime
          </p>
          <ul className="list-disc pl-5 mt-2 ">
            <li className="text-sm">
              Click on the download button in the library to download your song.
            </li>
            <li className="text-sm">
              Your downloaded song will appear in Chrome's default download
              section.
            </li>
          </ul>
        </div>

        <div className="mb-6 mt-5">
          <p className="text-xl  text-white max-md:text-base font-semibold mb-2">
            💡 Note: If you’re unsure about your selections, feel free to
            revisit and tweak any step before generating the final song.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowToUsePlatform;
