import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import apiUrl from '../../services/api';
import SecureLS from "secure-ls";

const ls = new SecureLS();

// Async thunk for making the API call
export const submitContactUsForm = createAsyncThunk(
  'contactUs/submit',
  async (contactData, { rejectWithValue }) => {
    try {
      const response = await axios.post(apiUrl.contactUs, contactData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {

      if (error.response?.status === 401) {
        // Handle 401 Unauthorized
        localStorage.clear();
        const navigate = useNavigate();

        const savedCredentials = ls.get("credentials");

        localStorage.clear();
        if (savedCredentials) {
          ls.set("credentials", savedCredentials);
        }
        navigate("/signin");

        return rejectWithValue("Unauthorized - Redirecting to login");
      }

      return rejectWithValue(error.response?.data || 'Something went wrong');
    }
  }
);

// Initial state
const initialState = {
  loading: false,
  success: false,
  error: null,
};

// Slice
const contactUsSlice = createSlice({
  name: 'contactUs',
  initialState,
  reducers: {
    resetContactUsState: (state) => {
      state.loading = false;
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitContactUsForm.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(submitContactUsForm.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(submitContactUsForm.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload;
      });
  },
});

// Export actions and reducer
export const { resetContactUsState } = contactUsSlice.actions;
export default contactUsSlice.reducer;
