import { motion } from "framer-motion";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaHeart } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import openLightbox from "../../assets/dashboard/bannerImg.jpg";
import MusicImage from "../../assets/dashboard/music.png";
import DashboardBanner from "../../assets/partners_images/dashboard_banner.webp";
import DashboardSubscription from "../../components/DashboardSubscription";
import { fetchIPData, postIpData } from "../../redux/ReduxSlices/ipGetInfo.jsx";
import "../../styles/index.css";
import SongType from "./SongTypes.jsx";
import { IoMdClose } from "react-icons/io";
import { FaPlay } from "react-icons/fa";
import { GiGlassCelebration } from "react-icons/gi";
import moodSongIcon from "../../assets/gif/Mood-Based Song Generator Icon.png";
import homePageLeft from "../../assets/image_icons/homepageLeft.png";
import homePageRight from "../../assets/image_icons/homepageRight.png";
import coupleImageDesktop from '../../assets/dashboard/realistic_model_dekstop.webp'

function HomePage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.ipData);
  const token = localStorage.getItem("token");
  const [isVideoModalOpen, setVideoModalOpen] = useState(false);
  const [isVideoModalOpen2, setVideoModalOpen2] = useState(false);
  const [currentVideoSrc, setCurrentVideoSrc] = useState("");
  const [currentVideoSrc2, setCurrentVideoSrc2] = useState("");

  // const handleGenrate = () => {
  //   const userAgent = navigator.userAgent || navigator.vendor;
  //   const isInstagramBrowser = userAgent.includes("Instagram");

  //   // Check if the user is on Instagram's in-app browser
  //   if (isInstagramBrowser) {
  //     window.open("https://imadeasong.com/signupI", "_blank");
  //   } else {
  //     // Fallback for non-Instagram browsers
  //     if (token) {
  //       navigate("/dashboard");
  //     } else {
  //       setTimeout(() => {
  //         navigate("/signin");
  //       }, 1000);
  //     }
  //   }
  // };

  const handleGenrate = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
    const isInAppBrowser = userAgent.includes("Instagram") || userAgent.includes("FBAN") || userAgent.includes("FBAV");

    // Check if the user is on Instagram's or Facebook's in-app browser
    if (isInAppBrowser) {
      window.open("https://imadeasong.com/signupI", "_blank");
    } else {
      // Fallback for non-Instagram and non-Facebook browsers
      if (token) {
        navigate("/dashboard");
      } else {
        setTimeout(() => {
          navigate("/signup");
        }, 1000);
      }
    }
  };

  const createYourOwnSelfie = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
    const isInAppBrowser = userAgent.includes("Instagram") || userAgent.includes("FBAN") || userAgent.includes("FBAV");

    // Check if the user is on Instagram's or Facebook's in-app browser
    if (isInAppBrowser) {
      window.open("https://imadeasong.com/signupI", "_blank");
    } else {
      // Fallback for non-Instagram and non-Facebook browsers
      if (token) {
        navigate("/dashboard", { state: { tab: 'Singing Selfie' } });
      } else {
        setTimeout(() => {
          navigate("/signup", { state: { tab: 'Singing Selfie' } });
        }, 1000);
      }
    }
  };



  const currentDateTime = moment().format("MMMM Do YYYY, h:mm:ss a");

  useEffect(() => {
    dispatch(fetchIPData());
  }, [dispatch]);

  useEffect(() => {
    if (data) {
      // Build the payload

      const payload = {
        skey: "a1b2c3d4e5f67890abcdef1234567890abcdef12YWJjMTIzNDU2Nzg5QUJDMTIzNDU2Nzg5IV4mQCNA",
        ip_address: data.ip,
        network: data.network || "Unknown", // Default if network is not present
        city: data.city,
        country_name: data.country_name,
        continental_code: data.continent_code,
        latitude: data.latitude.toString(),
        longitude: data.longitude.toString(),
        date_time: currentDateTime, // Current date and time
        device: navigator.userAgent, // Get the device details from the browser
      };

      // Dispatch the post action with the payload
      dispatch(postIpData(payload));
    }
  }, [data, dispatch]);
  // #1 Personalized Song Generator

  const handleVideoOpenModal = (videoSrc) => {
    setCurrentVideoSrc(videoSrc);
    setVideoModalOpen(true);
  };

  const handleVideoCloseModal = () => {
    setVideoModalOpen(false);
    setCurrentVideoSrc("");
  };

  const handleVideoOpenModal2 = (videoSrc) => {
    setCurrentVideoSrc2(videoSrc);
    setVideoModalOpen2(true);
  };

  const handleVideoCloseModal2 = () => {
    setVideoModalOpen2(false);
    setCurrentVideoSrc2("");
  };

  return (
    <>
      <section class="song-creation-section">
        <motion.div
          initial={{ y: "-100%" }}
          animate={{ y: 0 }}
          transition={{ duration: 0.3 }}
          className="badge"
        >
          #1 Personalized Song Generator
        </motion.div>
        <motion.div
          initial={{ y: "-100%" }}
          animate={{ y: 0 }}
          transition={{ duration: 0.3 }}
          className="text-white flex justify-center items-center text-l font-bold  mt-4 md:mt-2"
        >
          Made with{" "}
          <a className="ml-2 mr-2">
            <FaHeart style={{ color: "red" }} />
          </a>{" "}
          in India
        </motion.div>
        <motion.h1
          initial={{ y: "-100%" }}
          animate={{ y: 0 }}
          transition={{ duration: 0.3 }}
          class="main-title"
        >
          Create an Unforgettable Personal Gift
        </motion.h1>
        <div className="mt-10 max-md:mt-0 mb-10 max-md:mb-0">
          <img src={coupleImageDesktop} alt="couple image" className="homepage-couple-img" />
        </div>
        <motion.p
          initial={{ y: "-100%" }}
          animate={{ y: 0 }}
          transition={{ duration: 0.3 }}
          class="subtitle"
        >
          Turn your words into music-no music experience needed. Just select,
          tune, and share your song with the world!
        </motion.p>
        <motion.div
          initial={{ y: "-100%" }}
          animate={{ y: 0 }}
          transition={{ duration: 0.3 }}
          className="text-white text-center text-4xl sm:text-5xl mt-4 font-semibold"
        >
          Say it with a Song
        </motion.div>
        <motion.div
          initial={{ y: "-100%" }}
          animate={{ y: 0 }}
          transition={{ duration: 0.3 }}
          class="cta-buttons"
        >
          <button onClick={handleGenrate} class="create-melody-btn ">
            Create a Melody
          </button>
        </motion.div>

        <div class="hero-image">
          <motion.img
            initial={{ x: "-100%" }}
            animate={{ x: 0 }}
            transition={{ duration: 0.3 }}
            src={homePageLeft}
            alt="Hero Left"
            class="hero-image-left"
          />
          <motion.div
            initial={{ scale: 0.2 }}
            animate={{ scale: 0.9 }}
            transition={{ type: "spring", stiffness: 100, damping: 10 }}
            class="hero-image-center"
          >
            <img src={MusicImage} alt="Hero Background" class="hero-image-bg" />
          </motion.div>
          <motion.img
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            transition={{ duration: 0.5 }}
            src={homePageRight}
            alt="Hero Right"
            class="hero-image-right"
          />
        </div>

        <section class="features-section">
          <div class="features-container">
            <div class="features-title">
              <motion.div
                initial={{ y: "-100%" }}
                animate={{ y: 0 }}
                transition={{ duration: 2 }}
                class="features-content"
              >
                <h2 class="features-heading">
                  Create a Song for any Occasion or Celebration in Under 2
                  Minutes
                </h2>
              </motion.div>
            </div>
            <div>
              <img
                src={openLightbox}
                alt="Features Background"
                class="features-image-bg"
              />
            </div>
          </div>
        </section>

        <div className="features-container max-w-7xl mx-auto px-6 lg:px-8 mt-14 max-md:mt-4">
          <div className="features-title text-center max-md:mb-6 mb-6">
            <motion.div
              initial={{ y: "-100%" }}
              animate={{ y: 0 }}
              transition={{ duration: 2 }}
              className="features-content"
            >
              <h2 className="features-heading text-3xl lg:text-5xl font-bold text-gray-800">
                Your Guide to Using <br />I Made A Song
              </h2>
            </motion.div>
            <h2 className="text-xl text-gray-400 text-center max-md:mt-4 mt-8 mb-4">
              Watch the Video to Learn How to Use I Made A Song
            </h2>
          </div>
        </div>
        <div
          className="video-card relative rounded-lg shadow-xl border border-gray-200 bg-gray-800 overflow-hidden cursor-pointer hover:shadow-2xl transition-shadow duration-300 mb-6"
          onClick={() =>
            handleVideoOpenModal2(
              "https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/imas_how-to-use1.mp4"
            )
          }
        >
          <img
            src="https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/imas-general-images/imas_thumbnail.png"
            alt="Slideshow Video Tutorial"
            className="w-80 h-64 object-cover"
          />
          <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center">
            <span className="text-white text-xl font-semibold">
              <FaPlay className="text-4xl" />
            </span>
          </div>
        </div>


        <section className="features-section py-2 mt-16 max-md:mt-4">
          <div className="features-container max-w-7xl mx-auto px-6 lg:px-8">
            <div className="features-title text-center max-md:mb-6 mb-16">
              <motion.div
                initial={{ y: "-100%" }}
                animate={{ y: 0 }}
                transition={{ duration: 2 }}
                className="features-content"
              >
                {/* Heading Section */}
                <h2 className="features-heading text-3xl lg:text-5xl font-bold text-gray-800">
                  Create a personalized video for your friends to celebrate friendship!
                  <GiGlassCelebration className="inline-block text-yellow-500 ml-4" fontSize={55} />
                </h2>

                {/* Greeting Message */}
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 2, delay: 1 }}
                  className="mt-8"
                >
                  <p className="text-xl text-gray-400 mt-2 max-md:mt-0">
                    Celebrate this New Year with your friends, joy, and lots of laughter ✨
                  </p>
                </motion.div>
              </motion.div>
            </div>

            <div className="features-video-wrapper flex justify-center flex-wrap max-md:gap-6 gap-20">
              <div>
                <div
                  className="video-card relative rounded-lg shadow-xl border border-gray-200 bg-gray-800 overflow-hidden cursor-pointer hover:shadow-2xl transition-shadow duration-300"
                  onClick={() =>
                    handleVideoOpenModal2(
                      "https://imas-general.s3.eu-central-003.backblazeb2.com/Friendship_song_am+1.mp4"
                    )
                  }
                >
                  <img
                    src="https://imas-general.s3.eu-central-003.backblazeb2.com/singing_song_thumbnail.png"
                    alt="Singing Selfie Tutorial"
                    className="max-md:w-64 max-md:h-40 w-80 h-64 object-cover"
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center">
                    <span className="text-white text-xl font-semibold">
                      <FaPlay className="text-4xl" />
                    </span>
                  </div>
                </div>
                <h3 className="text-2xl font-bold text-white text-center mt-4">
                  Singing Selfie English
                </h3>
              </div>

              <div>
                <div
                  className="video-card relative rounded-lg shadow-xl border border-gray-200 bg-gray-800 overflow-hidden cursor-pointer hover:shadow-2xl transition-shadow duration-300"
                  onClick={() =>
                    handleVideoOpenModal2(
                      "https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/clone-video-sample.mp4"
                      // "https://imas-general.s3.eu-central-003.backblazeb2.com/friendship-song-vs1.mp4"
                    )
                  }
                >
                  <img
                    // src="https://imas-general.s3.eu-central-003.backblazeb2.com/vedant-thumbnail.png"
                    src="https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/user-thumbnail-sample.jpg"
                    alt="Slideshow Video Tutorial"
                    className="max-md:w-64 max-md:h-40 w-80 h-64 object-cover"
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center">
                    <span className="text-white text-xl font-semibold">
                      <FaPlay className="text-4xl" />
                    </span>
                  </div>
                </div>
                <h3 className="text-2xl font-bold text-white text-center mt-4">
                  Singing Selfie Hindi
                </h3>
              </div>
            </div>

            {isVideoModalOpen2 && (
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-[1000]">
                <div className="relative w-full h-full max-w-screen-sm">
                  <button
                    onClick={handleVideoCloseModal2}
                    className="absolute top-4 right-4 z-10 text-2xl hover:text-red-600 text-white px-2 py-1 border border-white rounded-md"
                  >
                    <IoMdClose />
                  </button>

                  <video
                    className="w-full h-full object-contain"
                    controls
                    autoPlay
                  >
                    <source src={currentVideoSrc2} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            )}
          </div>
          <button
            className="text-black text-xl max-md:text-base mt-16 max-md:mt-8 custom-button mb-4 px-6 py-3 max-md:py-2 rounded-full "
            onClick={createYourOwnSelfie}
          >
            Create Your Own Now
          </button>
        </section>

        <SongType />

        {/* display video for how to create video */}
        <section className="features-section py-12 max-md:mt-4">
          <div className="features-container max-w-7xl mx-auto px-6 lg:px-8">
            <div className="features-title text-center max-md:mb-8 mb-20">
              <motion.div
                initial={{ y: "-100%" }}
                animate={{ y: 0 }}
                transition={{ duration: 2 }}
                className="features-content"
              >
                <h2 className="features-heading text-3xl lg:text-5xl font-bold text-gray-800">
                  Discover how to create a video on I Made A Song
                </h2>
              </motion.div>
            </div>

            <div className="features-video-wrapper flex justify-center flex-wrap max-md:gap-6 gap-20">
              <div className="flex flex-col items-center justify-center space-y-8">
                {/* Video card */}
                <div
                  className="video-card relative rounded-lg shadow-xl border border-gray-200 bg-gray-800 overflow-hidden cursor-pointer hover:shadow-2xl transition-shadow duration-300"
                  onClick={() =>
                    handleVideoOpenModal(
                      "https://imas-ai-home.s3.eu-central-003.backblazeb2.com/singing_selfie_tutorial.mp4"
                    )
                  }
                >
                  <img
                    src="https://imas-ai-home.s3.eu-central-003.backblazeb2.com/Screenshot+2024-12-25+at+12.16.24%E2%80%AFAM.png"
                    alt="Singing Selfie Tutorial"
                    className="w-80 h-64 object-cover"
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center">
                    <span className="text-white text-xl font-semibold">
                      <FaPlay className="text-4xl" />
                    </span>
                  </div>
                </div>

                {/* Text Section */}
                <div className="text-center max-w-sm">
                  <h3 className="text-2xl font-bold text-white">
                    How to Make a Singing Selfie
                  </h3>
                </div>
              </div>

              <div className="flex flex-col items-center justify-center space-y-8">
                {/* Video card */}
                <div
                  className="video-card relative rounded-lg shadow-xl border border-gray-200 bg-gray-800 overflow-hidden cursor-pointer hover:shadow-2xl transition-shadow duration-300"
                  onClick={() =>
                    handleVideoOpenModal(
                      "https://imas-ai-home.s3.eu-central-003.backblazeb2.com/collage_video_tutorial.mp4"
                    )
                  }
                >
                  <img
                    src="https://imas-ai-home.s3.eu-central-003.backblazeb2.com/Screenshot+2024-12-25+at+12.20.31%E2%80%AFAM.png"
                    alt="Slideshow Video Tutorial"
                    className="w-80 h-64 object-cover"
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center">
                    <span className="text-white text-xl font-semibold">
                      <FaPlay className="text-4xl" />
                    </span>
                  </div>
                </div>

                {/* Text Section */}
                <div className="text-center max-w-sm">
                  <h3 className="text-2xl font-bold text-white">
                    How to Make a Slideshow Video
                  </h3>
                </div>
              </div>

            </div>

            {isVideoModalOpen && (
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-[1000]">
                <div className="relative w-full h-full max-w-screen-sm">
                  <button
                    onClick={handleVideoCloseModal}
                    className="absolute top-4 right-4 z-10 text-2xl hover:text-red-600 text-white px-2 py-1 border border-white rounded-md"
                  >
                    <IoMdClose />
                  </button>

                  <video
                    className="w-full h-full object-contain"
                    controls
                    autoPlay
                  >
                    <source src={currentVideoSrc} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            )}
          </div>
        </section>

        <div class="features-grid">
          <div class="features-row">
            <div class="feature-card">
              <div class="feature-card-wrapper">
                <div class="feature-content">
                  <div class="flex-bar">
                    <div class="feature-icon">
                      <img
                        src={moodSongIcon}
                        alt="Mood-Based Song Generator Icon"
                        class="feature-icon-img"
                      />
                    </div>
                    <h3 class="feature-title">Custom Song</h3>
                  </div>
                  <p class="feature-description">
                    Transform any text or personal message into a one-of-a-kind
                    song with I Made a Song's AI-powered text-to-song generator.
                    Create a Song in any Language!
                  </p>
                  <div class="feature-actions">
                    {/* <button class="feature-button">View dashboard</button> */}
                  </div>
                </div>
              </div>
            </div>
            <div class="feature-card">
              <div class="feature-card-wrapper">
                <div class="flex-bar">
                  <div class="feature-icon">
                    <img
                      src={moodSongIcon}
                      alt="Mood-Based Song Generator Icon"
                      class="feature-icon-img"
                    />
                  </div>
                  <h3 class="feature-title">AI Lyrics Writer</h3>
                </div>
                <div class="feature-content">
                  <p class="feature-description">
                    Effortlessly create meaningful lyrics for any genre or
                    theme. From social media shout-outs to personal dedications,
                    let AI help you craft compelling lyrics that make every song
                    unforgettable.
                  </p>
                  <div class="feature-actions">
                    {/* <button class="feature-button">View dashboard</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="features-row mt-4">
            <div class="feature-card">
              <div class="feature-card-wrapper">
                <div class="flex-bar">
                  <div class="feature-icon">
                    <img
                      src={moodSongIcon}
                      alt="Mood-Based Song Generator Icon"
                      class="feature-icon-img"
                    />
                  </div>
                  <h3 class="feature-title">Batch Song Creation</h3>
                </div>
                <div class="feature-content">
                  <p class="feature-description">
                    Generate multiple versions of your song in one go. Explore
                    different genres, moods and vocal styles and find the one
                    that resonates best. Perfect for testing and refining your
                    musical ideas!
                  </p>
                  <div class="feature-actions">
                    {/* <button class="feature-button">View dashboard</button> */}
                  </div>
                </div>
              </div>
            </div>
            <div class="feature-card">
              <div class="feature-card-wrapper">
                <div class="flex-bar">
                  <div class="feature-icon">
                    <img
                      src={moodSongIcon}
                      alt="Mood-Based Song Generator Icon"
                      class="feature-icon-img"
                    />
                  </div>
                  <h3 class="feature-title">Mood-Based Song Generator</h3>
                </div>
                <div class="feature-content">
                  <p class="feature-description">
                    Create a song based on your current mood or emotion. Whether
                    you're feeling happy, nostalgic or relaxed, let our AI craft
                    the perfect melody and lyrics to match your vibe.
                  </p>
                  <div class="feature-actions">
                    {/* <button class="feature-button">View dashboard</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src={DashboardBanner}
          alt="banner-image"
          className="dashboard-banner-height"
          onClick={() => {
            navigate("/partners");
            window.scrollTo(0, 0); // Reset scroll to the top
          }}
        />
        <button
          className="text-black text-xl max-md:text-base mt-16 max-md:mt-8 custom-button mb-4 px-6 py-3 max-md:py-2 rounded-full "
          onClick={() => navigate("/partners")}
        >
          Inquire Now
        </button>
        <DashboardSubscription />
      </section >
    </>
  );
}

export default HomePage;
