import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorImage from "../../assets/dashboard/no-results.png";
import { FaSpinner } from "react-icons/fa";
import {
  deleteCollageVideo,
  getAllVideo,
} from "../../redux/ReduxSlices/slideShowSlice.jsx";
import "../../styles/videoPlayer.css";

import { BiPause, BiPlay } from "react-icons/bi";
import { FaDownload, FaTrash } from "react-icons/fa";
import { FiMaximize } from "react-icons/fi";
import showNotification from "../../hooks/notificationHook.js";

const GeneratedVideo = ({ handleDownload }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [videoToDelete, setVideoToDelete] = useState(null);
  const { allVideos, isLoading, error } = useSelector(
    (state) => state.slideshow
  );
  const dispatch = useDispatch();

  const [videoRefs, setVideoRefs] = useState([]);
  const [playingIndex, setPlayingIndex] = useState(null);
  const [videoStates, setVideoStates] = useState([]);

  useEffect(() => {
    dispatch(getAllVideo());
  }, [dispatch]);

  // Initialize refs and video states once videos are loaded
  useEffect(() => {
    if (allVideos?.collage_videos) {
      const refs = allVideos.collage_videos.map(() => React.createRef());
      setVideoRefs(refs);

      const initialStates = allVideos.collage_videos.map(() => ({
        currentTime: [0, 0],
        duration: [0, 0],
      }));
      setVideoStates(initialStates);
    }
  }, [allVideos]);

  const secToMin = (sec) => {
    const min = Math.floor(sec / 60);
    const secRemain = Math.floor(sec % 60);
    return {
      min,
      sec: secRemain < 10 ? `0${secRemain}` : secRemain,
    };
  };

  const handlePlayPause = (index) => {
    if (playingIndex === index) {
      videoRefs[index].current.pause();
      setPlayingIndex(null);
    } else {
      if (playingIndex !== null && videoRefs[playingIndex]) {
        videoRefs[playingIndex].current.pause();
      }
      videoRefs[index].current.play();
      setPlayingIndex(index);
    }
  };

  const handleTimeUpdate = (index) => {
    const videoRef = videoRefs[index].current;
    const currentTime = secToMin(videoRef.currentTime || 0);

    setVideoStates((prevStates) =>
      prevStates.map((state, i) =>
        i === index ? { ...state, currentTime } : state
      )
    );
  };

  const handleLoadedMetadata = (index) => {
    const videoRef = videoRefs[index].current;
    const duration = secToMin(videoRef.duration || 0);

    setVideoStates((prevStates) =>
      prevStates.map((state, i) =>
        i === index ? { ...state, duration: duration } : state
      )
    );
  };

  const handleDeleteClick = (videoUrl) => {
    setVideoToDelete(videoUrl);
    setShowDeleteModal(true);
  };

  const handleDelete = () => {
    if (!videoToDelete) {

      showNotification("error", "No video selected for deletion.")
      return;
    }
    // Dispatch the delete action for the first video
    dispatch(deleteCollageVideo(videoToDelete))
      .then((response) => {
        if (response.payload.message === "Video successfully deleted") {
          // Show success message

          showNotification("success", "Video deleted successfully")
          setShowDeleteModal(false);
          // Fetch the updated collection of videos after successful deletion
          dispatch(getAllVideo());
        } else {
          // Handle case where deletion was not successful

          showNotification("error", "Failed to delete the video.")
        }
      })
      .catch((error) => {
        // Handle errors during the delete operation

        showNotification("error", "An error occurred while deleting the video.")
      });
  };

  const handleFullScreen = (index) => {
    const videoRef = videoRefs[index].current;
    if (videoRef.requestFullscreen) {
      videoRef.requestFullscreen();
    } else if (videoRef.mozRequestFullScreen) {
      videoRef.mozRequestFullScreen();
    } else if (videoRef.webkitRequestFullscreen) {
      videoRef.webkitRequestFullscreen();
    } else if (videoRef.msRequestFullscreen) {
      videoRef.msRequestFullscreen();
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <FaSpinner className="text-teal-400 text-3xl animate-spin opacity-75 mb-20" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center font-bold text-red-500 mt-10">
        Failed to load videos.
      </div>
    );
  }

  return (
    <>
      <div className="">
        {allVideos?.collage_videos?.length > 0 ? (
          <div className="videoGridContainer">
            {allVideos.collage_videos.map((video, index) => (
              <div key={index} className="playerContainer">
                <div className="videoTitle text-gray-300">
                  <h3>{video.title || `Video ${index + 1}`}</h3>
                </div>
                <video
                  className="videoPlayer"
                  ref={videoRefs[index]}
                  src={video.original_url}
                  controls={false}
                  onLoadedMetadata={() => handleLoadedMetadata(index)}
                  onTimeUpdate={() => handleTimeUpdate(index)}
                />
                <div className="controlsContainer">
                  <div className="controls">
                    <button
                      className="controlButton"
                      onClick={() => handlePlayPause(index)}
                    >
                      {playingIndex === index ? (
                        <BiPause className="size-10" />
                      ) : (
                        <BiPlay className="size-10" />
                      )}
                    </button>
                    <button
                      className="controlButton"
                      onClick={() => handleDownload(video.original_url, video.title)}
                    >
                      <FaDownload style={{ color: "#80d99c" }} />
                    </button>
                    <button
                      className="controlButton"
                      onClick={() => handleDeleteClick(video.original_url)}
                    >
                      <FaTrash style={{ color: "#c64646" }} />
                    </button>
                    <button
                      className="controlButton"
                      onClick={() => handleFullScreen(index)}
                    >
                      <FiMaximize className="size-6" />
                    </button>
                  </div>
                  <input
                    type="range"
                    min="0"
                    max={videoRefs[index]?.current?.duration || 0}
                    value={videoRefs[index]?.current?.currentTime || 0}
                    className="timeline"
                    onChange={(e) => {
                      if (videoRefs[index].current) {
                        videoRefs[index].current.currentTime = e.target.value;
                      }
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex flex-col items-center text-center mt-12 lg:mt-52 pb-8 max-md:mb-12">
            <img className="invert h-10 max-md:h-7" src={ErrorImage} alt="error" />
            <div className="no-song-generated mt-2">No collection found</div>
            <p className="text-gray-300 text-base mt-4">
              <a className="text-teal-300 text-base max-md:text-sm">
                "Generate video with I made a song"
              </a>
            </p>
          </div>
        )}
      </div>
      {showDeleteModal && (
        <div className="fixed inset-0 flex items-center justify-center z-[1000]">
          <motion.div
            className="h-auto w-[50%] max-md:w-[90%]  rounded-lg shadow-2xl relative overflow-hidden"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.3 }}
          >
            <div className="p-4 flex flex-wrap justify-center items-center">
              <div className="w-full max-w-md delete-popup shadow-lg rounded-lg p-6 relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-3 cursor-pointer fill-white hover:fill-red-500 float-right"
                  onClick={() => setShowDeleteModal(false)}
                  viewBox="0 0 320.591 320.591"
                >
                  <path
                    d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
                    data-original="#000000"
                  ></path>
                  <path
                    d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
                    data-original="#000000"
                  ></path>
                </svg>
                <div className="my-8 text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-14 fill-red-300 inline"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M19 7a1 1 0 0 0-1 1v11.191A1.92 1.92 0 0 1 15.99 21H8.01A1.92 1.92 0 0 1 6 19.191V8a1 1 0 0 0-2 0v11.191A3.918 3.918 0 0 0 8.01 23h7.98A3.918 3.918 0 0 0 20 19.191V8a1 1 0 0 0-1-1Zm1-3h-4V2a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v2H4a1 1 0 0 0 0 2h16a1 1 0 0 0 0-2ZM10 4V3h4v1Z"
                      data-original="#000000"
                    />
                    <path
                      d="M11 17v-7a1 1 0 0 0-2 0v7a1 1 0 0 0 2 0Zm4 0v-7a1 1 0 0 0-2 0v7a1 1 0 0 0 2 0Z"
                      data-original="#000000"
                    />
                  </svg>
                  <h4 className="text-white text-l font-semibold mt-4">
                    Are you sure you want to delete it?
                  </h4>
                </div>
                <div className="flex flex-col space-y-2">
                  <button
                    type="button"
                    className="px-4 py-2 rounded-lg text-white text-sm tracking-wide bg-red-500 hover:bg-red-600 active:bg-red-500"
                    onClick={handleDelete}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </>
  );
};

export default GeneratedVideo;
