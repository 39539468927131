import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import apiUrl from '../../services/api';

export const fetchOpenAIResponse = createAsyncThunk(
  'openAi/fetchOpenAIResponse',
  async (message, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(
        apiUrl.generateCustomSong,
        { prompt: message },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );


      let check = [];

      if (JSON.stringify(response.data) === JSON.stringify(check)) {
        return response.data;
      } else {
        return response.data;
      }

    } catch (error) {
      return rejectWithValue("Something went wrong, please try again later.");
    }
  }
);

const openAiSlice = createSlice({
  name: 'openAi',
  initialState: {
    response: '',
    isLoading: false,
    error: null,
  },
  reducers: {
    clearState: (state) => {
      state.response = '';
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOpenAIResponse.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchOpenAIResponse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
      })
      .addCase(fetchOpenAIResponse.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { clearState } = openAiSlice.actions;
export default openAiSlice.reducer;