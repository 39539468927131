import React, { useEffect, useRef, useState } from "react";
import { MdOutlineInfo } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ScrollDown from '../../assets/gif/scroll_down.gif';
import ErrorImage from "../../assets/song/alert.webp";
import HowToUsePlatform from "../../components/HowToUsePlatform";
import { fetchCreditBalance } from "../../redux/ReduxSlices/creditSlice";
import { deductFreeTrialCount } from "../../redux/ReduxSlices/freeTrialSlice";
import { fetchOpenAIResponse } from "../../redux/ReduxSlices/openAISlice";
import { UserProfileDetails } from '../../redux/ReduxSlices/ProfileSlice';
import { getGenderOptions } from "../../utils/gender";
import { getGenresOption } from "../../utils/genres";
import { getLanguageOptions } from "../../utils/language";
import { getMoodOptions } from "../../utils/moodOptions";
import { getOccassionOption } from "../../utils/occassion";
import { motion } from 'framer-motion';
import HowToUseVideoCollage from "../VideoCollage/HowToUseVideoCollage";
import showNotification from "../../hooks/notificationHook";

function CustomeAI({ onGenerate, onClose, setLoader }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectMood, setSelectMood] = useState("");
  const [selectStyle, setSelectStyle] = useState("");
  const [selectGender, setSelectGender] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedOccasion, setSelectedOccasion] = useState("");
  const [selectInstrument, setSelectInstrument] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [isAnimating, setIsAnimating] = useState(false);
  const { isLoading, error } = useSelector((state) => state.openAi);
  const { balance } = useSelector((state) => state.credits);
  const { freeTrailData } = useSelector((state) => state.freeTrial);
  const [isModalVisible, setModalVisible] = useState(false);
  const [rejectedStyle, setRejectedStyle] = useState([]);
  const [rejectedMood, setRejectedMood] = useState([]);
  const { userDetails } = useSelector((state) => state.user);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false); //
  const [showModal, setShowModal] = useState(false);

  const sumCount = (userDetails?.free_trial_count ?? 0) + (balance?.credit_point ?? 0);
  useEffect(() => {
    // Show the tooltip when the component mounts
    setTooltipVisible(true);
  }, []);
  const modalRef = useRef(null);
  const resetSelections = () => {
    setSelectMood("");
    setSelectGender("");
    setSelectStyle("");
    setSelectedLanguage("");
    setSelectedOccasion("");
    setSelectInstrument("");
  };


  useEffect(() => {
    dispatch(UserProfileDetails());

  }, [dispatch, sumCount]);


  const handleSendMessage = async (e) => {

    e.preventDefault();

    if (sumCount === 0) {
      setTimeout(() => {
        navigate("/pricing");
      }, 2000);

      return showNotification("error", "You need to subscribe to generate more songs!")

    }

    dispatch(UserProfileDetails());

    if (freeTrailData.free_trial_count === 0 && balance.credit_point === 0) {

      setTimeout(() => {
        navigate("/pricing");
      }, 2000);
      return showNotification("error", "You need to subscribe to generate more songs!")


    }



    if (
      !selectStyle ||
      !selectedLanguage ||
      !selectedOccasion ||
      !selectMood ||
      !selectGender
    ) {

      showNotification("error", "Please choose all fields to generate better lyrics");
      return;
    }

    // dont delete this
    // "thank you mom and dad", "thank you mother", "thank you father"
    let occasion = ''
    if (selectedOccasion === "Thank You Mother") {
      occasion = "thank you mother"
    }
    else if (selectedOccasion === "Thank You Father") {
      occasion = "thank you father"
    }
    else if (selectedOccasion === "Thank You Parents") {
      occasion = "thank you mom and dad"
    }
    else {
      occasion = selectedOccasion
    }
    const selectedValues = [
      selectedLanguage,
      selectStyle,
      selectedOccasion,
      selectMood,
      selectInstrument,
      selectGender,
    ];

    localStorage.setItem("selectedValues", JSON.stringify(selectedValues));

    let message = `Compose a ${selectStyle} song in ${selectedLanguage}, for ${occasion} occasion. Utilize ${selectGender} to evoke ${selectMood} emotions, ensuring the melody perfectly complements the essence of the occasion.`;

    try {
      setLoader(true);
      let response1 = await dispatch(fetchOpenAIResponse(message));

      var noDataForMatching = response1.payload[0].message;

      if (noDataForMatching === "Failed to generate song. Please try again.") {
        setShowModal(true);
      }

      let showConditionalBox = response1?.payload?.message

      setTimeout(() => {
        setLoader(false);
      }, 10000);

      var noDataForMatching = response1.payload[0].message;

      var noDataForMatching = response1.payload[0].message;

      if (noDataForMatching === "Failed to generate song. Please try again.") {
        setShowModal(true);
      } else {
        if (window.innerWidth <= 768 || /iPhone|iPad|iPod/i.test(navigator.userAgent)) {
          setModalVisible(true);
        } else {
          //
        }
      }

      if (
        // typeof showConditionalBox === "object" &&
        showConditionalBox === "No matching entries found."

      ) {
        setDialogMessage(
          "Oops... It seems that the combination didn’t generate a song. Please try a different one!"
        );

        setDialogVisible(true);

      } else if (showConditionalBox?.length > 0 && freeTrailData.free_trial_count > 0) {
        dispatch(deductFreeTrialCount());
      } else {
        dispatch(fetchCreditBalance());
      }

      dispatch(UserProfileDetails());

      onClose();
    } catch (err) {
      console.error(err);

      setTimeout(() => {
        setLoader(false);
      }, 10000);
    }
    dispatch(UserProfileDetails());
    resetSelections();
  };

  const closeModal1 = () => {
    setShowModal(false); // Hide the modal
  };

  const closeDialog = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setDialogVisible(false);
      setIsAnimating(false);
    }, 300);
  };

  const handleInfoClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsModalOpen(false);
      }
    };

    if (isModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isModalOpen]);

  useEffect(() => {
    if (
      selectedOccasion === "Thank You Mother" ||
      selectedOccasion === "Thank You Father" ||
      selectedOccasion === "Thank You Parents"
    ) {
      let style = ["Fast", "Peppy", "Bollywood Pop", "Disco"];
      let mood = ["Romantic", "Relaxed", "Joyful"];
      setRejectedStyle((prev) => [...prev, ...style]);
      setRejectedMood((prev) => [...prev, ...mood]);
    } else {
      setRejectedStyle([]);
      setRejectedMood([]);
    }
  }, [selectedOccasion]);

  return (
    <>
      <div className="relative mb-2 lg:mb-4 text-center mt-4 lg:mt-0 ">
        <div className="flex max-md:mt-8  ">
          <h2 className="text-l  max-md:text-lg lg:text-xl font-bold text-white">
            Create Your Song{" "}
          </h2>
          <h2
            className="text-white text-start text-2xl font-bold absolute right-0 top-1"
            onClick={handleInfoClick}
          >
            <MdOutlineInfo className="cursor-pointer" />{" "}
          </h2>
        </div>
        <p className="text-gray-400 mt-2 text-start text-sm">
          Customize every detail to create the perfect melody
        </p>
      </div>
      <motion.div
        initial={{ x: "-100%" }}
        animate={{ x: 0 }}
        transition={{ duration: 0.3 }}
        className="inset-0 bg-opacity-50 flex items-center justify-center">
        <div className="text-black border-6 px-2 shadow-xl w-full bg-gradient-to-br from-teal-900 via-gray-800 to-black-300 backdrop-blur-md rounded py-2 font-medium max-w-lg sm:max-w-4xl">
          <div className="space-y-3">
            <div>
              <label className="text-white block mb-1 pl-2">Occasion</label>
              <div className="flex flex-wrap gap-2 border border-[#7fd7a373] rounded-md  p-2">
                {getOccassionOption().map((option, index) => (
                  <button
                    key={index}
                    onClick={() => setSelectedOccasion(option.value)}
                    className={`py-1 px-2 rounded-md shadow-sm text-sm max-md:text-base max-md:font-semibold text-white border border-teal-800 ${selectedOccasion === option.value
                      ? "bg-teal-600 border border-none"
                      : "bg-gray-800"
                      } hover:bg-teal-500 focus:ring focus:ring-teal-600`}
                  >
                    {option.value}
                  </button>
                ))}
              </div>
            </div>
            <div>
              <label className="text-white block mb-1 pl-2">Language</label>
              <div className="flex flex-wrap gap-2  border border-[#7fd7a373] rounded-md p-2">
                {getLanguageOptions().map((option, index) => (
                  <button
                    key={index}
                    onClick={() => setSelectedLanguage(option.value)}
                    className={`py-1 px-2 rounded-md shadow-sm text-sm max-md:text-base max-md:font-semibold text-white border border-teal-800 ${selectedLanguage === option.value
                      ? "bg-teal-600 border border-none"
                      : "bg-gray-800"
                      } hover:bg-teal-500 focus:ring focus:ring-teal-600`}
                  >
                    {option.value}
                  </button>
                ))}
              </div>
            </div>
            <div>
              <label className="text-white block mb-1 pl-2">Music Style</label>
              <div className="flex flex-wrap gap-2 border border-[#7fd7a373] rounded-md p-2">
                {getGenresOption()
                  .filter((option) => {
                    return !rejectedStyle.includes(option.value);
                  })
                  .map((option, index) => (
                    <button
                      key={index}
                      onClick={() => setSelectStyle(option.value)}
                      className={`py-1 px-2 rounded-md shadow-sm text-sm max-md:text-base max-md:font-semibold text-white border border-teal-800 ${selectStyle === option.value
                        ? "bg-teal-600 border border-none"
                        : "bg-gray-800"
                        } hover:bg-teal-500 focus:ring focus:ring-teal-600`}
                    >
                      {option.value}
                    </button>
                  ))}
              </div>
            </div>

            <div>
              <label className="text-white block mb-1 pl-2">Mood</label>
              <div className="flex flex-wrap gap-1 border  border-[#7fd7a373] rounded-md p-2">
                {getMoodOptions()
                  .filter((option) => {
                    return !rejectedMood.includes(option.value);
                  })
                  .map((option, index) => (
                    <button
                      key={index}
                      onClick={() => setSelectMood(option.value)}
                      className={`py-1 px-2 rounded-md shadow-sm text-sm max-md:text-base max-md:font-semibold text-white border border-teal-800 ${selectMood === option.value
                        ? "bg-teal-600 border border-none"
                        : "bg-gray-800"
                        } hover:bg-teal-500 focus:ring focus:ring-teal-600`}
                    >
                      {option.value}
                    </button>
                  ))}
              </div>
            </div>
            <div>
              <label className="text-white block mb-1 pl-2">Voice</label>
              <div className="flex flex-wrap gap-2 border   border-[#7fd7a373] rounded-md p-2">
                {getGenderOptions().map((option, index) => (
                  <button
                    key={index}
                    onClick={() => setSelectGender(option.value)}
                    className={`py-1 px-2 rounded-md shadow-sm text-sm max-md:text-base max-md:font-semibold text-white border border-teal-800 ${selectGender === option.value
                      ? "bg-teal-600 border border-none"
                      : "bg-gray-800"
                      } hover:bg-teal-500 focus:ring focus:ring-teal-600`}
                  >
                    {option.value}
                  </button>
                ))}
              </div>
            </div>
          </div>
          {/* {error && <p className="text-red-500 text-center mt-4">{error}</p>} */}
        </div>
      </motion.div>

      <div className="relative flex justify-center w-full mt-4 lg:mt-4 mb-36 max-md:mb-0">
        <button
          onClick={handleSendMessage}
          className="custom-button text-black bg-teal-600 font-medium py-3 w-full px-5 rounded-md hover:bg-teal-500 shadow-lg transition duration-300"
        >
          {isLoading ? "Generating Song" : "Generate Song"}
        </button>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-80 z-50 flex justify-center items-center text-black shadow-lg ">
          <div
            className="rounded-lg p-4 model-margin sm:p-6 w-full sm:w-3/4 md:w-1/2 overflow-auto bg-black border border-gray-700"
            ref={modalRef}
          >
            <HowToUsePlatform onClose={closeModal} />

          </div>
        </div>
      )}

      {/* Dialog */}
      {dialogVisible && (
        <div className="dialog">
          <div
            className={`dialog-content ${isAnimating ? "dialog-close" : "dialog-open"
              }`}
          >
            <div className="flex justify-center">
              <img src={ErrorImage} alt="error-image" className="h-20" />
            </div>
            <p className="text-xl mt-2 font-semibold">{dialogMessage}</p>
            <button
              onClick={closeDialog}
              className="mt-8 clg-btn font-semibold cursor-pointer"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {showModal && (
        <div
          style={{
            zIndex: '1',
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgb(0 0 0 / 92%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: '90%',
              maxWidth: "500px",
              border: "1px solid #282828",
              color: '#ffffff',
              background: "black",
              padding: "40px 48px",
              borderRadius: "6px",
              textAlign: "center",
            }}
          >
            <h2>Oops!</h2>
            <p className="text-2xl">Oops! Our AI couldn’t generate the best song from the provided inputs. Please try again to create a beautiful melody!</p>
            <button className="text-black mt-4 border rounded-lg px-4 bg-white" onClick={closeModal1}>Close</button>
          </div>
        </div>
      )}

      {isModalVisible && (
        <div className="modal">
          <div className="modal-content">
            <p className="text-2xl">
              Your song has been successfully generated. Please scroll down.
            </p>
            <div className="flex justify-center align-baseline">
              <button
                onClick={() => setModalVisible(false)}
                className="clg-btn"
              >
                {" "}
                <a>
                  <img src={ScrollDown} alt="Close" className="w-10" />
                </a>
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      <style jsx>{`
        .modal {
          display: flex;
          align-items: center;
          justify-content: center;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgb(0 0 0 / 87%);
          z-index: 1000;
        }
        .modal-content {
          border: 1px solid #393939 !important;
          background: #000;
          color: #fff;
          padding: 20px;
          border-radius: 6px;
          text-align: center;
        }
        .clg-btn {
          border: none;
          margin-top: 20px;
          font-size: 18px;
          background: #000000;
        }
      `}</style>
    </>
  );
}

export default CustomeAI;
