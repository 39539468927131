// Female :
// https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/female-eng/English-female-1.1.mp3
// https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/female-eng/English-female-2.mp3
// https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/female-eng/English-female-3.mp3
// https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/female-eng/English-female-4.mp3
// https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/female-eng/English-female-5.mp3

// https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/female-hindi/friends-hindi-female1.mp3
// https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/female-hindi/friends-hindi-female2.mp3
// https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/female-hindi/hindi-female-3.mp3
// https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/female-hindi/hindi-female4.mp3
// https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/female-hindi/hindi-female5.mp3

// Male :

// https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/male-eng/English-male-friend1.mp3
// https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/male-eng/English-male-friends2.mp3
// https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/male-eng/English-male-friends3.mp3
// https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/male-eng/English-male-friends4.mp3
// https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/male-eng/English-male-friends5.mp3

// https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/male-hindi/hindi-male-friend1.mp3
// https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/male-hindi/hindi-male-friend2.mp3
// https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/male-hindi/hindi-male-friend3.mp3
// https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/male-hindi/hindi-male-friend4.mp3
// https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/male-hindi/hindi-male-friend5.mp3


export const newYearSongs = [
  // Female English
  {
    id: 1,
    category: "female",
    song_name: "English-1",
    language: "English",
    audio_url: "https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/female-eng/English-female-1.1.mp3",
  },
  {
    id: 2,
    category: "female",
    song_name: "English-2",
    language: "English",
    audio_url: "https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/female-eng/English-female-2.mp3",
  },
  {
    id: 3,
    category: "female",
    song_name: "English-3",
    language: "English",
    audio_url: "https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/English-female-3.mp3",
  },
  {
    id: 4,
    category: "female",
    song_name: "English-4",
    language: "English",
    audio_url: "https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/female-eng/English-female-4.mp3",
  },
  {
    id: 5,
    category: "female",
    song_name: "English-5",
    language: "English",
    audio_url: "https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/female-eng/English-female-5.mp3",
  },
  // Female Hindi
  {
    id: 6,
    category: "female",
    song_name: "Hindi-1",
    language: "Hindi",
    audio_url: "https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/female-hindi/friends-hindi-female1.mp3",
  },
  {
    id: 7,
    category: "female",
    song_name: "Hindi-2",
    language: "Hindi",
    audio_url: "https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/female-hindi/friends-hindi-female2.mp3",
  },
  {
    id: 8,
    category: "female",
    song_name: "Hindi-3",
    language: "Hindi",
    audio_url: "https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/female-hindi/hindi-female-3.mp3",
  },
  {
    id: 9,
    category: "female",
    song_name: "Hindi-4",
    language: "Hindi",
    audio_url: "https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/female-hindi/hindi-female4.mp3",
  },
  {
    id: 10,
    category: "female",
    song_name: "Hindi-5",
    language: "Hindi",
    audio_url: "https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/female-hindi/hindi-female5.mp3",
  },
  // Male English
  {
    id: 11,
    category: "male",
    song_name: "English-1",
    language: "English",
    audio_url: "https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/male-eng/English-male-friend1.mp3",
  },
  {
    id: 12,
    category: "male",
    song_name: "English-2",
    language: "English",
    audio_url: "https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/male-eng/English-male-friends2.mp3",
  },
  {
    id: 13,
    category: "male",
    song_name: "English-3",
    language: "English",
    audio_url: "https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/male-eng/English-male-friends3.mp3",
  },
  {
    id: 14,
    category: "male",
    song_name: "English-4",
    language: "English",
    audio_url: "https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/male-eng/English-male-friends4.mp3",
  },
  {
    id: 15,
    category: "male",
    song_name: "English-5",
    language: "English",
    audio_url: "https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/male-eng/English-male-friends5.mp3",
  },
  // Male Hindi
  {
    id: 16,
    category: "male",
    song_name: "Hindi-1",
    language: "Hindi",
    audio_url: "https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/male-hindi/hindi-male-friend1.mp3",
  },
  {
    id: 17,
    category: "male",
    song_name: "Hindi-2",
    language: "Hindi",
    audio_url: "https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/male-hindi/hindi-male-friend2.mp3",
  },
  {
    id: 18,
    category: "male",
    song_name: "Hindi-3",
    language: "Hindi",
    audio_url: "https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/male-hindi/hindi-male-friend3.mp3",
  },
  {
    id: 19,
    category: "male",
    song_name: "Hindi-4",
    language: "Hindi",
    audio_url: "https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/male-hindi/hindi-male-friend4.mp3",
  },
  {
    id: 20,
    category: "male",
    song_name: "Hindi-5",
    language: "Hindi",
    audio_url: "https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/male-hindi/hindi-male-friend5.mp3",
  },
];
