import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import apiUrl from '../../services/api';
import { clipPath } from 'framer-motion/client';
import { title } from 'framer-motion/m';

// Async thunk to handle downloading audio
export const downloadAudio = createAsyncThunk(
  "downloadAudio/downloadAudio",
  async ({ songId }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        apiUrl.downloadAudio,
        { id: songId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      );

      return { success: true, songId }; // Return metadata only
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);



export const deleteAudio = createAsyncThunk('deleteAudio/DeleteAudio', async (songId, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(apiUrl.deleteAudio, {
      song_id: songId
    },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })

    return response.data
  } catch (error) {
    return rejectWithValue(error.response?.data || error.message);
  }

})

// Slice for download audio
const downloadAudioSlice = createSlice({
  name: "downloadAudio",
  initialState: {
    downloadAudioData: null,
    deleteAudioData: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(downloadAudio.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(downloadAudio.fulfilled, (state, action) => {
        state.loading = false;
        state.downloadAudioData = action.payload; // Only store metadata
      })
      .addCase(downloadAudio.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteAudio.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAudio.fulfilled, (state, action) => {
        state.loading = false;
        state.deleteAudioData = action.payload;
      })
      .addCase(deleteAudio.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});


export default downloadAudioSlice.reducer;


