import React from "react";
import fnpImg from "../../assets/valentineImages/83609_Large.jpg";
import Img1 from "../../assets/valentineImages/Album-I1.png";
import Img2 from "../../assets/valentineImages/Album-I2.png";
import Img3 from "../../assets/valentineImages/Album-I3.png";
import Img4 from "../../assets/valentineImages/Album-I4.png";
import sideImg1 from "../../assets/valentineImages/M1.png";
import mobileImg from "../../assets/valentineImages/MOBILE.png";
import img5 from "../../assets/valentineImages/Play-the-song-during-a-romantic.png";
import img6 from "../../assets/valentineImages/Play-the-song-on-a-scenic-romantic-long-drive..png";
import img8 from "../../assets/valentineImages/Rectangle-2-copy-4.png";
import secondBanner from "../../assets/valentineImages/SO.png";
import firstBanner from "../../assets/valentineImages/Untitled-1.png";
import img7 from "../../assets/valentineImages/Use-the-song-as-part-of-your-proposal-moment..png";
import tripImg from "../../assets/valentineImages/easemytrip-com-logo-png_seeklogo-351185.png";
import marvelImg from "../../assets/valentineImages/marvelstudios.png";
import netflixImg from "../../assets/valentineImages/netfli.png";
import primeImg from "../../assets/valentineImages/primevideo.png";

import { FaHeart } from "react-icons/fa6";

function ValentinePage() {
  return (
    <div className="bg-[#fff6f8] min-h-screen overflow-x-hidden">
      <div className="max-w-7xl mx-auto ">
        <div
          className=" bg-no-repeat min-h-screen flex flex-col items-center justify-center px-6 bg-top rounded-b-[50px] md:bg-cover "
          style={{
            backgroundImage: `url(${firstBanner})`,
            objectFit: "cover",
          }}
        >
          <div className="text-center mt-36 md:mt-[51rem]">
            <h1 className="text-2xl md:text-5xl font-bold text-pink-700 font-Centaur">
              The Most Romantic Musical
              <p>Gift for Your Valentine</p>
            </h1>
            <div className="bg-white rounded-xl px-4 py-2 mt-8">
              <p className="text-lg md:text-xl text-pink-600">
                Introducing The World’s First Personalized
                <p>Valentine’s Love Song Album!</p>
              </p>
            </div>
          </div>

          <div className="text-center mt-6 max-w-3xl mb-5">
            <p className="text-pink-950 text-sm md:text-lg">
              Forget the traditional flowers and chocolates—surprise your
              partner with a customized 10-song album featuring their name in
              the lyrics.
            </p>
          </div>
        </div>

        <div className="mt-[-20%] md:mt-10">
          <h2 className="text-center text-base md:text-2xl font-semibold text-pink-400">
            Listen to How Some of Our Customers Created
            <p className=" text-2xl md:text-3xl text-pink-700">
              Love Song Albums
            </p>
          </h2>

          <div className="grid grid-cols-2 md:grid-cols-4 gap-5 mt-8">
            <div className="relative ">
              <img
                src={Img1}
                alt="Customer example 1"
                className="w-full h-auto rounded-lg"
              />
              <button className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-2 rounded-full shadow-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 text-pink-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.25 9V5.25M14.25 5.25L18 9M14.25 5.25L10.5 9"
                  />
                </svg>
              </button>
            </div>

            <div className="relative   ">
              <img
                src={Img2}
                alt="Customer example 2"
                className="w-full h-auto rounded-lg"
              />
              <button className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-2 rounded-full shadow-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 text-pink-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.25 9V5.25M14.25 5.25L18 9M14.25 5.25L10.5 9"
                  />
                </svg>
              </button>
            </div>

            <div className="relative   ">
              <img
                src={Img3}
                alt="Customer example 3"
                className="w-full h-auto rounded-lg"
              />
              <button className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-2 rounded-full shadow-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 text-pink-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.25 9V5.25M14.25 5.25L18 9M14.25 5.25L10.5 9"
                  />
                </svg>
              </button>
            </div>

            <div className="relative  ">
              <img
                src={Img4}
                alt="Customer example 4"
                className="w-full h-auto rounded-lg"
              />
              <button className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-2 rounded-full shadow-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 text-pink-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.25 9V5.25M14.25 5.25L18 9M14.25 5.25L10.5 9"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="mt-16">
          <div
            className="flex items-center justify-center  md:bg-cover md:bg-center bg-no-repeat relative rounded-xl"
            style={{
              backgroundImage: `url(${secondBanner})`,
              height: "500px",
              objectFit: "cover",
            }}
          >
            <div className=" w-1/2 md:w-2/5  rounded-lg   absolute top-[20px] md:top-[30%] right-0">
              <div className="bg-white px-4 w-full rounded-l-lg">
                <h3 className="text-sm md:text-3xl font-bold text-pink-500 px-1">
                  Exclusive Offer:{" "}
                  <span className="line-through  mr-1">₹3,999</span> ₹1,999 for
                  a<p>10 songs personized love album</p>
                </h3>
              </div>

              <p className="text-white mt-2 text-base">
                Once your order is placed, the link to your album will be
                digitally delivered via email or WhatsApp.
              </p>
              <button className="mt-4 bg-[#e7dec7] text-pink-600 py-2 px-6 text-xl rounded-full font-semibold ">
                Order Now
              </button>
            </div>
            <div className="absolute mt-14 md:bottom-0 left-5">
              <p className="text-white mt-4 text-sm text-left mb-5">
              OFFER VALID TILL 5Th FEBRUARY
              </p>
            </div>
          </div>

          <div className="grid grid-cols-3 md:grid-cols-3 gap-3 mt-[-28%] md:mt-10 px-1">
            <div className="text-center ">
              <img
                src={img5}
                alt="Candlelight dinner"
                className="w-full rounded-lg h-40"
              />
              <p className="mt-4 text-pink-700 text-sm md:text-lg">
                Play the song during a romantic candlelight dinner.
              </p>
            </div>

            <div className="text-center ">
              <img
                src={img6}
                alt="Romantic drive"
                className="w-full rounded-lg h-40"
              />
              <p className="mt-4 text-pink-700 text-sm md:text-lg">
                Play the song on a scenic romantic drive.
              </p>
            </div>

            <div className="text-center">
              <img
                src={img7}
                alt="Proposal moment"
                className="w-full rounded-lg h-40"
              />
              <p className="mt-4 text-pink-700 text-sm md:text-lg">
                Use the song as part of your proposal moment.
              </p>
            </div>
          </div>

          <div className="text-center mt-8">
            <button className="bg-[#e7dec7] text-pink-700 p-4 font-semibold rounded-full  border border-white">
              Get Your Personalized Love Song Album
            </button>
          </div>
        </div>

        <div className="bg-[#eee8e1] rounded-xl shadow-lg p-8 mt-14">
          <h2 className="text-2xl font-bold text-center text-pink-800 mb-6">
            About The Makers
          </h2>
          <div className="text-pink-700 space-y-4 mb-8">
            <p>
              Digitune Studios is an NSE-listed firm & a renowned name in the
              entertainment industry.
            </p>
            <ul className="space-y-2">
              <li>
                • 24 years of experience in the international media sector
              </li>
              <li>• Active in specializing in film and television</li>
              <li>• Delivering 250+ Hollywood films and TV series</li>
            </ul>
          </div>
          <hr className="bg-white h-1 rounded-full my-2" />
          <p className="text-center text-pink-700 mt-4">
            {" "}
            Worked with Globally Acclaimed platform
          </p>
          <div className="flex justify-center items-center gap-8 mt-4">
            <img src={marvelImg} alt="Marvel Studios" className="h-8" />
            <img src={netflixImg} alt="Netflix" className="h-8" />
            <img src={primeImg} alt="Prime Video" className="h-8 " />
          </div>
        </div>

        <div className="mt-12">
          <div className="text-center text-gray-800">
            <h1 className="text-xl md:text-4xl font-bold mb-4 text-pink-600">
              Order Your Personalized <br />{" "}
              <span className="text-pink-700 text-2xl">
                Love Song Album Now!
              </span>
            </h1>
          </div>
          <div className="grid grid-cols-3 gap-4 mb-16 px-2">
            {[
              { price: "1,000", label: "BASE PACKAGE" },
              { price: "1,500", label: "REGULAR PACKAGE" },
              { price: "2,000", label: "PREMIUM PACKAGE" },
            ].map((pkg, index) => (
              <div
                key={index}
                className={`rounded-xl p-6 text-center bg-[#efc6d1] shadow-lg`}
              >
                <p className="text-pink-700 font-medium mb-2 ">{pkg.label}</p>
                <p className=" font-bold text-pink-600">₹{pkg.price}</p>
              </div>
            ))}
          </div>

          <div className="grid grid-cols-6 gap-4 items-center text-center mb-16">
            <div className="col-span-1">
              <img src={sideImg1} alt="LeftImage" className="w-full h-auto " />
            </div>

            <div className="col-span-4">
              <button className="bg-[#eee8e1] text-pink-800 text-lg py-2 font-semibold px-4 rounded-full shadow-lg hover:shadow-xl transition-shadow">
                ORDER NOW
              </button>
              <p className="text-pink-800 mt-4 text-lg md:text-xl">
                Special Offer: use code LOVE50
              </p>
              <p className="text-pink-600 mt-4 text-sm font-Open Sans md:text-lg">
                OFFER VALID TILL 5Th FEBRUARY{" "}
              </p>
            </div>

            {/* Right Column: Image */}
            <div className="col-span-1">
              <img src={sideImg1} alt="RightImage" className="w-full h-auto " />
            </div>
          </div>
        </div>
        <div className="">
          <div className="grid grid-cols-6 gap-8 items-center">
            <div className="col-span-4 text-center md:text-left text-gray-800 px-4">
              <h1 className="text-2xl md:text-4xl font-bold mb-4">
                Surprise Your Valentine with <br />
                <span className="text-red-500">
                  a Personalized Love Song Album
                </span>
              </h1>
              <ul className="list-inside text-gray-600 mb-4 text-sm md:text-lg">
                <li className="mb-2 flex">
                  <FaHeart className="fill-pink-700 mx-1 " /> Digital delivery
                  via email or WhatsApp.
                </li>
                <li className="mb-2 flex">
                  <FaHeart className="fill-pink-700 mx-1" /> Features your
                  partner's name.
                </li>
                <li className="flex mb-2">
                  <FaHeart className="fill-pink-700 mx-1" /> Celebrate your
                  relationship.
                </li>
              </ul>
              <button className="bg-[#eee8e1] text-pink-800 font-semibold py-3 px-6 rounded-full shadow-lg mb-4">
                ORDER NOW
              </button>
              <p className="text-md text-pink-700">
                Special Offer: use code{" "}
                <span className="font-bold text-pink-700 md:text-lg">
                  LOVE50
                </span>
              </p>
              <p className="text-sm text-pink-500 md:text-lg">
                OFFER VALID TILL JANUARY 30TH
              </p>
            </div>
            <div className="col-span-2 flex justify-center">
              <img
                src={mobileImg}
                alt="Phone Mockup"
                className=" w-64 md:w-72"
              />
            </div>
          </div>
        </div>

        <div className=" p-4 mt-4">
          <div className="grid grid-cols-1 md:grid-cols-6 gap-7 items-center">
            <div className="col-span-2 flex justify-center">
              <img
                src={img8}
                alt="Bonus Offer"
                className="rounded-lg shadow-lg  bg-contain"
              />
            </div>
            <div className="col-span-4 text-left text-gray-800">
              <h2 className="text-xl md:text-4xl font-bold mb-3 text-yellow-600">
                Lucky You! We have a{" "}
                <span className="text-yellow-600 text-2xl">BONUS OFFER</span>{" "}
                for you!
              </h2>
              <p className="text-pink-500 mb-3 text-sm md:text-lg">
                We’re making your moments extra special with <br />
                <span className="">PERSONALIZED IMAGE VIDEO SONG.</span>
              </p>
              <p className="text-pink-500 mb-3 text-sm md:text-lg">
                Upload a picture with your partner, and we’ll create an image
                video featuring one of your personalized songs!
              </p>
              <p className="text-pink-500  text-sm md:text-lg">
                Hurry!
                <br /> Limited Time Only! Offer valid till 5th Feb!
              </p>
            </div>
          </div>
        </div>
        <div className=" py-8 px-6">
          {/* Header Section */}
          <div className="bg-[#f39299] text-center py-6 rounded-md">
            <h2 className="text-2xl md:text-4xl font-bold text-white">
              To Love, Laughter & Happily Ever Afters!
            </h2>
            <p className="text-white mt-4">
              Imagine the look on their face when they hear their name in a
              beautiful melody. It’s more than just a GIFT—it’s a deep,
              emotional connection.
            </p>
            <p className="text-white font-bold mt-4">
              Don’t Wait—Limited Time Offer!
            </p>
          </div>

          {/* Special Offer Section */}
          <div className="text-center mt-8">
            <div className="bg-[#fdf5e6] inline-block py-4 px-6 rounded-full shadow-md">
              <p className="text-pink-600 font-bold">
                Special Offer: use code LOVE50
              </p>
            </div>
            <p className="text-pink-500 text-sm mt-4 font-Open Sans">
              OFFER VALID TILL 5TH FEBRUARY
            </p>
          </div>

          {/* Order Now Button */}
          <div className="text-center mt-8 ">
            <button className="bg-[#f39299] w-full text-white py-3 px-12 rounded-md font-bold hover:bg-pink-700 transition">
              ORDER NOW
            </button>
          </div>

          {/* Brand Partners Section */}
          <div className="mt-8 flex justify-end items-center">
            <div className="flex gap-6 items-center">
              <p className="text-gray-500 text-sm font-semibold text-right">
                BRAND PARTNERS
              </p>
              <img src={fnpImg} alt="FNP Logo" className="h-10" />
              <img src={tripImg} alt="EaseMyTrip Logo" className="h-10" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ValentinePage;
