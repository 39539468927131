import React from 'react';

import { FaBirthdayCake } from "react-icons/fa";
import { MdCelebration } from "react-icons/md";
import { GiLovers } from "react-icons/gi";
import { GrUserFemale } from "react-icons/gr";
import { FaHeart } from "react-icons/fa6";
import { LuHeartHandshake } from "react-icons/lu";
import { GiRose } from "react-icons/gi";
import { IoIosMan } from "react-icons/io";
import { ImHappy2 } from "react-icons/im";

const categories = [
    { name: "Birthdays", icon: <FaBirthdayCake aria-label="Birthdays icon" />, comingSoon: false },
    { name: "Anniversaries", icon: <GiLovers aria-label="Anniversaries icon" />, comingSoon: false },
    { name: "Someone you Love", icon: <FaHeart aria-label="Your Loved One icon" />, comingSoon: false },
    { name: "Mother", icon: <GrUserFemale aria-label="Mother's Day icon" />, comingSoon: false },
    { name: "Father", icon: <IoIosMan aria-label="Father's Day icon" />, comingSoon: false },
    { name: "Celebrations", icon: <MdCelebration aria-label="Celebrations icon" />, comingSoon: true },
    { name: "Weddings", icon: <GiLovers aria-label="Weddings icon" />, comingSoon: true },
    { name: "Proposals", icon: <GiRose aria-label="Proposals icon" />, comingSoon: true },
    { name: "Say Thanks", icon: <LuHeartHandshake aria-label="Say Thanks icon" />, comingSoon: true },
    { name: "Just For Fun", icon: <ImHappy2 aria-label="Just For Fun icon" />, comingSoon: true },
];

function SongType() {
    return (
        <div className="section-title">
            <h2 className="section-title-text">Create Songs for</h2>
            <div className="flex flex-col items-center my-8">
                <div className="grid grid-cols-2 md:grid-cols-5 gap-8">
                    {categories.map((category) => (
                        <div
                            key={category.name}
                            className="flex flex-col items-center transition"
                        >
                            <div className="text-2xl mb-2">{category.icon}</div>
                            <span className="text-lg font-bold">{category.name}</span>
                            {category.comingSoon && (
                                <p className="coming-soon-badge">Coming soon</p>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default SongType;
