import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import MusicImage from "../../assets/dashboard/music.webp";
import apiUrl from "../../services/api.jsx";
import { AiFillPauseCircle, AiFillPlayCircle } from "react-icons/ai";
import { FaPlusCircle } from "react-icons/fa";

const LibraryCollage = ({handlePlayPause1, playingVoiceId, addSongToPlayer}) => {
    const { collectionData, loading } = useSelector((state) => state.uploadAudio);


  return (
    <>
      {collectionData.map((voice) => (
        <div
          key={voice.id}
          className="shadow-lg  sm:mt-4 p-2 song mb-2 sm:mb-6 relativ"
        >
          <div className="flex gap-4">
            <img
              src={MusicImage}
              alt={voice.title}
              className="w-18 h-10 object-cover rounded-lg cursor-pointer"
            />
            <div className="flex flex-col flex-grow">
              <h2 className="text-sm mt-2 font-semibold text-white heading-text-mobile">
                {voice.title === "" ? "Song - Title" : voice.title}
              </h2>
            </div>
            <div className="flex items-center justify-between gap-3 relative">
              <button
                onClick={() => {
                    handlePlayPause1(voice);
                }}
                className="text-blue-500 mx-2"
              >
                {playingVoiceId === voice.id ? (
                  <AiFillPauseCircle className="text-3xl fill-white" />
                ) : (
                  <AiFillPlayCircle className="text-3xl fill-white" />
                )}
              </button>
              <button
                onClick={() => addSongToPlayer(voice)}
                className="text-green-500 mx-2"
              >
                <FaPlusCircle className="text-3xl fill-white" />
              </button>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default LibraryCollage;
