import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import apiUrl from "../../services/api";
import SecureLS from "secure-ls";

const ls = new SecureLS();

// Async thunk for the API call
export const generateSlideshow = createAsyncThunk(
  "slideshow/generateSlideshow",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.setItem("token");
      const response = await axios.post(apiUrl.generateSlideShow, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  }
);

export const downloadVideo = createAsyncThunk(
  "slideshow/downloadVideo",
  async (payload, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(apiUrl.downloadSlideShow, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      return response.data; // Return the API response data
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
        "An error occurred while downloading the video."
      );
    }
  }
);

export const getAllVideo = createAsyncThunk(
  "slideshow/getAllVideo",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(apiUrl.generatedCollageVideo, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data; // Return the API response data
    } catch (error) {

      if (error.response?.status === 401) {
        // Handle 401 Unauthorized
        localStorage.clear();
        const navigate = useNavigate();

        const savedCredentials = ls.get("credentials");

        localStorage.clear();
        if (savedCredentials) {
          ls.set("credentials", savedCredentials);
        }
        navigate("/signin");

        return rejectWithValue("Unauthorized - Redirecting to login");
      }

      return rejectWithValue(
        error.response?.data?.message ||
        "An error occurred while fetching videos."
      );
    }
  }
);

export const deleteCollageVideo = createAsyncThunk(
  "slideshow/deleteCollageVideo",
  async (originalUrl, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        apiUrl.deleteCollageVideo,
        { original_url: originalUrl },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
        "An error occurred while deleting the video."
      );
    }
  }
);

const slideshowSlice = createSlice({
  name: "slideshow",
  initialState: {
    data: null,
    videoData: null,
    allVideos: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(generateSlideshow.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(generateSlideshow.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(generateSlideshow.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(downloadVideo.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(downloadVideo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.videoData = action.payload;
      })
      .addCase(downloadVideo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getAllVideo.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAllVideo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allVideos = action.payload;
      })
      .addCase(getAllVideo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteCollageVideo.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteCollageVideo.fulfilled, (state, action) => {
        state.isLoading = false;
        // Optionally update the state to reflect the video has been deleted
        state.allVideos = state.allVideos.collage_videos.filter(
          (video) => video.original_url !== action.payload.original_url
        );
      })
      .addCase(deleteCollageVideo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default slideshowSlice.reducer;
