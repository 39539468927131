import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import forgetImg from "../../assets/dashboard/forgetPassword.png";
import {
  forgotPassword,
  resetPassword,
} from "../../redux/ReduxSlices/forgotPasswordSlice";
import showNotification from "../../hooks/notificationHook";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [showPasswordInputs, setShowPasswordInputs] = useState(false);
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassowrd, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassowrd);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleEmailVerification = async () => {
    try {
      const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!pattern.test(email)) {
      showNotification("error","Enter a valid email ID.")
        return;
      }
      if (!email) {
        showNotification("error","Please enter your email address.")
        console.error("Email field is empty.",1);
        return;
      }
      const resultAction = await dispatch(forgotPassword({ email }));

      if (forgotPassword.fulfilled.match(resultAction)) {


        showNotification("success","Email verified successfully!")
        setShowPasswordInputs(true);
      } else {
        console.error("Failed to verify email:", resultAction.payload);
      }
    } catch (error) {

      showNotification("error",error)
      console.error("Error verifying email:", error);
    }
  };

  const passwordValidationRegex =
    /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+={}\[\]:;"'<>,.?/-]).+$/;

  const handleResetPassword = async () => {
    if (!otp || !newPassword || !confirmPassword) {
      showNotification("error","All fields are required.")
      console.error("Some fields are empty.");
      return; // Exit the function early
    }
    if (newPassword !== confirmPassword) {

      showNotification("error","Passwords do not match.")
      return;
    }
    if (!passwordValidationRegex.test(newPassword)) {

      showNotification("error", "Password must contain at least one number, one uppercase letter, and one special character.")
      return;
    }

    try {
      const resultAction = await dispatch(
        resetPassword({
          otp: parseInt(otp, 10),
          new_password: newPassword,
          confirm_password: confirmPassword,
        })
      );

      if (resetPassword.fulfilled.match(resultAction)) {

        showNotification("success","Password reset successful! You can now log in.")
        setTimeout(() => {
          navigate("/signin");
        }, 2000);
      } else {
        console.error("Failed to reset password:", resultAction.payload);
      }
    } catch (error) {
      console.error("Error resetting password:", error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default action for the Enter key
      handleEmailVerification(e);     // Pass the event object to `handleLogin`
    }
  };


  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 h-screen bg-black text-white mt-10">
      <div className="flex flex-col justify-center items-center p-5 sm:p-5 lg:mb-0">
        <h1 className="text-2xl sm:text-4xl font-bold text-center">
          Quickly Create, Customize, and Translate{" "}
          <span className="text-teal-400">Audio in Minutes</span>
        </h1>
        <div className="mt-8 sm:mt-10 w-full max-w-2xl flex flex-col md:flex-row items-center max-md:gap-4 gap-8">
          <img
            src={forgetImg}
            alt="Video Preview"
            className="rounded-lg shadow-lg w-full  max-md:h-[200px] h-[350px] sm:h-[350px] object-cover opacity-50"
          />
        </div>
      </div>

      <div className="flex justify-center items-center p-5 sm:p-5 relative ">
        <div className="absolute top-8 mt-5 left-20 w-[100px] h-[100px] sm:w-[250px] sm:h-[250px] md:w-[200px] md:h-[200px] lg:w-[300px] lg:h-[300px] xl:w-[250px] xl:h-[250px] custom-button rounded-full hidden sm:block md:hidden lg:block"></div>
        <div className="max-w-3xl max-md:p-0 p-6 sm:p-6 min-h-[400px] max-md:mb-12 flex  bg-black bg-opacity-45 rounded-lg shadow-xl border max-md:border-black border-teal-400 z-50">
          <div>
            <h2 className="text-2xl sm:text-3xl font-semibold mb-2 text-center text-teal-300">
              Forgot Password?
            </h2>

            {!showPasswordInputs ? (
              <>
                <div className="p-2 sm:p-4">
                  <p className="text-sm sm:text-base text-white mb-4 text-center">
                    Please enter your email
                  </p>
                  <input
                    type="email"
                    placeholder="example@mail.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={handleKeyDown}
                    required
                    className="w-full px-4 py-2 mb-4 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-400"
                  />
                  <button
                    className="w-full py-2 custom-button text-white rounded-lg hover:bg-teal-600 text-center"
                    onClick={handleEmailVerification}

                  >
                    Submit
                  </button>
                </div>
              </>
            ) : (
              <div className="p-4 sm:p-8">
                <p className="text-sm text-white mb-4">Enter OTP</p>
                <input
                  type="text"
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  className="w-full px-4 py-2 mb-4 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-400"
                />

                <p className="text-sm text-white mb-4">New Password</p>
                <div className="relative mb-4">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    onCopy={(e) => {
                      e.preventDefault();
                      showNotification("info","Copying is not allowed in this field.")
                    }}
                    onPaste={(e) => {
                      e.preventDefault();
                      showNotification("info","Pasting is not allowed in this field.")
                    }}
                    className="w-full px-4 py-2 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-400"
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute inset-y-0 right-3 flex items-center text-gray-400"
                  >
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </button>
                </div>

                <p className="text-sm text-white mb-4">Confirm Password</p>
                <div className="relative mb-4">
                  <input
                    type={showConfirmPassowrd ? "text" : "password"}
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onCopy={(e) => {
                      e.preventDefault();
                      showNotification("info","Copying is not allowed in this field.")
                    }}
                    onPaste={(e) => {
                      e.preventDefault();
                      showNotification("info","Pasting is not allowed in this field.")
                    }}
                    className="w-full px-4 py-2 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-400"
                  />
                  <button
                    type="button"
                    onClick={toggleConfirmPasswordVisibility}
                    className="absolute inset-y-0 right-3 flex items-center text-gray-400"
                  >
                    {showConfirmPassowrd ? <FaEye /> : <FaEyeSlash />}
                  </button>
                </div>

                <button
                  onClick={handleResetPassword}
                  className="w-full py-2 custom-button text-white rounded-lg hover:bg-teal-600 text-center"
                >
                  Reset Password
                </button>
              </div>
            )}

            <div className="text-center mt-4 text-gray-400 text-xs sm:text-sm  mb-4 px-4 sm:px-8">
              <p>
                Don’t have an account?{" "}
                <a
                  href="/signup"
                  className="text-teal-400 font-bold hover:underline"
                >
                  Signup
                </a>
              </p>
            </div>
          </div>
        </div>
        {/* Circle at the bottom-right */}
        <div
          className="absolute z-10 bg-teal-400 custom-button rounded-full
  hidden sm:block md:hidden lg:block
  bottom-10 right-40 w-[100px] h-[100px]
  sm:w-[120px] sm:h-[120px]
  md:w-[150px] md:h-[150px]
  lg:w-[180px] lg:h-[180px]
  2xl:bottom-0 2xl:right-38"
        ></div>{" "}
      </div>

    </div>
  );
}

export default ForgotPassword;
