import { GoogleLogin } from "@react-oauth/google";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";


import { FaEye, FaEyeSlash } from "react-icons/fa";
import OtpImg from "../../assets/dashboard/Otp image.png";
import logoVideo from "../../assets/gif/imas.gif";
import {
  googleLogin,
  login,
  resendOtp,
  signUpUser,
  verifyOtp,
} from "../../redux/ReduxSlices/UserSlice";
import { countryData } from "../../utils/countryCode";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import showNotification from "../../hooks/notificationHook";

const SignUpPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const [otp, setOtp] = useState("");

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [captchaText, setCaptchaText] = useState("");
  const [captchaInput, setCaptchaInput] = useState("");
  const [captchaValid, setCaptchaValid] = useState(false);

  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const [tooltip, setTooltip] = useState({
    show: false,
    message: "",
    x: 0,
    y: 0,
  });
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    username: "",
    country_code: "",
    // mobile: "",
    password: "",
    confirm_password: "",
    registration_way: 3,
  });

  const handleCopyOrPaste = (e, message) => {
    e.preventDefault();
    const rect = e.target.getBoundingClientRect();
    setTooltip({
      show: true,
      message,
      x: rect.left + rect.width / 2,
      y: rect.top - 20,
    });
    setTimeout(() => setTooltip({ ...tooltip, show: false }), 2000); // Hide tooltip after 2 seconds
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Regex to allow only letters and spaces
    const namePattern = /^[A-Za-z]*$/;

    if (name === "first_name" || name === "last_name") {
      if (!namePattern.test(value)) {

        showNotification("error", "First name and last name  should contain only letters.")
        return; // Prevent invalid input
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleUsernameChange = (e) => {
    const { value } = e.target;

    const forbiddenChars = /[^a-zA-Z0-9_]/;

    if (forbiddenChars.test(value)) {

      showNotification("error", "Special symbols are not permitted.")
      return;
    }

    if (value.length > 25) {

      showNotification("error", "Username cannot be more than 25 characters long.")
      return;
    }

    const atCount = (value.match(/@/g) || []).length;
    const underscoreCount = (value.match(/_/g) || []).length;

    if (atCount > 1 || underscoreCount > 1) {

      showNotification("error", "Only one '@' and one '_' are allowed.")
      return;
    }

    setFormData({
      ...formData,
      username: value,
    });
  };

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,20}$/;
    return passwordRegex.test(password);
  };

  const handleCountryCodeChange = (e) => {
    setFormData({
      ...formData,
      country_code: e.target.value,
    });
  };

  const generateCaptcha = () => {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 6; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    setCaptchaText(result);
    setCaptchaInput("");
    setCaptchaValid(false);
  };

  useEffect(() => {
    generateCaptcha();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaValid) {

      showNotification("error", "Please validate the CAPTCHA before submitting.")
      return;
    }

    if (formData.password !== formData.confirm_password) {

      showNotification("error", "Passwords do not match!")
      return;
    }

    if (!validatePassword(formData.password)) {

      showNotification("error", "Password must contain at least 8 characters, one uppercase letter, one number, and one special character."
      )
      return;
    }
    dispatch(signUpUser(formData))
      .unwrap()
      .then(() => {

        showNotification("success", "User registered successfully!")
        setModalVisible(true);
        // navigate("/signin");
      })
      .catch((error) => {

        const errorMessage = error || "Signup failed!";


        showNotification("error", errorMessage || " sign up failed")
      });
  };

  const calculatePasswordStrength = (password) => {
    let strength = 0;

    if (password.length >= 8 && password.length === 20) strength += 1;
    if (/[A-Z]/.test(password)) strength += 1;
    if (/\d/.test(password)) strength += 1;
    if (/[!@#$%^&*]/.test(password)) strength += 1;

    return strength;
  };

  const getStrengthLabelAndColor = (strength) => {
    switch (strength) {
      case 1:
        return { label: "Weak", color: "bg-red-500" };
      case 2:
        return { label: "good", color: "bg-yellow-400" };
      case 3:
        return { label: "Excellent", color: "bg-green-400" };

      default:
        return { label: "", color: "bg-gray-300" };
    }
  };
  const strengthData = getStrengthLabelAndColor(
    calculatePasswordStrength(formData.password)
  );

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  // const handleVerifyOtp = () => {
  //   if (!otp) {
  //     toast.error("Please enter the OTP.");
  //     return;
  //   }

  //   // Dispatch OTP verification with email and OTP value
  //   dispatch(verifyOtp({ email: formData.email, otp }))
  //     .unwrap()
  //     .then(() => {
  //       toast.success("OTP Verified Successfully!");
  //       setModalVisible(false); // Close the OTP modal
  //       navigate("/signin"); // Redirect to sign-in page after verification
  //     })
  //     .catch((error) => {
  //       const errorMessage = error || "OTP verification failed!";
  //       toast.error(errorMessage);
  //     });
  // };

  const handleVerifyOtp = () => {
    if (!otp) {

      showNotification("error", "Please enter the OTP.")
      return;
    }

    // Dispatch OTP verification with email and OTP value
    dispatch(verifyOtp({ email: formData.email, otp }))
      .unwrap()
      .then((response) => {
        // Check if the response is successful

        if (response.success) {
          // Save the access token in localStorage
          const token = response.access_token;
          const eptToken = response.hmac.signature;
          const eptTime = response.hmac.timestamp;

          localStorage.setItem("token", token);
          localStorage.setItem("eptToken", eptToken);
          localStorage.setItem("eptTime", eptTime);
          dispatch(login());


          // Redirect to the dashboard or home page after verification
          navigate("/dashboard"); // Change this to your intended route
        } else {
          throw new Error(response.message || "OTP verification failed!");
        }
      })
      .catch((error) => {
        const errorMessage = error.message || "OTP verification failed!";

      });
  };

  const handleResendOtp = () => {

    dispatch(resendOtp(formData.email));
  };

  const handleLoginSuccess = (credentialResponse) => {

    const token = credentialResponse.credential; // Get the token from Google response


    dispatch(googleLogin(token))
      .unwrap()
      .then((response) => {
        navigate("/dashboard");
      })
      .catch((error) => {
        console.error("Google login failed:", error);
        // Handle errors appropriately
      });
  };

  const handleLoginFailure = () => {
    console.error("Google Login Failed");

    showNotification("error", "Google login failed. Please try again.")
  };

  return (
    <div className="w-full min-h-screen  text-gray-900 flex relative top-10 bg-black">
      <div className=" m-0   sm:rounded-lg flex justify-center flex-1 max-md:mb-8">
        <div className="flex-1  text-center hidden lg:flex justify-center">
          <div className=" w-3/4 bg-contain rounded flex items-center flex-col justify-center ">
            <motion.div
              initial={{ x: "-100%" }}
              animate={{ x: 0 }}
              transition={{ duration: 0.3 }}
              className="mb-6 "
            >
              <img
                src={logoVideo}
                alt="i made a song"
                className="w-full h-60"
              />
            </motion.div>
          </div>
        </div>
        <div className="lg:w-1/2 w-full flex items-center max-md:items-start max-md:mt-4 justify-center  min-h-screen bg-black relative">
          <motion.div
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            transition={{ duration: 0.3 }}
            className="flex flex-col items-center justify-center "
          >
            <div className="absolute top-2 right-10 w-[200px] h-[200px] sm:w-[250px] sm:h-[250px] md:w-[300px] md:h-[300px] lg:w-[200px] lg:h-[200px] lg:absolute lg:top-0 lg:left-50 xl:w-[300px] xl:h-[300px] custom-button rounded-full hidden sm:block md:hidden lg:block  round-shape"></div>
            <div className="p-5 bg-opacity-20 m-2 border-teal-300 rounded-lg shadow-2xl z-40 z-index-2  sm:border">
              <h1 className="text-3xl xl:text-3xl font-extrabold text-white text-center ">
                Sign Up
              </h1>
              <form onSubmit={handleSubmit} className="bg-opacity-10 ">
                <div className="flex-1 mt-4 max-md:mt-0 ">
                  <div className="flex flex-col items-center">
                    <div className="w-full">
                      <div className="w-full">
                        <div className="grid gap-3 md:grid-cols-2 mt-5">
                          <div className="w-full">
                            <input
                              required
                              type="text"
                              placeholder="First Name"
                              name="first_name"
                              value={formData.first_name}
                              onChange={handleInputChange}
                              className="px-6 py-3 mt-2 h-10  w-full bg-gray-800 font-medium rounded-lg text-white  border-gray-200 placeholder-gray-100 text-sm focus:outline-none focus:border-gray-400 focus:bg-"
                            />
                          </div>
                          <div>
                            <input
                              type="text"
                              required
                              placeholder="Last Name"
                              pattern="^[A-Za-z\s]+$"
                              name="last_name"
                              value={formData.last_name}
                              onChange={handleInputChange}
                              className="px-6 py-3 mt-2 h-10 w-full font-medium rounded-lg bg-gray-800 text-white border-gray-200 placeholder-gray-100 text-sm focus:outline-none focus:border-gray-400 focus:bg"
                            />
                          </div>
                        </div>
                        <input
                          required
                          className="w-full px-6 py-3  h-10 rounded-lg font-medium bg-gray-800  border-gray-200 text-white placeholder-gray-100 text-sm focus:outline-none focus:border-gray-400 focus:bg mt-5"
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          placeholder="Email"
                        />
                        <div>
                          <input
                            type="text"
                            required
                            placeholder="Username"
                            name="username"
                            value={formData.username}
                            onChange={handleUsernameChange}
                            className="w-full px-6 py-3 h-10 rounded-lg font-medium bg-gray-800  border-gray-200 text-white placeholder-gray-100 text-sm focus:outline-none focus:border-gray-400 focus:bg mt-5"
                          />
                        </div>

                        <div className="relative mt-4">
                          <input
                            className="w-full px-6 py-3 h-10 rounded-lg font-medium bg-gray-800  border-gray-200 text-white placeholder-gray-100 text-sm focus:outline-none focus:border-gray-400 focus:bg "
                            type={passwordVisible ? "text" : "password"}
                            required
                            name="password"
                            value={formData.password}
                            onChange={handleInputChange}
                            onCopy={(e) =>
                              handleCopyOrPaste(
                                e,
                                "Copying is not allowed in this field."
                              )
                            }
                            onPaste={(e) =>
                              handleCopyOrPaste(
                                e,
                                "Pasting is not allowed in this field."
                              )
                            }
                            placeholder="Password"
                          />
                          <button
                            type="button"
                            className="eye-button absolute inset-y-0 right-0 bottom-16 px-3 flex items-center text-gray-500"
                            onClick={() => setPasswordVisible(!passwordVisible)}
                          >
                            {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                          </button>
                          <div className="mt-2">
                            <div className="">
                              <div
                                className={`${strengthData.color} h-1 rounded-md transition-all duration-300`}
                                style={{
                                  width: `${(calculatePasswordStrength(
                                    formData.password
                                  ) /
                                    4) *
                                    100
                                    }%`,
                                }}
                              />
                            </div>
                            <p className="text-sm text-gray-600">
                              {strengthData.label}
                            </p>
                          </div>
                          <input
                            className="w-full px-6 py-3 h-10 rounded-lg font-medium bg-gray-800 text-white border-gray-200 placeholder-gray-100 text-sm focus:outline-none focus:border-gray-400 focus:bg mt-2"
                            type={confirmPasswordVisible ? "text" : "password"}
                            name="confirm_password"
                            value={formData.confirm_password}
                            onChange={handleInputChange}
                            onCopy={(e) =>
                              handleCopyOrPaste(
                                e,
                                "Copying is not allowed in this field."
                              )
                            }
                            onPaste={(e) =>
                              handleCopyOrPaste(
                                e,
                                "Pasting is not allowed in this field."
                              )
                            }
                            placeholder="Confirm Password"
                          />
                          <button
                            type="button"
                            className="eye-button absolute inset-y-0 right-0 top-16 px-3 flex items-center text-gray-500"
                            onClick={() =>
                              setConfirmPasswordVisible(!confirmPasswordVisible)
                            }
                          >
                            {confirmPasswordVisible ? (
                              <FaEyeSlash />
                            ) : (
                              <FaEye />
                            )}
                          </button>
                        </div>
                      </div>
                      <div className="flex items-center justify-center gap-6 mt-4 mb-4">
                        <div className="flex justify-between space-x-6">
                          <div
                            className="bg-gray-100 text-black px-3 rounded-lg font-mono text-2xl captch-style"
                            aria-hidden="true"
                          >
                            {captchaText}
                          </div>
                          <div className="flex justify-center">
                            <button
                              type="button"
                              className="text-white underline text-sm mx-1"
                              onClick={generateCaptcha}
                            >
                              Refresh
                            </button>
                          </div>
                        </div>
                        <div>
                          <input
                            type="text"
                            className="w-full px-4 py-2 text-base rounded-lg text-white bg-gray-800 bg-opacity-80 placeholder-white placeholder:text-xs"
                            placeholder="Enter CAPTCHA"
                            value={captchaInput}
                            onChange={(e) => {
                              setCaptchaInput(e.target.value);
                              setCaptchaValid(e.target.value === captchaText);
                            }}
                          />
                        </div>
                      </div>

                      <button
                        className={`w-full py-2 text-center text-lg font-bold rounded-lg transition-all ${!captchaValid
                          ? "custom-button cursor-not-allowed"
                          : "custom-button"
                          }`}
                        disabled={!captchaValid}
                      >
                        <span className="ml-3">Sign Up</span>
                      </button>
                    </div>
                    <span className="text-gray-400 mt-2 font-semibold">OR</span>
                    <div className="w-[50%] mt-3">
                      <GoogleLogin
                        onSuccess={handleLoginSuccess}
                        onError={handleLoginFailure}
                      />
                    </div>

                    <div className="leading px-2 py-4 text-center text-white font-medium">
                      <p className="items-center  text-base">
                        Already have an account?{" "}
                        <span
                          className="text-cyan-400 hover:underline pl-2"
                          onClick={() => navigate("/signin")}
                        >
                          Sign In
                        </span>
                      </p>
                    </div>

                    <p className="text-base mt-2 text-center text-white">
                      For sign-up, payment details or card information are not
                      required.
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </motion.div>
          {/* <div className="absolute bottom-10 right-10 z-10  w-[150px] h-[150px] bg-teal-400 custom-button rounded-full hidden lg:hidden xl:block"></div> */}
          <div
            className="absolute  bg-teal-400 custom-button rounded-full
  hidden sm:block md:hidden lg:block
  bottom-10 right-10 w-[100px] h-[100px]
  sm:w-[120px] sm:h-[120px]
  md:w-[150px] md:h-[150px]
  lg:w-[180px] lg:h-[180px]
  2xl:bottom-0 2xl:right-38 round-shape-bottom"
          ></div>
        </div>
      </div>

      {modalVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96 flex flex-col items-center">
            {/* OTP Image */}
            <img
              src={OtpImg} // replace with your actual image path
              alt="OTP Verification"
              className="w-36 h-36 mb-4 object-cover"
            />

            <h2 className="text-2xl font-semibold mb-4">OTP Verification</h2>
            <p className="text-gray-700 mb-4 text-center">
              Enter the OTP sent to your registered email id.
            </p>

            <input
              type="text"
              maxLength="6"
              value={otp}
              onChange={handleOtpChange}
              placeholder="Enter OTP"
              className="w-full px-6 py-3 mb-4 rounded-lg bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
            />

            <div className="">
              <button
                class="text-blue-500 font-semibold hover:text-blue-700 mb-2 hover:underline focus:outline-none"
                onClick={handleResendOtp}
              >
                Resend OTP
              </button>
            </div>
            <button
              onClick={handleVerifyOtp}
              className="w-full py-2 rounded-lg upgrade-btn"
            >
              Verify OTP
            </button>

            <button
              onClick={() => setModalVisible(false)}
              className="w-full py-2 mt-3 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition-all duration-200"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      {tooltip.show && (
        <div
          className="absolute z-50 bg-black text-red-500 text-xs px-3 py-2 rounded-lg shadow-lg transition-opacity duration-300"
          style={{
            top: tooltip.y,
            left: tooltip.x,
            transform: "translate(-50%, -100%)", // Center horizontally and position above
          }}
        >
          {tooltip.message}
        </div>
      )}
    </div>
  );
};

export default SignUpPage;

{/* <div className="w-full">
  <div className="flex">
    <select
      required
      value={formData.country_code}
      onChange={handleCountryCodeChange}
      className="px-6 py-3 mt-5 h-12 w-1/3 font-medium rounded-lg bg-gray-800 text-white border-gray-200 placeholder-gray-100 text-sm focus:outline-none focus:border-gray-400 focus:bg"
    >
      <option value="" disabled>
        Select country code
      </option>
      {countryData.map((country, index) => (
        <option key={index} value={country.code}>
          {country.name} ({country.code})
        </option>
      ))}
    </select>
    <input
      type="number"
      required
      name="mobile"
      placeholder="Mobile No"
      value={formData.mobile}
      onChange={handleInputChange}
      className="w-2/3 px-6 py-3 mt-5 h-12 font-medium bg-gray-800 rounded-lg text-white border-gray-200  placeholder-gray-100 text-sm focus:outline-none focus:border-gray-400 focus:bg ml-5"
    />
  </div>
</div> */}