export const valentineData = [
  {
    id: 1,
    Category: "General",
    Name: "Vihhan",
    Language: "Hindi",
    Title: "My Love Vihhan",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/vihan-album/Dreams+of+Tomorrow.mp3",
    VideoURL: "https://imas-valentine.s3.eu-central-003.backblazeb2.com/videos/200_output_video+(1).mp4",
  },
  {
    id: 2,
    Category: "General",
    Name: "Vihhan",
    Language: "Hindi",
    Title: "Our Sunshine everywhere",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/vihan-album/Endless+Echoe.mp3",
  },
  {
    id: 3,
    Category: "General",
    Name: "Vihhan",
    Language: "Hindi",
    Title: "Vihaan,Makes me believe",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/vihan-album/Every+movement+dreams.mp3",
  },
  {
    id: 4,
    Category: "General",
    Name: "Vihhan",
    Language: "Hindi",
    Title: "Every movement dreams",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/vihan-album/Vihhan+valentine+new.mp3",
  },
  {
    id: 5,
    Category: "General",
    Name: "Vihhan",
    Language: "Hindi",
    Title: "Love is Melody",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/vihan-album/Moonlit+Serenade.mp3",
  },
  {
    id: 6,
    Category: "General",
    Name: "Vihhan",
    Language: "Hindi",
    Title: "Dreams of Tomorrow",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/vihan-album/My+Love.mp3",
  },
  {
    id: 7,
    Category: "General",
    Name: "Vihhan",
    Language: "Hindi",
    Title: "Whispers of Love",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/vihan-album/Our+Sunshine+everywhere.mp3",
  },
  {
    id: 8,
    Category: "General",
    Name: "Vihhan",
    Language: "Hindi",
    Title: "Endless Echoes",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/vihan-album/Starlight%2C+Forever+Yours.mp3",
  },
  {
    id: 9,
    Category: "General",
    Name: "Vihhan",
    Language: "Hindi",
    Title: "Moonlit Serenade",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/vihan-album/Vihaan%2CMakes+me+believe.mp3",
  },
  {
    id: 10,
    Category: "General",
    Name: "Vihhan",
    Language: "Hindi",
    Title: "Starlight, Forever Yours",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/vihan-album/Whispers+of+Love.mp3",
  },
  // Avani
  {
    id: 11,
    Category: "General",
    Name: "Avani",
    Language: "Hindi",
    Title: "Avani Banja Meri Valentines",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/avani-album/Avani%2C+Ban+Ja+Meri+Valentine.mp3",
    VideoURL: "https://imas-valentine.s3.eu-central-003.backblazeb2.com/videos/avni_output_video.mp4",
  },
  {
    id: 12,
    Category: "General",
    Name: "Avani",
    Language: "Hindi",
    Title: "Happy Valentines",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/avani-album/Avni+Valentine+Hindi+(1).mp3",
  },
  {
    id: 13,
    Category: "General",
    Name: "Avani",
    Language: "Hindi",
    Title: "Avani Tere Sath",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/avani-album/Avni+Valentine+Hindi.mp3",
  },
  {
    id: 14,
    Category: "General",
    Name: "Avani",
    Language: "Hindi",
    Title: "Endless Love",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/avani-album/Avni+velentine+2.mp3",
  },
  {
    id: 15,
    Category: "General",
    Name: "Avani",
    Language: "Hindi",
    Title: "Avani Meri Valentines",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/avani-album/Avni%2C+Mera+Valentine.mp3",
  },
  {
    id: 16,
    Category: "General",
    Name: "Avani",
    Language: "Hindi",
    Title: "Muskan Ho Tum",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/avani-album/Avni%2C+Teri+Muskaan.mp3",
  },
  {
    id: 17,
    Category: "General",
    Name: "Avani",
    Language: "Hindi",
    Title: "Tu Jo Mere Sath",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/avani-album/Avni%2C+Tu+Hi+Meri+Valentine.mp3",
  },
  {
    id: 18,
    Category: "General",
    Name: "Avani",
    Language: "Hindi",
    Title: "My love",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/avani-album/Avni%2C+Tu+Meri+Valentine.mp3",
  },
  {
    id: 19,
    Category: "General",
    Name: "Avani",
    Language: "Hindi",
    Title: "Avani Ke Naam",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/avani-album/_Avni+Ke+Naam+Ye+Valentine_.mp3",
  },
  {
    id: 20,
    Category: "General",
    Name: "Avani",
    Language: "Hindi",
    Title: "Tu Hi Hai Meri",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/avani-album/_Avni%2C+Tu+Hi+Valentine_.mp3",
  },
  // Priya
  {
    id: 21,
    Category: "General",
    Name: "Priya",
    Language: "Hindi",
    Title: "Priya My Heartbeat",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/priya-album/Preeya+My+Heartbeat.mp3",
    VideoURL: "https://imas-valentine.s3.eu-central-003.backblazeb2.com/videos/priya_output_video+(1).mp4",
  },
  {
    id: 22,
    Category: "General",
    Name: "Priya",
    Language: "Hindi",
    Title: "Priya My love",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/priya-album/Preeya+My+Only+Love.mp3",
  },
  {
    id: 23,
    Category: "General",
    Name: "Priya",
    Language: "Hindi",
    Title: "You Are Forever",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/priya-album/Preeya+You+are+My+Forever.mp3",
  },
  {
    id: 24,
    Category: "General",
    Name: "Priya",
    Language: "Hindi",
    Title: "You Are My World",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/priya-album/Preeya+You+are+My+World.mp3",
  },
  {
    id: 25,
    Category: "General",
    Name: "Priya",
    Language: "Hindi",
    Title: "For Priya, With Love",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/priya-album/_Happy+Valentine%E2%80%99s+Day%2C+Priya_++(1).mp3",
  },
  {
    id: 26,
    Category: "General",
    Name: "Priya",
    Language: "Hindi",
    Title: "Priya's Serenade",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/priya-album/Priya+my+love.mp3",
  },
  {
    id: 27,
    Category: "General",
    Name: "Priya",
    Language: "Hindi",
    Title: "Priya, In Every Beat",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/priya-album/Yours+Priya+by+Mrudula.mp3",
  },
  {
    id: 28,
    Category: "General",
    Name: "Priya",
    Language: "Hindi",
    Title: "Heartbeats of Priya",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/priya-album/oh+priya+you+are+my+shining+star.mp3",
  },
  {
    id: 29,
    Category: "General",
    Name: "Priya",
    Language: "Hindi",
    Title: "Priya, In Your Eyes",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/priya-album/priya+valentine+(1).mp3",
  },
  {
    id: 30,
    Category: "General",
    Name: "Priya",
    Language: "Hindi",
    Title: "In the Arms of Priya",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/priya-album/priya+you+are+light+of+my+sky.mp3",
  },
  // Arjun
  {
    id: 31,
    Category: "General",
    Name: "Arjun",
    Language: "Hindi",
    Title: "Arjun Meri Dhadkan",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/arjun-album/arjun+valentine+(1).mp3",
    VideoURL: "https://imas-valentine.s3.eu-central-003.backblazeb2.com/videos/arjun_output_video+(1).mp4",
  },
  {
    id: 32,
    Category: "General",
    Name: "Arjun",
    Language: "Hindi",
    Title: "Tujhse Juda Na Ho Paaye",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/arjun-album/_Arjun%2C+Tujhse+Juda+Na+Ho+Paaye_.mp3",
  },
  {
    id: 33,
    Category: "General",
    Name: "Arjun",
    Language: "Hindi",
    Title: "For Arjun, With Love",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/arjun-album/_Mujh+Mein+Basa+Hai+Arjun+Mera_.mp3",
  },
  {
    id: 34,
    Category: "General",
    Name: "Arjun",
    Language: "Hindi",
    Title: "Arjun, My Valentine",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/arjun-album/Arjun%2C+Mera+Pyaar.mp3",
  },
  {
    id: 35,
    Category: "General",
    Name: "Arjun",
    Language: "Hindi",
    Title: "In Your Arms",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/arjun-album/Arjun%2C+Meri+Duniya+Tu+Hi.mp3",
  },
  {
    id: 36,
    Category: "General",
    Name: "Arjun",
    Language: "Hindi",
    Title: "With Love, From Arjun",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/arjun-album/Arjun%2C+Tera+Pyaar+Meri+Dhadkan.mp3",
  },
  {
    id: 37,
    Category: "General",
    Name: "Arjun",
    Language: "Hindi",
    Title: "In the Eyes of Arjun",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/arjun-album/Arjun%2C+Tera+Saath+Chahiye.mp3",
  },
  {
    id: 38,
    Category: "General",
    Name: "Arjun",
    Language: "Hindi",
    Title: "Arjun, Love’s Serenade",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/arjun-album/Barsaat+Aur+Arjun+(1).mp3",
  },
  {
    id: 39,
    Category: "General",
    Name: "Arjun",
    Language: "Hindi",
    Title: "A Heartbeat Away",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/arjun-album/Arjun+Ke+Liye%2C+Pyar+Bhara+Din1.mp3",
  },
  {
    id: 40,
    Category: "General",
    Name: "Arjun",
    Language: "Hindi",
    Title: "In Every Moment",
    AudioURL:
      "https://imas-valentine.s3.eu-central-003.backblazeb2.com/songs/arjun-album/arjun%2Bvalentine%2Bsong.mp3",
  },
];

export const valentineUser = [
  {
    id: 202401,
    name: "Arjun",
  },
  {
    id: 202402,
    name: "Priya",
  },
  {
    id: 202403,
    name: "Avani",
  },
  {
    id: 202404,
    name: "Vihhan",
  },
];
