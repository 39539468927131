import React, { useEffect, useState } from "react";
import CutBannerCouple from "../../assets/viral_images/couple_img.png";
import banner2 from "../../assets/viral_images/For the ONE.. TXT BANNER.png";
import genersIcon from "../../assets/viral_images/GENERS.png";
import Gift from "../../assets/viral_images/GIFT.png";
import langIcon from "../../assets/viral_images/LANGUAGE.png";
import occasionIcon from "../../assets/viral_images/OCCASION.png";
import reviews from "../../assets/viral_images/REVIEW.png";
import reviews1 from "../../assets/viral_images/review1.jpg";
import reviews2 from "../../assets/viral_images/review2.jpg";
import reviews3 from "../../assets/viral_images/review3.jpg";
import reviews4 from "../../assets/viral_images/review4.jpg";
import reviews5 from "../../assets/viral_images/review5.jpg";
import signupIcon from "../../assets/viral_images/SIGN UP.png";
import SlideShow from "../../assets/viral_images/SINGING & SLIDESHOW.png";
import TopBanner from "../../assets/viral_images/top_banner.png";

import { useNavigate } from "react-router-dom";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const ParentsPage = () => {
  const navigate = useNavigate();

  const [activeVideo, setActiveVideo] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const token = localStorage.getItem("token");

  const handlePlay = (videoId) => {
    setActiveVideo(videoId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setActiveVideo(null);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleViralPageNavigation = () => {

    const userAgent = navigator.userAgent || navigator.vendor;
    const isInAppBrowser = userAgent.includes("Instagram") || userAgent.includes("FBAN") || userAgent.includes("FBAV");

    // Check if the user is on Instagram's or Facebook's in-app browser
    if (isInAppBrowser) {
      window.open("https://imadeasong.com/signupI", "_blank");
    } else {
      // Fallback for non-Instagram and non-Facebook browsers
      if (token) {
        navigate("/dashboard");
      } else {
        setTimeout(() => {
          navigate("/signup");
        }, 1000);
      }
    }
  }

  return (
    <>
      <div>
        <div className="max-md:mt-2 bg-black" >
          <section
            className="bg-cover bg-center text-white flex items-center lg:px-32 "
            style={{
              backgroundImage:
                window.innerWidth >= 768 ? `url(${TopBanner})` : "none",
            }}
          >
            <div
              className="mx-auto h-full mt-12 flex items-center  max-md:items-start justify-end md:mt-20 md:mb-[16rem] md:"
            >
              <div className="lg:w-1/2 text-center   max-md:text-start lg:text-left pl-44 max-md:pl-0">
                <h2 className="text-3xl md:text-4xl font-bold pl-4">
                  This{""}
                  <span className="text-[#A0E37C] font-bold pl-2">NEW YEAR</span>
                </h2>
                <h3 className="text-2xl md:text-3xl font-medium pl-4 text-[#A1E47D] max-md:mb-2">
                  Express Love & Gratitude
                </h3>
                <h3 className="text-2xl md:text-3xl font-medium mt-[-10px] pl-4 max-md:mb-2">
                  With A Song to Your
                </h3>
                <h1 className="text-4xl md:text-6xl text-[#A1E47D] font-bold mt-[-10px] pl-4">
                  Parents!
                </h1>
                <p className="text-base md:text-xl mt-4 pl-4 text-gray-300">
                  Your parents have always been your biggest cheerleaders, guiding
                  you through life's ups and downs. Now, it's time to show them
                  how much they truly mean to you in the most heartfelt way
                  <br />- with a personalized song made just for them!
                </p>
                <button
                className="parent-btn-bg mt-6 text-black font-semibold py-1 px-4 rounded-full  ml-4  hover:bg-[#2f7b4d] border-gray-700"
                // className="mt-6  px-4 py-2 max-md:py-0 max-md:px-2 text-sm bg-[#A0E27E] text-black rounded-full border-none ml-4"
                onClick={handleViralPageNavigation}>
                  Create for Free!
                </button>
                <div id="top-section">
                  <img
                    src={CutBannerCouple}
                    alt="top banner"
                    className="mt-8 w-full md:hidden"
                  />
                </div>
              </div>
            </div>
          </section>

          {/* <section className="relative h-[70vh] bg-black text-white flex items-center">
          <img
            src={TopBanner}
            alt="Parents"
            className="absolute top-0 left-0 w-full h-full "
          />
          <div className=" w-full max-w-lg mx-auto md:mx-0 md:ml-auto p-4 md:p-8 bg-opacity-80 ">
            <div className="text-left space-y-4 absolute left-[50%]  bottom-[30%]">
              <h1 className="text-sm sm:text-xl md:text-2xl lg:text-3xl font-bold">
                This <span className="text-green-400 text-sm ">NEW YEAR</span>
                <br />
                Express Love & Gratitude<br /> With A Song to Your{" "}
                <br />
                <span className="text-green-400 font-bold text-4xl">Parents!</span>
              </h1>
              <p className="text-xs sm:text-base md:text-lg lg:text-lg">
                Your parents have always been your biggest cheerleaders,<br/> guiding you
                through life's ups and downs. Now, it's time to show <br/>them how much they
                truly mean to you in the most heartfelt way<br/> - with a personalized song
                made just for them!
              </p>
              <button
                className="parent-btn-bg text-black font-bold py-2 px-6 rounded-full hover:bg-[#2f7b4d] border-gray-700"
                onClick={() => navigate("/signup")}
              >
                Create for Free!
              </button>
            </div>
          </div>
        </section> */}

          <section className="bg-black px-3 text-white py-0">
            <h2 className="text-center text-4xl mt-10 mb-10 font-bold max-md:mb-1 max-md:mt-2  max-md:text-sm">
              Choose from 2 Unique Ways to Make it PERSONAL!
            </h2>
            <div className="grid grid-cols-2 md:grid-cols-2 gap-8 max-w-4xl mx-auto">
              {/* Option 1 */}
              <div className="rounded-lg p-3 text-start">
                <div className="relative">
                  {activeVideo !== "video1" ? (
                    <>
                      {/* Thumbnail Image */}
                      <img
                        src="https://imas-ai-home.s3.eu-central-003.backblazeb2.com/videssss/thank_you_mom_thumbnail.png"
                        alt="Face Sing"
                        className="w-full rounded-lg mb-4 max-md:h-44 h-56"
                      />
                      {/* Transparent Play Button */}
                      <button
                        className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-lg"
                        onClick={() => handlePlay("video1")}
                        style={{ top: 0, left: 0, right: 0, bottom: 0 }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-16 w-16 text-white"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M14.752 11.168l-6.586-3.486A1 1 0 007 8.506v6.988a1 1 0 001.166.992l6.586-3.486a1 1 0 000-1.832z"
                          />
                        </svg>
                      </button>
                    </>
                  ) : (
                    <video
                      // src="https://imas-ai-home.s3.eu-central-003.backblazeb2.com/videssss/thank_you_mother.mp4"
                      controls
                      autoPlay
                      className="w-full rounded-lg h-36 mb-4"
                      controlsList="nodownload noremoteplayback"
                      disablePictureInPicture
                      onPause={() => setActiveVideo(null)}
                    />
                  )}
                </div>

                <h3 className="max-md:text-sm text-3xl  text-center bg-gradient-to-br from-[#16c17f] to-[#F6FBA2] bg-clip-text text-transparent leading-tight">
                  Create a Song, Upload a Pic & Watch Your Face Sing
                </h3>
                <p className="mt-3 max-md:text-sm text-2xl text-center">
                  Watch as your selfie comes to life, lip-syncing the song lyrics
                  as if you're singing it yourself.
                </p>
              </div>

              {/* Option 2 */}
              <div className="rounded-lg p-3 text-start">
                <div className="relative">
                  {activeVideo !== "video2" ? (
                    <>
                      {/* Thumbnail Image */}
                      <img
                        src="https://imas-ai-home.s3.eu-central-003.backblazeb2.com/videssss/parent_collage_thumbnail.png"
                        alt="Collage family Sing"
                        className="w-full rounded-lg mb-4 max-md:h-44 h-56"
                      />
                      {/* Transparent Play Button */}
                      <button
                        className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-lg"
                        onClick={() => handlePlay("video2")}
                        style={{ top: 0, left: 0, right: 0, bottom: 0 }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-16 w-16 text-white"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M14.752 11.168l-6.586-3.486A1 1 0 007 8.506v6.988a1 1 0 001.166.992l6.586-3.486a1 1 0 000-1.832z"
                          />
                        </svg>
                      </button>
                    </>
                  ) : (
                    <video
                      // src="https://imas-ai-home.s3.eu-central-003.backblazeb2.com/videssss/WhatsApp+Video+2024-12-21+at+12.03.40+PM.mp4"
                      controls
                      autoPlay
                      className="w-full rounded-lg h-36 mb-4"
                      controlsList="nodownload noremoteplayback"
                      disablePictureInPicture
                      onPause={() => setActiveVideo(null)}
                    />
                  )}
                </div>

                <h3 className="max-md:text-sm text-3xl text-center bg-gradient-to-br from-[#16c17f] to-[#F6FBA2] bg-clip-text text-transparent leading-tight">
                  Create a Stunning Slideshow of Memories
                </h3>
                <p className="max-md:text-sm text-2xl text-center mt-6">
                  Cherish old family photos with a beautiful, personalized song to
                  create a touching visual experience.
                </p>
              </div>
            </div>
          </section>

          <section className="bg-black text-white py-2 px-5">
            <h2 className="text-center max-md:text-xl font-semibold text-4xl mt-5 max-md:mt-0 mb-8">How It Works</h2>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 gap-6 max-w-5xl mx-auto">
              {/* Card 1 */}
              <div className="bg-black border-l border-r border-b border-green-500 rounded-lg p-3 flex flex-col items-start text-center">
                <img src={signupIcon} alt="Sign Up" className="w-8 h-8 mb-3" />
                <h3 className="font-semibold text-start max-md:text-xs text-xl sm:text-m md:text-lg">
                  Sign Up for Free
                </h3>
              </div>

              {/* Card 2 */}
              <div className="bg-black border-l border-r border-b border-green-500 rounded-lg p-3 flex flex-col items-start text-center">
                <img src={occasionIcon} alt="Occasion" className="w-8 h-8 mb-3" />
                <h3 className="font-semibold text-start max-md:text-xs text-xl sm:text-m md:text-lg">
                  Tell us about the Occasion
                </h3>
              </div>

              {/* Card 3 */}
              <div className="bg-black border-l border-r border-b border-green-500 rounded-lg p-3 flex flex-col items-start text-center">
                <img src={langIcon} alt="Language" className="w-8 h-8 mb-3" />
                <h3 className="font-semibold text-start max-md:text-xs text-xl sm:text-m md:text-lg">
                  Choose a Preferable Language
                </h3>
              </div>

              {/* Card 4 */}
              <div className="bg-black border-l border-r border-b border-green-500 rounded-lg p-3 flex flex-col items-start text-center">
                <img src={genersIcon} alt="Genres" className="w-8 h-8 mb-2" />
                <h3 className="font-semibold text-start max-md:text-xs text-xl sm:text-m md:text-lg">
                  Create a Custom Song
                </h3>
              </div>

              {/* Card 5 */}
              <div className="bg-black border-l border-r border-b border-green-500 rounded-lg p-3 flex flex-col items-start text-center">
                <img src={SlideShow} alt="Mood" className="w-8 h-8 mb-3" />
                <h3 className="font-semibold text-start max-md:text-xs text-xl sm:text-m md:text-lg">
                  Choose between Singing Photo or Photo Slideshow
                </h3>
              </div>

              {/* Card 6 */}
              <div className="bg-black border-l border-r border-b border-green-500 rounded-lg p-3 flex flex-col items-start text-center">
                <img src={Gift} alt="Voice" className="w-8 h-8 mb-3" />
                <h3 className="font-semibold text-start max-md:text-xs text-xl sm:text-m md:text-lg">
                  Create the most Memorable Gift
                </h3>
              </div>
            </div>

            <div className="text-center mt-8">
              <button
                className="parent-btn-bg text-black font-bold py-1 px-6 rounded-full hover:bg-[#2f7b4d] border-gray-700"
                onClick={handleViralPageNavigation}
              >
                Start Now for FREE!
              </button>
            </div>
          </section>

          <section className="relative bg-black text-white max-md:mt-1 mt-8">
            <img
              src={banner2}
              alt="Banner"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 flex items-center my-5">
              <div className="ml-5 space-y-3 absolute left-2 sm:left-10 md:left-14 lg:left-16 2xl:left-48">
                <h1 className="text-base font-bold leading-snug sm:text-lg md:text-xl lg:text-2xl 2xl:text-3xl">
                  For the <span className="text-green-400">ONES</span> who've
                  <br />
                  given <span className="text-green-400">YOU</span> everything!
                </h1>
                <ul className="text-xs font-bold sm:text-sm md:text-base lg:text-lg 2xl:text-xl space-y-2">
                  <li>• A Gift Like No Other</li>
                  <li>• Relive Special Memories</li>
                  <li>• Tears of Joy Guaranteed</li>
                  <li>• No musical talent needed</li>
                </ul>
              </div>
            </div>
          </section>

          <section className="bg-black text-white py-2 px-4">
            <h2 className="text-center font-semibold max-md:text-xl text-3xl max-md:mb-4 mt-6 mb-6 max-md:mt-0">Latest Verified Reviews</h2>

            <div className="w-full mx-auto">
              <Swiper
                loop={true}
                spaceBetween={20}
                slidesPerView={1}
                breakpoints={{
                  768: { slidesPerView: 2 }, // 2 slides on medium screens
                  1024: { slidesPerView: 3 }, // 3 slides on large screens
                }}
                autoplay={{
                  delay: 2000, // Auto scroll every 2 seconds
                  disableOnInteraction: true, // Keeps autoplay even when interacting
                }}
                modules={[Autoplay]}
                className="pb-10"
              >
                {/* Reviews */}
                {[
                  {
                    image: reviews,
                    text: "I created a song for my parents, and seeing their faces when they heard it was priceless.",
                  },
                  {
                    image: reviews1,
                    text: "The slideshow made my parents cry. A heartfelt gift that touched their hearts.",
                  },
                  {
                    image: reviews2,
                    text: "A beautiful way to preserve memories and make them even more special.",
                  },
                  {
                    image: reviews3,
                    text: "An emotional experience that I'll cherish forever.",
                  },
                  {
                    image: reviews4,
                    text: "A unique and touching gift that left a lasting impression.",
                  },
                  {
                    image: reviews5,
                    text: "Absolutely loved it! This was a gift that truly spoke from the heart.",
                  },
                ].map((review, index) => (
                  <SwiperSlide key={index}>
                    <div className="flex items-center justify-center">
                      {/* Left blurred side image */}
                      <div className="hidden lg:block w-[10%] filter blur-sm opacity-50">
                        <img
                          src={reviews[(index - 1 + 6) % 6]?.image}
                          alt={`SideImage ${index}`}
                          className="w-full h-[150px] object-cover border border-gray-200 rounded-lg"
                        />
                      </div>
                      {/* Main image */}
                      <div className="w-[90%] mx-2 relative rounded-lg overflow-hidden max-md:h-[200px] h-[300px] ">
                        <img
                          src={review.image}
                          alt={`Review ${index + 1}`}
                          className="w-full h-full object-cover border border-teal-300"
                        />
                        {/* Overlay Content */}
                        <div className="absolute inset-x-0 bottom-0 bg-black bg-opacity-40 flex flex-col justify-end p-2 pb-2 rounded-b-lg">
                          <div className="flex items-center mb-2">
                            <span className="text-yellow-400 text-lg">★★★★★</span>
                          </div>
                          <p className="text-white text-sm">{review.text}</p>
                        </div>
                      </div>
                      {/* Right blurred side image */}
                      <div className="hidden lg:block w-[10%] filter blur-sm opacity-50">
                        <img
                          src={reviews[(index + 1) % 6]?.image}
                          alt={`Side Image ${index}`}
                          className="w-full h-[150px] object-cover border border-gray-200 rounded-lg"
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            <div className="text-center mt-8 mb-12">
              <h3 className="max-md:text-lg text-3xl font-semibold text-[#9fe37c]">
                Give your parents a gift they'll never forget!
              </h3>
              <p className="mt-2 max-md:text-sm text-xl leading-tight text-gray-300">
                A song that tells them exactly how much they mean to you.
              </p>
              <button
                className="mt-4 mb-4 parent-btn-bg text-black font-bold py-1 px-6 rounded-full hover:bg-[#2f7b4d] border border-gray-700"
                onClick={handleViralPageNavigation}
              >
                Sign Up Now — It's FREE!
              </button>
            </div>
          </section>
          {isModalOpen && (
            <div className="fixed inset-0 bg-black flex  items-center justify-center z-[1000]">
              <div className="relative w-full max-w-5xl">
                <button
                  className="absolute -top-24 right-4 text-white text-3xl p-4 hover:text-red-600"
                  onClick={closeModal}
                >
                  ✖
                </button>
                <video
                  src={
                    activeVideo === "video1"
                      ? "https://imas-ai-home.s3.eu-central-003.backblazeb2.com/videssss/thank_you_mother.mp4"
                      : "https://imas-ai-home.s3.eu-central-003.backblazeb2.com/videssss/happy_family_collage_video.mp4"
                  }
                  controls
                  autoPlay
                  className="w-full h-[70vh]"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ParentsPage;
