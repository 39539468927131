import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const BottomNavbar = () => {
    const navigate = useNavigate();
    const [isPopupVisible, setPopupVisible] = useState(false);
    const popupRef = useRef(null);

    const togglePopup = () => {
        setPopupVisible(!isPopupVisible);
    };

    const handleOptionClick = (path) => {
        setPopupVisible(false); // Close popup
        navigate(path); // Redirect to the specified path
    };

    // Close popup if user clicks outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setPopupVisible(false);
            }
        };

        if (isPopupVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isPopupVisible]);

    return (
        <motion.div
            initial={{ y: "100%" }}
            animate={{ y: 0 }}
            transition={{ duration: 0.3 }}
            className="fixed bottom-0 p-1.5 pb-4 left-0 w-full text-white shadow-md flex justify-around bottom-sheet">
            <button
                onClick={() => navigate('/dashboard', { state: { tab: 'Create Song' } })}
                className="flex flex-col items-center group"
            >
                <span className="material-icons mt-2 text-l text-gray-400 font-bold group-hover:text-teal-500 transition duration-200">
                    Song
                </span>
            </button>

            <button
                onClick={() => navigate('/dashboard', { state: { tab: 'Library' } })}
                className="flex flex-col items-center group "
            >
                <span className="material-icons mt-2 text-l text-gray-400 font-bold group-hover:text-teal-500  transition duration-200">
                    Library
                </span>
            </button>


            <div className="relative">
                <button
                    onClick={togglePopup}
                    className="flex flex-col items-center group"
                >
                    <span className="material-icons text-l font-bold mt-2 text-gray-400 group-hover:text-teal-500 transition duration-200">
                        Video
                    </span>
                </button>

                {/* Popup */}
                {isPopupVisible && (
                    <div
                        ref={popupRef}
                        className={`absolute bottom-12 bottom-option-model transition-all duration-600 ${isPopupVisible ? 'opacity-100 scale-100' : 'opacity-0 scale-95'
                            }`}
                    >
                        <button
                            onClick={() => {
                                togglePopup();
                                navigate('/dashboard', { state: { tab: 'Slideshow Video' } });
                            }}
                            className="block w-full text-black text-left px-3 py-2 hover:bg-gray-200 rounded-md bottom-option-model-bar"
                        >
                            Slideshow Video
                        </button>
                        <button
                            onClick={() => {
                                togglePopup();
                                navigate('/dashboard', { state: { tab: 'Singing Selfie' } });
                            }}
                            className="block w-full text-left text-black px-3 py-2 mt-1 hover:bg-gray-200 rounded-md bottom-option-model-bar"
                        >
                            Singing Selfie
                        </button>
                    </div>
                )}
            </div>

            <button
                onClick={() => navigate('/menu')}
                className="flex flex-col items-center group"
            >
                <span className="material-icons text-2xl text-gray-400 group-hover:text-teal-500 transition duration-200">...</span>
            </button>
        </motion.div>
    );
};

export default BottomNavbar;
