import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrl from "../../services/api";
import showNotification from "../../hooks/notificationHook";

// Async thunk for forgot password
export const forgotPassword = createAsyncThunk(
  "forgotPassword/emailVerification",
  async (payload, { rejectWithValue }) => {
    try {
      // Send POST request with Axios
      const response = await axios.post(apiUrl.forgotPassword, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });


      // Save token to local storage if present in the response
      if (response.data.token) {
        localStorage.setItem("forgotPasswordToken", response.data.token);
      }

      return response.data; // Return response data to handle in the reducer
    } catch (error) {

      showNotification("error",error.response?.data.detail)
      return rejectWithValue(
        error.response?.data || "Something went wrong. Please try again."
      );
    }
  }
);

export const resetPassword = createAsyncThunk(
  "forgotPassword/resetPassword",
  async (payload, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("forgotPasswordToken");
      if (!token) {
        throw new Error("No token found in local storage. Please restart the process.");
      }

      // Add the token to the payload
      const requestBody = {
        ...payload,
        token, // Add token to the payload
      };

      // Send POST request to reset password API
      const response = await axios.post(apiUrl.resetPassword, requestBody, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      return response.data; // Return response data to handle in the reducer
    } catch (error) {

      showNotification("error",error.response?.data.detail)

      return rejectWithValue(

        error.response?.data.detail || "Something went wrong. Please try again."
      );
    }
  }
);
// Slice for forgot password
const forgotPasswordSlice = createSlice({
  name: "forgotPassword",
  initialState: {
    loading: false,
    error: null,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.error = null;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload || "Failed to send email verification.";
      })
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.error = null;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload || "Failed to reset the password.";
      });
  },
});

export default forgotPasswordSlice.reducer;










