import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import HowToUsePlatform from "../../components/HowToUsePlatform";
import { fetchFreeTrialCount } from "../../redux/ReduxSlices/freeTrialSlice.jsx";
import CustomCenterLoader from "../../components/CustomCenterLoader.jsx";
import { getCollection } from "../../redux/ReduxSlices/uploadAudioSlice.jsx";
import CustomeAI from "../custom/CustomAI.jsx";
import GetAllVoice from "./GetAllVoice.jsx";

function SongGeneratedPage({ setLoader }) {
  const [showOpenAi, setShowOpenAi] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [prompt, setPrompt] = useState("");

  const [showLoader, setShowLoader] = useState(false);

  const modalRef = useRef(null);
  const [songs, setSongs] = useState([]);

  const { response } = useSelector((state) => state.openAi);
  const [songsArray, setSongsArray] = useState([]);

  // const [songsArray,setSongsArray] = useState(Array.isArray(response)
  //   ? response.filter((item) => item.audio).map((item) => item.audio)
  //   : [])
  useEffect(() => {
    setSongsArray(
      Array.isArray(response)
        ? response.filter((item) => item.audio).map((item) => item.audio)
        : []
    );
  }, [response]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCollection());
  }, [dispatch, songs, songsArray]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsModalOpen(false);
      }
    };

    if (isModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // Cleanup listener
    };
  }, [isModalOpen]);

  const handleOpenAIClose = () => {
    setShowOpenAi(false);
  };

  const handleGenerate = (generatedText) => {
    setPrompt(generatedText);
    setShowOpenAi(showOpenAi);
  };

  useEffect(() => {
    dispatch(fetchFreeTrialCount());
  }, [dispatch]);

  useEffect(() => {
    handleGenerate();
  }, [prompt]);
  return (
    <div className="flex flex-col lg:flex-row w-full min-h-screen createSong">
      <div className="w-full lg:w-[30%] p-4 shadow-md overflow-auto middle-component border-right customAi">
        <CustomeAI setLoader={setShowLoader} />
      </div>

      {showLoader && <CustomCenterLoader />}

      <div className="w-full lg:w-[70%]">
        <GetAllVoice songs={songsArray} setSongsArray={setSongsArray} />
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-700 bg-opacity-60 z-50 flex justify-center items-center text-black shadow-lg ">
          <div
            className="rounded-lg p-4 sm:p-6 w-full sm:w-3/4 md:w-1/2 overflow-auto bg-[#a3bdbc]"
            ref={modalRef}
          >
            <HowToUsePlatform onClose={closeModal} />
          </div>
        </div>
      )}

      {showOpenAi && (
        <div className="fixed inset-0 bg-gray-700 bg-opacity-60 z-50 flex justify-center items-center text-black shadow-lg">
          <div className="shadow-xl shadow-teal-300 p-5">
            <CustomeAI
              onClose={handleOpenAIClose}
              onGenerate={handleGenerate}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default SongGeneratedPage;
