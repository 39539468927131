
import React, { useState } from "react";
import banner1 from "../../assets/viral-custom-song-img/banner.png";
import brandLogo from "../../assets/dashboard/brand_img.webp";
import birthday from "../../assets/viral-custom-song-img/Birthday.png";
import Anniversary from "../../assets/viral-custom-song-img/Anniversary.png";
import ExpressLove from "../../assets/viral-custom-song-img/ExpressLove.png";
import SongforFriends from "../../assets/viral-custom-song-img/SongforFriends.png";
import ThankyouParents from "../../assets/viral-custom-song-img/ThankyouParents.png";
import WeddingInvite from "../../assets/viral-custom-song-img/WeddingInvite.png";
import BirthdayInvite from "../../assets/viral-custom-song-img/BirthdayInvite.png";
import Celebrations from "../../assets/viral-custom-song-img/Celebrations.png";
import JustforFun from "../../assets/viral-custom-song-img/JustforFun.png";
import SayThanks from "../../assets/viral-custom-song-img/SayThanks.png";
import Festivals from "../../assets/viral-custom-song-img/Festivals.png";
import Milestones from "../../assets/viral-custom-song-img/Milestones.png";
import GetWellSoon from "../../assets/viral-custom-song-img/GetWellSoon.png";
import Apologies from "../../assets/viral-custom-song-img/Apologies.png";
import CompanySong from "../../assets/viral-custom-song-img/CompanySong.png";
import proposal from "../../assets/viral-custom-song-img/Proposals.png";
import headingImg from "../../assets/viral-custom-song-img/quote.png";
import { FaPause } from "react-icons/fa6";
import musicPng from "../../assets/viral-custom-song-img/music.png";
import howitwork from "../../assets/viral-custom-song-img/howitworks.png";
import checkmark from "../../assets/viral-custom-song-img/checkmark.png";
import mbanner from "../../assets/viral-custom-song-img/mbanner.png";
import personalized from "../../assets/viral-custom-song-img/personalized.png";
import conffeti from "../../assets/viral-custom-song-img/confetti.png";
import heart from "../../assets/viral-custom-song-img/heart.png";
import reviews from "../../assets/viral_images/REVIEW.png";
import reviews1 from "../../assets/viral_images/review1.jpg";
import reviews2 from "../../assets/viral_images/review2.jpg";
import reviews3 from "../../assets/viral_images/review3.jpg";
import reviews4 from "../../assets/viral_images/review4.jpg";
import reviews5 from "../../assets/viral_images/review5.jpg";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import ViralFAQ from "./ViralFAQ";

function CustomSongPage() {
  const specialOccession = [
    { src: birthday, label: "Birthday" },
    { src: Anniversary, label: "Anniversary" },
    { src: ExpressLove, label: "Express Love" },
    { src: SongforFriends, label: "Song for Friends" },
    { src: ThankyouParents, label: "Thank you Parents" },
    { src: WeddingInvite, label: "Wedding Invite" },
    { src: BirthdayInvite, label: "Birthday Invite" },
    { src: proposal, label: "Proposals" },
    { src: Celebrations, label: "Celebrations" },
    { src: JustforFun, label: "Just for Fun" },
    { src: SayThanks, label: "Say Thanks" },
    { src: Festivals, label: "Festivals" },
    { src: Milestones, label: "Milestones" },
    { src: GetWellSoon, label: "Get Well Soon" },
    { src: Apologies, label: "Apologies" },
    { src: CompanySong, label: "Company Song" },
  ];
  const steps = [
    {
      title: "START THE CHAT",
      description: "Click the link to place the order via WhatsApp.",
      imgSrc: checkmark,
    },
    {
      title: "FILL OUT A SIMPLE FORM",
      description:
        "Let us know who the song is for, their name, and your preferred language, mood, and theme.",
      imgSrc: checkmark,
    },
    {
      title: "MAKE IT PERSONAL",
      description:
        "Choose either a slideshow or a selfie video song, or simply create an MP3 song!",
      imgSrc: checkmark,
    },
    {
      title: "MAKE THE PAYMENT",
      description:
        "Click on 'Create my song'. Complete the payment & it's done!",
      imgSrc: checkmark,
    },
    {
      title: "GET THE SONG ON YOUR EMAIL",
      description:
        "Our team will send you your customized song via email within 24 hours.",
      imgSrc: checkmark,
    },
  ];

  const [activeVideo, setActiveVideo] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isPlaying, setIsPlaying] = useState(false);
  const [audio] = useState(
    new Audio(
      "https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/friendship-song/female-hindi/friends-hindi-female1.mp3"
    )
  );
  const handlePlayPause = () => {
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying); // Toggle play/pause state
  };
  const handlePlay = (videoId) => {
    setActiveVideo(videoId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setActiveVideo(null);
  };
  return (
    <div className="bg-[#02090F] overflow-hidden">
      <div className="bg-[#02090F]">
        {/* Banner Section */}
        <section className="heroSec  py-8 relative">
          {/* Background Image */}
          <div
            className="absolute inset-0 bg-cover bg-center object-cover"
            style={{
              backgroundImage:
                window.innerWidth >= 768 ? `url(${banner1})` : "none",
              height: "100%", // Adjust height dynamically

            }}
          ></div>

          {/* Content Container */}
          <div className="container mx-auto px-4 relative z-50 ">
            <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-4 h-full">
              {/* Left Section */}
              <div className="text-left text-white space-y-4 p-4 md:p-10 bg-opacity-90  md:bg-transparent">
                <img
                  src={brandLogo}
                  alt="brand-logo"
                  className="h-8 md:h-12 w-auto"
                />
                <h1 className="text-2xl md:text-5xl font-bold">
                  Create the most
                  <br /> unique gift for that <br />
                  someone special
                </h1>
                <h3 className="text-lg md:text-2xl font-semibold text-[#6DDDC4]">
                  Dedicate a Song with Their Name!
                </h3>
                <p className="text-base md:text-sm space-y-2 leading-tight">
                  Create a Personalized video song based on your preference and
                  needs. Whether it's for a loved one, a special occasion, or
                  simply to celebrate a moment, dedicate a song with their name
                  in it!
                </p>
                <button className="bg-gradient-to-b from-[#97E388] to-[#62D7C4] border-none px-4 py-2 sm:px-6 sm:py-3 rounded-full text-black text-base sm:text-lg font-semibold">
                  Create a Customized Song at{" "}
                  <span className="font-bold text-red-500">₹499</span>
                </button>
              </div>
            </div>
          </div>
          <div id="top-section ">
            <img
              src={mbanner}
              alt="top banner"
              className=" w-full md:hidden relative top-[-14rem] z-10"
            />
          </div>
        </section>

        {/* Occasion Section */}
        <section className=" py-8  mt-[-60%] sm:mt-0">
          <div className="flex items-center justify-center mb-6">
            <span className="w-36 h-[2px] bg-[#6DDDC4] "></span>
            <h3 className="text-center text-md sm:text-xl font-bold  text-[#6DDDC4] mx-2">
              The Perfect Gift for Every Special Occasion
            </h3>
            <span className="w-36 h-[2px] bg-[#6DDDC4] "></span>
          </div>
          <div className="container mx-auto grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 px-5">
            {specialOccession.map((item, index) => (
              <div
                key={index}
                className="border flex border-[#98E384] rounded-xl text-white my-2 p-1"
              >
                <img
                  src={item.src}
                  alt={item.label}
                  className="my-5 mx-2 mb-2 w-12 h-12 sm:w-20 sm:h-20 md:w-24 "
                />
                <p className="text-center my-8 text-sm sm:text-base">
                  {item.label}
                </p>
              </div>
            ))}
          </div>
        </section>

        {/* Paragraph Section */}
        <section className=" py-10 bg-gradient-to-b from-[#0e2954] via-[#061b36] to-[#061b36] ">
          <div className="relative text-center">
            <img
              src={headingImg}
              alt="blank"
              className="transform scale-y-[-1] scale-x-[-1] absolute top-[-23px] sm:top-0 sm:left-[14rem] w-6 h-6 sm:w-10 sm:h-10 md:left-[15%] lg:left-[15%]"
            />
            <h2 className="text-base sm:text-4xl  italic text-white font-semibold ml-1">
              {""} Hearing your name in a song is a one-of-a-kind thrill,
              <br /> a feeling so rare and personal that it turns a fleeting
              melody
              <br />
              into something timeless and uniquely yours.
            </h2>
            <img
              src={headingImg}
              alt="black1"
              className="transform scale-y-[1] absolute right-5 sm:right-[16rem]   sm:bottom-0 md:right-[18%] w-6 h-6 sm:w-10 sm:h-10"
            />
          </div>
        </section>

        {/* Express Love Section */}
        <section className="py-8 ">
          <h2 className="text-center text-xl sm:text-3xl font-bold mb-8 text-[#6DDDC4]">
            Express Your Love & Emotions
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-12 max-w-5xl mx-auto text-white">
            <div className="rounded-lg p-6  text-start ">
              <div className="relative">
                <img
                  src={brandLogo} // Replace with the correct image path
                  alt="Song Thumbnail"
                  className="w-full rounded-lg mb-4 max-md:h-44 h-56"
                />
                <button
                  className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-lg"
                  onClick={handlePlayPause}
                  style={{ top: 0, left: 0, right: 0, bottom: 0 }}
                >
                  {isPlaying ? (
                    <FaPause className="text-4xl" />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-16 w-16 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M14.752 11.168l-6.586-3.486A1 1 0 007 8.506v6.988a1 1 0 001.166.992l6.586-3.486a1 1 0 000-1.832z"
                      />
                    </svg>
                  )}
                </button>
              </div>

              <h3 className="max-md:text-md text-3xl text-center text-[#58EBCE]   leading-tight">
                MP3 Song
              </h3>
              <p className="mt-3 max-md:text-md text-xl text-center">
                Create a personalized song in high-quality MP3 format.
              </p>
            </div>
            <div className="rounded-lg p-6 text-start   ">
              <div className="relative">
                {activeVideo !== "video1" ? (
                  <>
                    {/* Thumbnail Image */}
                    <img
                      src="https://imas-ai-home.s3.eu-central-003.backblazeb2.com/videssss/thank_you_mom_thumbnail.png"
                      alt="Face Sing"
                      className="w-full rounded-lg mb-4 max-md:h-44 h-56"
                    />
                    {/* Transparent Play Button */}
                    <button
                      className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-lg"
                      onClick={() => handlePlay("video1")}
                      style={{ top: 0, left: 0, right: 0, bottom: 0 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-16 w-16 text-white"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M14.752 11.168l-6.586-3.486A1 1 0 007 8.506v6.988a1 1 0 001.166.992l6.586-3.486a1 1 0 000-1.832z"
                        />
                      </svg>
                    </button>
                  </>
                ) : (
                  <video
                    controls
                    autoPlay
                    className="w-full rounded-lg h-36 mb-4"
                    controlsList="nodownload noremoteplayback"
                    disablePictureInPicture
                    onPause={() => setActiveVideo(null)}
                  />
                )}
              </div>

              <h3 className="max-md:text-md text-3xl  text-center text-[#58EBCE]  leading-tight">
                Selfie Video
              </h3>
              <p className="mt-3 max-md:text-md text-xl text-center">
                Watch your selfie come to life, lip-syncing the song's lyrics as
                if you're singing it yourself.
              </p>
            </div>

            {/* Option 2 */}
            <div className="rounded-lg p-6 text-start    ">
              <div className="relative  ">
                {activeVideo !== "video2" ? (
                  <>
                    {/* Thumbnail Image */}
                    <img
                      src="https://imas-ai-home.s3.eu-central-003.backblazeb2.com/videssss/parent_collage_thumbnail.png"
                      alt="Collage family Sing"
                      className="w-full rounded-lg mb-4 max-md:h-44 h-56"
                    />
                    {/* Transparent Play Button */}
                    <button
                      className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-lg"
                      onClick={() => handlePlay("video2")}
                      style={{ top: 0, left: 0, right: 0, bottom: 0 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-16 w-16 text-white"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M14.752 11.168l-6.586-3.486A1 1 0 007 8.506v6.988a1 1 0 001.166.992l6.586-3.486a1 1 0 000-1.832z"
                        />
                      </svg>
                    </button>
                  </>
                ) : (
                  <video
                    controls
                    autoPlay
                    className="w-full rounded-lg h-36 mb-4"
                    controlsList="nodownload noremoteplayback"
                    disablePictureInPicture
                    onPause={() => setActiveVideo(null)}
                  />
                )}
              </div>

              <h3 className="max-md:text-md text-3xl text-center text-[#58EBCE]  leading-tight">
                Slideshow of Memories
              </h3>
              <p className="max-md:text-md text-xl text-center mt-6">
                Share your favourite photos, and we'll create a slideshow paired
                with your personalized song.
              </p>
            </div>
          </div>

          <h5 className="text-center text-xl mt-4 text-[#58EBCE] ">
            *Our team will send you your customized song via email within 24
            hours.
          </h5>
        </section>

        {/* Songs Section */}
        <section className="songs py-8  bg-gradient-to-br from-[#106A84] via-[#03797B] to-[#03797B]">
          <h1 className="text-center text-xl sm:text-3xl font-bold mb-8 text-[#51E8C7]">
            We Turn Your Stories into Songs!
          </h1>
          <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-3 gap-4">
            {[...Array(3)].map((_, index) => (
              <img key={index} src={musicPng} alt="Music" className="mx-auto" />
            ))}
          </div>
          <div className="text-center mt-8 flex items-center justify-center ">
            <span className="w-36 h-[2px] bg-[#b1f1e8]"></span>
            <button className="bg-white border border-[#51E8C7] px-4 py-2 sm:px-6 sm:py-3 rounded-full text-black text-base sm:text-lg font-semibold">
              Create a Customized Song at{" "}
              <span className="font-bold text-red-500">₹499</span>
            </button>
            <span className="w-36 h-[2px] bg-[#99d6d6]"></span>
          </div>
        </section>
        <section
          className="bg-cover bg-center bg-no-repeat w-full  py-10"
          style={{
            backgroundImage:
              window.innerWidth >= 768 ? `url(${howitwork})` : "none",
          }}
        >
          <div className="container mx-auto">
            <div className="flex flex-col md:flex-row items-start">
              <div className="w-full md:w-1/2 mb-8 md:mb-0">
                <h1 className="text-4xl font-bold text-white  mb-6">
                  How it Works
                </h1>
                <div className="space-y-6">
                  {steps.map((step, index) => (
                    <div key={index} className="flex items-start">
                      <img
                        src={step.imgSrc}
                        alt="checkmark"
                        className="w-8 h-8 mr-4"
                      />
                      <div>
                        <h5 className="text-lg font-semibold text-[#58EBCE]">
                          {step.title}
                        </h5>
                        <p className="text-white text-sm">{step.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <button className="bg-gradient-to-b from-[#97E388] via-[#97E388] to-[#62D7C4] border-none px-4 py-2 rounded-full text-black text-lg font-semibold mt-5">
                  Chat with us!
                </button>
              </div>

              <div className="w-full md:w-1/2"></div>
              <div id="top-section">
                <img
                  src={howitwork}
                  alt="top banner"
                  className=" w-full md:hidden"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="celebrate py-10  px-4  items-center justify-center flex flex-col">
          <h2 className="text-xl md:text-4xl  font-semibold mb-8 text-white text-center">
            Celebrate, Surprise, or simply say
            <span className="text-[#58EBCE] font-bold "> "You're special"</span>
          </h2>

          <div className="">
            {/* Grid Layout for Responsive Design */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 ">
              {/* First Column */}
              <div className="border border-[#9DDADD] py-3 px-6 text-center rounded-xl bg-[#08273B] flex  items-center justify-center">
                <div className=" ">
                  <img
                    src={personalized}
                    alt="personalized"
                    className="mr-4 my-2 w-12 h-12 sm:w-14 sm:h-14"
                  />
                </div>
                <div>
                  <h5 className="text-xl font-semibold text-[#58EBCE] mt-2">
                    100% PERSONALIZED
                  </h5>
                  <p className="text-sm text-white text-wrap  mt-1">
                    No generic templates—every song is
                    <br /> a masterpiece based on your input.
                  </p>
                </div>
              </div>

              <div className="border border-[#9DDADD] py-3 px-6 text-center rounded-xl bg-[#08273B] flex  items-center justify-center">
                <div className=" ">
                  <img
                    src={heart}
                    alt="heart"
                    className="mr-4 my-2 w-12 h-12 sm:w-14 sm:h-14"
                  />
                </div>
                <div>
                  <h5 className="text-xl font-semibold text-[#58EBCE] mt-2">
                    MEMORABLE OPTIONS
                  </h5>
                  <p className="text-sm text-white text-wrap  mt-1">
                    Make it visual with your selfie or
                    <br /> slideshows of photos.
                  </p>
                </div>
              </div>

              <div className="border border-[#9DDADD] py-3 px-6 text-center rounded-xl bg-[#08273B] flex  items-center justify-center">
                <div className=" ">
                  <img
                    src={conffeti}
                    alt="conffeti"
                    className="mr-4 my-2 w-12 h-12 sm:w-14 sm:h-14"
                  />
                </div>
                <div>
                  <h5 className="text-xl font-semibold text-[#58EBCE] mt-2 text-wrap">
                    MEMORABLE OPTIONS
                  </h5>
                  <p className="text-sm text-white text-wrap  mt-1">
                    Make it visual with your selfie or
                    <br /> slideshows of photos.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className=" text-white py-2 px-4 bg-black">
          <h2 className="text-center font-semibold max-md:text-xl text-3xl max-md:mb-4 mt-6 mb-6 max-md:mt-0 text-[#58EBCE]">
            Customer Reactions
          </h2>

          <div className="w-full mx-auto">
            <Swiper
              loop={true}
              spaceBetween={20}
              slidesPerView={1}
              breakpoints={{
                768: { slidesPerView: 2 }, // 2 slides on medium screens
                1024: { slidesPerView: 3 }, // 3 slides on large screens
              }}
              autoplay={{
                delay: 2000, // Auto scroll every 2 seconds
                disableOnInteraction: true, // Keeps autoplay even when interacting
              }}
              modules={[Autoplay]}
              className="pb-10"
            >
              {/* Reviews */}
              {[
                {
                  image: reviews,
                  text: "I created a song for my parents, and seeing their faces when they heard it was priceless.",
                },
                {
                  image: reviews1,
                  text: "The slideshow made my parents cry. A heartfelt gift that touched their hearts.",
                },
                {
                  image: reviews2,
                  text: "A beautiful way to preserve memories and make them even more special.",
                },
                {
                  image: reviews3,
                  text: "An emotional experience that I'll cherish forever.",
                },
                {
                  image: reviews4,
                  text: "A unique and touching gift that left a lasting impression.",
                },
                {
                  image: reviews5,
                  text: "Absolutely loved it! This was a gift that truly spoke from the heart.",
                },
              ].map((review, index) => (
                <SwiperSlide key={index}>
                  <div className="flex items-center justify-center">
                    {/* Left blurred side image */}
                    <div className="hidden lg:block w-[10%] filter blur-sm opacity-50">
                      <img
                        src={reviews[(index - 1 + 6) % 6]?.image}
                        alt={`SideImage ${index}`}
                        className="w-full h-[150px] object-cover border border-gray-200 rounded-lg"
                      />
                    </div>
                    {/* Main image */}
                    <div className="w-[90%] mx-2 relative rounded-lg overflow-hidden max-md:h-[200px] h-[300px] ">
                      <img
                        src={review.image}
                        alt={`Review ${index + 1}`}
                        className="w-full h-full object-cover border border-teal-300"
                      />
                      {/* Overlay Content */}
                      <div className="absolute inset-x-0 bottom-0 bg-black bg-opacity-40 flex flex-col justify-end p-2 pb-2 rounded-b-lg">
                        <div className="flex items-center mb-2">
                          <span className="text-yellow-400 text-lg">★★★★★</span>
                        </div>
                        <p className="text-white text-sm">{review.text}</p>
                      </div>
                    </div>
                    {/* Right blurred side image */}
                    <div className="hidden lg:block w-[10%] filter blur-sm opacity-50">
                      <img
                        src={reviews[(index + 1) % 6]?.image}
                        alt={`SideImage ${index}`}
                        className="w-full h-[150px] object-cover border border-gray-200 rounded-lg"
                      />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <div className="text-center mt-8 mb-12">
            <h3 className="max-md:text-lg text-3xl font-semibold ">
              Thousand will turned their emotions into everlasting songs.
            </h3>
            <p className="mt-2 max-md:text-sm text-xl leading-tight text-[#58EBCE]">
              What are you waiting for?
            </p>
          </div>
        </section>

        <section className="flex justify-center items-center flex-col px-4 py-8 max-w-6xl mx-auto">
          <h1 className=" max-md:text-md text-3xl font-bold leading-tight text-[#58EBCE] mb-5">
            FAQ
          </h1>
          <div className="w-full">
            <ViralFAQ />
          </div>
          <div className="text-center mt-8 flex items-center justify-center ">
            <span className="w-36 h-[2px] bg-[#b1f1e8]"></span>
            <button className="bg-gradient-to-b from-[#97E388] to-[#62D7C4] border border-[#51E8C7] px-4 py-2 sm:px-6 sm:py-3 rounded-full text-black text-base sm:text-lg font-semibold">
              Create a Customized Song at{" "}
              <span className="font-bold text-red-500">₹499</span>
            </button>
            <span className="w-36 h-[2px] bg-[#99d6d6]"></span>
          </div>
        </section>

        <section class="">
          <hr class="" />
          <p className="text-gray-600 px-5">
            Copyright © 2024 Digikore Studios Limited
          </p>
        </section>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 bg-black flex  items-center justify-center z-[1000]">
          <div className="relative w-full max-w-5xl">
            <button
              className="absolute -top-24 right-4 text-white text-3xl p-4 hover:text-red-600"
              onClick={closeModal}
            >
              ✖
            </button>
            <video
              src={
                activeVideo === "video1"
                  ? "https://imas-ai-home.s3.eu-central-003.backblazeb2.com/videssss/thank_you_mother.mp4"
                  : "https://imas-ai-home.s3.eu-central-003.backblazeb2.com/videssss/happy_family_collage_video.mp4"
              }
              controls
              autoPlay
              className="w-full h-[70vh]"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default CustomSongPage;
