import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import ImageTen from '../../assets/partners_images/10.png';
import ImageTwelve from '../../assets/partners_images/12.png';
import ImageThree from '../../assets/partners_images/3.png';
import ImageFour from '../../assets/partners_images/4.png';
import ImageFive from '../../assets/partners_images/5.png';
import ImageSix from '../../assets/partners_images/6.png';
import ImageNine from '../../assets/partners_images/9.png';
import brandLogo from '../../assets/partners_images/logo.png';
import '../../styles/partner.css';

const PartnersPage = () => {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  const handleContactUs = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const faqItems = [
    {
      question: "What are the potential earnings from this business?",
      answer: "Franchisees can earn between Rs. 2 lakhs to Rs. 5 lakhs per month, depending on customer demand, effective marketing, and local market potential."
    },
    {
      question: "What makes this a good business opportunity?",
      answer: "1. Growing Demand: Personalized gifting is a booming market.\n 2.High-Profit Margins: Minimal operational costs with high earning potential.\n 3.Turnkey Setup: Fully functional platform provided, reducing time and effort. \n 4. Scalability: Expand reach through digital marketing and social media."
    },
    {
      question: "What is the required investment?",
      answer: "The investment required is Rs. 10 lakhs + GST. This includes:\n A fully functional website under your domain name or brand name. \n 33,333 song credits valid for 1 year.\n Rs. 4 lakhs worth of digital marketing to drive traffic and initial sales."
    },
    {
      question: "What support will I receive as a franchisee?",
      answer: "Franchisees receive:\n A turnkey platform ready to launch.\n Ongoing training and technical support.\n A dedicated digital marketing team for SEO, PPC, and influencer marketing."
    },
    {
      question: "Do I need technical knowledge to operate the platform?",
      answer: "No technical expertise is required. We provide complete training on platform operations, marketing strategies, and customer management."
    },
    {
      question: "What marketing support will I receive?",
      answer: "Our dedicated marketing team will help you launch and run targeted campaigns, including: \n SEO and content marketing for organic traffic. \n Social media ads and PPC campaigns for paid reach. \n Influencer marketing to drive brand awareness."
    }, {
      question: "How do I get started?",
      answer: "Contact us today, and our team will guide you through the onboarding process. With our turnkey setup, you can launch your business in less than 15 days!"
    },
  ];

  return (
    <>
      <section className="banner about_section">
        <div className="container about-sec">
          <div className="row">
            <div className="left-column">
              <div className="main">
                <a href='/'>   <img src={brandLogo} className="logo" alt="Logo" /></a>
                <h1 className="title1">
                  Start Your Own <br />
                  AI-generated Personalized Song Platform
                </h1>
                <p>Monthly Earnings <br /> 2 Lakhs - Rs. 5 Lakhs – Effortlessly!</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="actnow">
        <div className="acttext">
          <h2>
            <span style={{ color: "#fffc01" }}>Limited Slots Available</span> | Act Now!
          </h2>
        </div>
      </section>

      <section className="info about_section layout_padding">
        <div className="container about-section">
          <div className="row">
            <div className="w-full md:w-1/2">
              <h1 class="mb_h1">Become a Franchise/Distributor</h1>
              <h2 className="info_h1">The Generative AI music market is projected to grow to</h2>
              <h1 className="mb">$3.42 billion by 2033!</h1>
              <p className='font-semibold max-md:text-left mt-2 leading-tight'>
                Tap into the growing demand for unique, memorable songs customised for every occasion. Create a profitable business,
                offering consumers an affordable, personalised music experience they’ll cherish!
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="thirdsec about_section">
        <div className="container">
          <div className="row">
            <div className="w-full md:w-1/2">
              <h2 className="mb1">AI-Powered Songs are</h2>
              <h1 className="third_h1">The Future of Personalized Gifting!</h1>
              {/* <img src={ImageTwelve} alt="AI-Powered Custom" /> */}
            </div>
            <div className="w-full md:w-1/2">
              <button className="btn1">Memorable & Unique</button>
              <button className="btn1">Scalable</button>
              <button className="btn1">Cost-Effective</button>
            </div>
          </div>
        </div>
      </section>

      <section className="img_box about_section">
        <div className="container img-box">
          <div className="row test">
            <img src={ImageThree} className="img1" alt="Image 1" />
            <img src={ImageFour} className="img1" alt="Image 2" />
            <img src={ImageFive} className="img1" alt="Image 3" />
            <img src={ImageSix} className="img1" alt="Image 4" />
          </div>
        </div>
      </section>

      <section className="why_us about_section layout_padding">
        <div className="container">
          <h2 className="fourth_h2">Why Partner with Us?</h2>
          <ul className="line">
            <li>Turnkey Solution <br /> A fully functional platform, ready to launch.</li>
            <li>High-Profit Margins <br /> Earn between Rs. 2 lakhs to Rs. 5 lakhs a month.</li>
            <li>Support <br /> Our team provides ongoing training and technical assistance.</li>
            <li>Proven Business Model <br /> Tap into a rapidly growing market with immense potential.</li>
          </ul>
        </div>
      </section>

      <section className="fifth about_section">
        <div className="container mx-auto px-4">
          <div className="row flex flex-wrap">
            <div className="w-full text-center py-8">
              <h1 className="text-4xl max-md:text-3xl font-bold">Drive Sales with a Powerful Marketing Strategy</h1>
            </div>

            <p className="w-full text-center text-lg mb-8">
              Our dedicated digital marketing team is here to guide you through launching targeted campaigns
            </p>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 justify-center mx-auto text-center">
              <button id="finance-btn" className="btn-primary">
                SEO &amp; Content Marketing
              </button>
              <button id="refer-btn" className="btn-secondary">
                Social Media Ads
              </button>
              <button id="finance-btn" className="btn-primary">
                PPC Campaigns
              </button>
              <button id="refer-btn" className="btn-secondary">
                Influencer Marketing
              </button>
            </div>


            <div className="hidden md:flex w-full space-x-4">
              <div className="md:w-1/2 p-4">
                <div className="detail-box listcheck p-6 rounded-lg ">
                  <div className="get_strt mb-4">
                    <h2 className="text-2xl font-semibold text-left">
                      Get Started with an Investment <br /> of <span className="text-left">just Rs.10 lakhs!</span>
                    </h2>
                  </div>
                  <ul className="checkmark ml-5 space-y-2">
                    <li>We provide you with a Fully Functional Website on your Domain Name / Brand Name</li>
                    <li>33333 song credits valid for 1 year.</li>
                    <li>Rs. 4 lakhs worth of Digital Marketing to get you your first 10,000 to 15,000 website visitors within 60 days of launch, which can result in sales of between Rs. 5 lakhs to Rs. 10 lakhs.</li>
                  </ul>
                </div>
              </div>

              <div className="md:w-1/2 mt-5 p-4">
                <img src={ImageNine} alt="Digital Marketing Image" className="rounded-lg " />
              </div>
            </div>

            <div className="hidden md:flex w-full space-x-4 mt-8">
              <div className="md:w-1/2 p-4">
                <img src={ImageTen} alt="Another Marketing Image" className="rounded-lg " />
              </div>

              <div className="md:w-1/2 p-4">
                <div className="detail-box  p-6 rounded-lg ">
                  <h2 className="text-2xl font-semibold mb-4">What You’ll Need</h2>
                  <ul className="checkmark ml-5 space-y-2">
                    <li>Interest in digital business and customer service</li>
                    <li>Basic marketing understanding (training provided)</li>
                    <li>Understanding of local trends &amp; customer base for better targeting</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="md:hidden w-full space-y-4 mt-5">
              <div className="flex flex-col items-center">
                <img src={ImageNine} alt="Digital Marketing Image" className="l1_img rounded-lg  mb-4" />
                <div className="detail-box listcheck  p-6 rounded-lg  text-center">
                  <h2 className="text-2xl font-semibold mb-4 text-left">Get Started with an Investment <br /> of <span className="text-left">just Rs.10 lakhs!</span></h2>
                  <ul className="checkmark ml-5 space-y-2 text-left">
                    <li>We provide you with a Fully Functional Website on your Domain Name / Brand Name</li>
                    <li>25,000 song credits valid for 1 year</li>
                    <li>Rs. 4 lakhs worth of Digital Marketing to get you your first 10,000 to 15,000 website visitors within 60 days of launch, which can result in sales of between Rs. 5 lakhs to Rs. 10 lakhs</li>
                  </ul>
                </div>
              </div>

              <div className="flex flex-col items-center mt-4">
                <img src={ImageTen} alt="Another Marketing Image" className="l1_img rounded-lg shadow-md mb-4" />
                <div className="detail-box  p-6 rounded-lg  text-center">
                  <h2 className="text-2xl font-semibold mb-4">What You’ll Need</h2>
                  <ul className="checkmark ml-5 space-y-2 text-left">
                    <li>Interest in digital business and customer service</li>
                    <li>Basic marketing understanding (training provided)</li>
                    <li>Understanding of local trends &amp; customer base for better targeting</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="last about_section">
        <div className="container">
          <div className="heading">
            <h1>Simple Setup, Big Earnings Potential!</h1>
          </div>
          <p className="description">
            We have done all the hard work. You can reap the benefits!!!
          </p>
        </div>
      </section>

      <section className="cta about_section">
        <div className="container">
          <button onClick={handleContactUs} className="cta_btn">Contact us today</button>
        </div>
      </section>

      <section className="bsb-accordion-6 py-0 md:py-6 xl:py-10">
        <div className="container mx-auto">
          <div className="row">
            <div className="col-12">
              <div className="accordion" id="accordionExample">
                <h1 className="text-2xl font-bold mb-4">FAQ's</h1>

                {faqItems.map((item, index) => (
                  <div
                    key={index}
                    className={`accordion-item border border-gray-200 ${activeIndex === index ? "active" : ""}`}
                  >
                    <button
                      onClick={() => toggleAccordion(index)}
                      className="accordion-button w-full text-left px-4 py-3 text-lg font-medium"
                    >
                      {item.question}
                    </button>
                    {activeIndex === index && (
                      <div className="accordion-body px-4 py-3">
                        {/* Check if it's the 3rd, 4th, or 6th question and add bullets */}
                        {(index === 2 || index === 3 || index === 5) ? (
                          <ul className="list-disc pl-5">
                            {item.answer.split("\n").map((line, i) => (
                              <li key={i}>{line}</li>
                            ))}
                          </ul>
                        ) : (
                          item.answer.split("\n").map((line, i) => (
                            <div key={i} className="mb-2">
                              {line}
                            </div>
                          ))
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>



      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
          <div className="rounded-lg w-96 relative">
            <h2 className="text-xl text-gray-500 font-semibold m-3">Contact Us</h2>
            <iframe
              src="https://forms.zohopublic.in/access2/form/forimadeasong/formperma/1-aXrXYR1VuyMl0y_7Ve-e_rgiBe_K521lQyGrz2Boo"
              width="100%"
              height="600"
              title="Contact Us Form"
              frameBorder="0"
              className="rounded-lg"
            ></iframe>
            <button
              onClick={closeModal}
              className="absolute top-2 right-2 text-gray-500"
            >
              &times;
            </button>
          </div>
        </div>
      )}

      <section className="cta about_section">
        <div className="container">
          <p className="description max-md:mb-12"><strong>Start your Profitable Business in less than 15 days!</strong></p>
        </div>
      </section>
    </>
  );
};

export default PartnersPage;
